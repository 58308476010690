(function() {
	angular.module("app").directive("privacyPolicy", privacyController);

	function privacyController(user) {
		return {
			templateUrl: "pages/privacyPolicy/privacyPolicy.html",
			controllerAs: "vm",
			controller: function() {
				var vm = this;

				angular.extend(vm, {});

				//******** end *********//
			}
		};
	}
})();
