(function() {
	angular.module("app").directive("drbReturn", drbReturnController);

	function drbReturnController(
    cases,
    drbOptions,
    funeralHomes,
    guests,
    helpers,
    orders,
    themes,
    user,
    $q,
    $window,
    $timeout,
    $routeParams,
    $location,
    drbSubscriptions,
    $rootScope)	{
      return {
        templateUrl: 'pages/drbReturn/drbReturn.html',
        scope: true,
        link: function(scope) {
          var vm = scope

          angular.extend(scope, {
            loading: true,
            payment: false,
            selectPlan: selectPlan,
            addSMS: addSMS,
            stripe: {},
            plan: null,
            smsSelected: {
              annualSMS: false,
              monthlySMS: false,
            },
            selectedSMS: null,
            selectedPlan: null,
            customer_info: {},
          });

          init()

          async function init() {
            
            const subscriber = await drbSubscriptions.getActiveSubscription()
            if(subscriber.status === 200) {
            
              $location.path('/drbPortal')
              vm.$apply() 
              const customer = await drbSubscriptions.getCustomer()
              
            
            } else {
              const res = await funeralHomes.get();
              if(res.data.stripe_subscription_status) {
                const customer = await drbSubscriptions.getCustomer()
                vm.customer_info = customer.data.customer
                vm.loading = false
                vm.$apply() 
              } else {
                $location.path('/drbLanding')
              }
            }
          }

          function selectPlan(plan){
            loadStripe()
            vm.plan = plan
            //STRIPE PLAN
            vm.selectedPlan = plan === 'monthly' ? {plan: drbSubscriptions.drb_monthly} : {plan: drbSubscriptions.drb_annual}
          
            vm.payment = true
          }

          function addSMS(plan) {
            vm.selectedSMS = plan === 'monthly' && vm.smsSelected.monthlySMS ? [{plan: drbSubscriptions.sms_incoming}, {plan: drbSubscriptions.sms_outgoing}] : plan === 'annual' && vm.smsSelected.annualSMS ? [{plan: drbSubscriptions.sms_incoming}, {plan: drbSubscriptions.sms_outgoing}] : null
          }
          
          function loadStripe() {
            vm.stripe = Stripe(drbSubscriptions.key);
            var elements = vm.stripe.elements();
          
            var card = elements.create('card', {
                iconStyle: 'solid',
                style: {
                  base: {
                    iconColor: '#ccc',
                    color: '#a6a6a6',
                    fontWeight: 500,
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
            
                    ':-webkit-autofill': {
                      color: '#fce883',
                    },
                    '::placeholder': {
                      color: '#ccc',
                    },
                  },
                  invalid: {
                    iconColor: '#ccc',
                    color: '#ccc',
                  },
                },
              });
            !vm.loading && card.mount('#stripe-card');
          
            registerElements([card], 'stripe-form');
        
          }

          async function registerElements(elements, exampleName) {

            const formClass = `.${exampleName}`;
            const example = document.querySelector(formClass);

            const form = example.querySelector('form');
            // var resetButton = example.querySelector('a.reset');
            const error = form.querySelector('.error');
            const errorMessage = error.querySelector('.message');

            function enableInputs() {
              Array.prototype.forEach.call(
                form.querySelectorAll(
                  "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function(input) {
                  input.removeAttribute('disabled');
                }
              );
            }

            function disableInputs() {
              Array.prototype.forEach.call(
                form.querySelectorAll(
                  "input[type='text'], input[type='email'], input[type='tel']"
                ),
                function(input) {
                  input.setAttribute('disabled', 'true');
                }
              );
            }

            async function triggerBrowserValidation() {
              // The only way to trigger HTML5 form validation UI is to fake a user submit
              // event.
              const submit = document.createElement('input');
              submit.type = 'submit';
              submit.style.display = 'none';
              form.appendChild(submit);
              submit.click();
              submit.remove();
            }

            // Listen for errors from each Element, and show error messages in the UI.
            let savedErrors = {};
            elements.forEach(function(element, idx) {
              element.on('change', function(event) {
                if (event.error) {
                  error.classList.add('visible');
                  savedErrors[idx] = event.error.message;
                  errorMessage.innerText = event.error.message;
                } else {
                  savedErrors[idx] = null;

                  // Loop over the saved errors and find the first one, if any.
                  var nextError = Object.keys(savedErrors)
                    .sort()
                    .reduce(function(maybeFoundError, key) {
                      return maybeFoundError || savedErrors[key];
                    }, null);

                  if (nextError) {
                    // Now that they've fixed the current error, show another one.
                    errorMessage.innerText = nextError;
                  } else {
                    // The user fixed the last error; no more errors.
                    error.classList.remove('visible');
                  }
                }
              });
            });

            // Listen on the form's 'submit' handler...
            form.addEventListener('submit', async function(e) {
              e.preventDefault();

              // Trigger HTML5 validation UI on the form if any of the inputs fail
              // validation.
              var plainInputsValid = true;
              Array.prototype.forEach.call(form.querySelectorAll('input'), function(
                input
              ) {
                if (input.checkValidity && !input.checkValidity()) {
                  plainInputsValid = false;
                  return;
                }
              });
              if (!plainInputsValid) {
                triggerBrowserValidation();
                return;
              }

              // Show a loading screen...
              example.classList.add('submitting');

              // Disable all inputs.
              disableInputs();

              // Gather additional customer data we may have collected in our form.
              var name = form.querySelector(`#stripe-name`);
              var email = form.querySelector(`#stripe-email`);
              var address1 = form.querySelector(`#stripe-address`);
              var city = form.querySelector(`#stripe-city`);
              var state = form.querySelector(`#stripe-state`);
              var zip = form.querySelector(`#stripe-zip`);
              var additionalData = {
                name: name ? name.value : undefined,
                email: email ? email.value : undefined,
                address_line1: address1 ? address1.value : undefined,
                address_city: city ? city.value : undefined,
                address_state: state ? state.value : undefined,
                address_zip: zip ? zip.value : undefined,
              };

              // Use Stripe.js to create a token. We only need to pass in one Element
              // from the Element group in order to create a token. We can also pass
              // in the additional customer data we collected in our form.
              const result_token_one = await vm.stripe.createToken(elements[0], additionalData)
              // Stop loading!
              example.classList.remove('submitting');

              if (result_token_one.token) {
                // If we received a token, show the token ID.
                example.classList.add('submitted');
                let plans = []
                vm.selectedPlan && plans.push(vm.selectedPlan)
                const payload = {
                    sourceToken: result_token_one.token.id,
                    email: additionalData.email,
                    name: additionalData.name,
                    phone: additionalData.phone,
                    plans: plans,
                    trial: true,
                  }
                const subscription = await drbSubscriptions.createSubscription(payload)
              }
              else {
                // Otherwise, un-disable inputs.
                enableInputs();
              }
              //the second token should not be needed as it will take the default payment from the first, but this is in case it fails
              if(vm.selectedSMS) {
              const result_token_two = await vm.stripe.createToken(elements[0], additionalData)
              // Stop loading!
              example.classList.remove('submitting');

              if (result_token_two.token) {
                // If we received a token, show the token ID.
                example.classList.add('submitted');
                let plans = []
                

              
                  const sms_payload = {
                    sourceToken: result_token_two.token.id,
                    email: additionalData.email,
                    plans: vm.selectedSMS,
                    //collection_method: 'send_invoice'
                  }
                  const sms_sub = await drbSubscriptions.createSubscription(sms_payload)
                }
                else {
                  // Otherwise, un-disable inputs.
                  enableInputs();
                }
                  
                
                
              }
              const subscriber = await drbSubscriptions.getActiveSubscription()
                if(subscriber.status === 200) {
                  $location.path('/drbPortal')
                  vm.$apply() 
                } else {
                  const uhoh = true
                }
            });
          }
        }
      }
    }
  })();