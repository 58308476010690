(function() {
	angular.module("app").directive("drbPortal", drbPortalController);

	function drbPortalController(
    cases,
    drbOptions,
    funeralHomes,
    guests,
    helpers,
    orders,
    themes,
    user,
    $q,
    $window,
    $timeout,
    $routeParams,
    $location,
    drbSubscriptions,
    $rootScope)	{
		return {
			templateUrl: 'pages/drbPortal/drbPortal.html',
			scope: true,
			link: function(scope) {
				var vm = scope

				angular.extend(scope, {
					activeSearch: false,
          orders: null,
          showTab: 'main',
          tabNav: tabNav,
          error: null,
          orderBy: "-sortable_created_at",
          descending: true,
          orders: [],
          loading: true,
          getActiveOrders: getActiveOrders,
          updateFuneralHomeSlug: updateFuneralHomeSlug,
          home: {},
          updateOrder: updateOrder,
          getAdditional: getAdditional,
          theme: {},
          case: {},
          checkSlug: checkSlug,
          generateCSV: generateCSV,
          getGuests: getGuests,
          guests: [],
          deleteGuest: deleteGuest,
          goToLabels: goToLabels,
          updateOptions: updateOptions,
          options: {},
          saving: false,
          badString: false,
          page: 1,
          pages: [],
          hasSlug: true,
          editSlug: false,
          customer_info: {},
          formatTime: formatTime,
          showPlans: false,
          plan: '',
          checkout: checkout,
          cancelAllSubscriptions: cancelAllSubscriptions,
          cancelSubscription: cancelSubscription,
          cancel_date: '',
          cancelled: false,
          invoice: {},
          amount_due: '',
          addSMS: addSMS,
          // startFreeTrial: startFreeTrial,
          imgURL: helpers.imgixCase,
          
				})

				init()


				async function init() {
          //loadStripe()
          vm.stripe = Stripe(drbSubscriptions.key);
          const subscriber = await drbSubscriptions.getActiveSubscription()
          if(subscriber.status === 200) {  
            vm.loading = false 
            await getHome()         
            await getActiveOrders()
            await retrieveStripeInfo()
            if(vm.home.corporate_id === 'a39c463a-766a-11e9-86fd-12f1538cdcec') vm.home.northstar = true;
            scope.$digest()
          } else {
            const res = await funeralHomes.get()
            if(res.data.stripe_subscription_status) {
              $location.path('/drbReturn')
            } else {
              $location.path('/drbLanding')
            }
            
          }
          
        }

        async function retrieveStripeInfo() {
          const res = await drbSubscriptions.getCustomer()
          vm.customer_info = res.data.customer
          if(res.data.invoices){
            vm.invoice = res.data.invoices
            vm.amount_due = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(res.data.invoices.amount_due/100)
          }
          if(vm.customer_info.subscriptions.data.length > 1) { 
            vm.customer_info.subscriptions.data.sort((a, b) => a.items.data.length - b.items.data.length)
            await Promise.all (
              vm.customer_info.subscriptions.data.map(async sub => {
                if(sub.cancel_at_period_end && sub.plan) {
                  vm.cancelled = true
                  vm.cancel_date = moment.unix(sub.cancel_at).format('LL')
                }
                return;
              })
            )
          }
          scope.$digest()
          return;
        }
        
        function formatTime(time, format) {
          return moment.unix(time).format(format)
        }

				async function getActiveOrders(page) {   
					page ? vm.page = page : vm.page = 1
          vm.tab = "active";
          vm.orders = [];
          vm.pages = [];
          const response = await orders.getFuneralHomeOrders(false, page - 1 || 0)

          const range = Math.ceil(response.data.total / 20);
          for (let i = 1; i <= range; i++) {
            vm.pages.push(i);
          }
          vm.orders = response.data.orders;
          vm.orders.map(order => {
            order.isCollapsed = true
            order.service_start_date = order.service_start_date ? moment.utc(order.service_start_date).format('ll'): null;
            order.service_end_date = order.service_end_date ? moment.utc(order.service_end_date).format('ll'): null;
          })
          vm.$digest()
				}
				
				async function getHome() {
          const home = await funeralHomes.get()
        //   vm.is_authenticated = home.data.is_service_authenticated;
        //   vm.freeTrial = home.data.freeTrial ? home.data.freeTrial : null;
          vm.home = funeralHomes.home
          vm.home.slug = funeralHomes.home.service_url_slug ? `${funeralHomes.home.service_url_slug}` : '';
          vm.hasSlug = vm.home.slug ? true : false
          vm.$digest()
				}

        // async function startFreeTrial() {
        //   const response = await funeralHomes.startFreeTrial()
        //   if(response.status === 200) {
        //     await isServiceAuthenticated(true)
        //     if(vm.is_authenticated || (vm.freeTrial && vm.freeTrial < 31)) {
        //       await getActiveOrders()
        //     }
        //   }
        // }

        async function updateFuneralHomeSlug() {
          vm.saving = true
          let payload = {}
          payload.service_url_slug = vm.home.slug.length > 0 ? vm.home.slug : null;

          await funeralHomes.update(payload)
          //just want that 'saving' to be briefly visible when save goes fast like fire
          $timeout(function () {
            vm.saving = false
            vm.hasSlug = true
            vm.editSlug = false
            vm.$digest()
          },200)
          
        }

        async function checkSlug() {
          const match = RegExp(/[^a-z0-9-._]/g)
          if(match.test(vm.home.slug)) {
            vm.badString = true
            return false
          } else {
            vm.badString = false
          } 
          if(vm.home.slug.length > 0 && vm.badString === false){
            const response = await funeralHomes.checkSlug(vm.home.slug)         
            vm.slug_taken = response.data
            vm.$digest()
          } else {
            vm.slug_taken = false
          }
          
        }

        async function updateOrder(order, caller) {
          let payload= {}
          if(order.selected_for_service !== funeralHomes.home.selected_for_service) {
            payload.selected_for_service = order.selected_for_service
          } 
          vm.orders.map(item => {
            if( item.id !== order.id) item.isCollapsed = true
            return item
          })
        
          order.isCollapsed = !order.isCollapsed
          await orders.update(order.id, payload)
          
          const options_response = await drbOptions.getDRBOptions(order.id)

          vm.options = options_response.data.id ? Object.assign({}, options_response.data) : { name: 'required', email: 'required', phone: 'required', address: 'required', message: 'visible', service_start_date: null, service_end_date: null};

          if(!options_response.data.id) {
            let payload = Object.assign({},vm.options)
            payload.case_id = order.case_id
            payload.order_id = order.id
            payload.city = payload.address
            payload.state = payload.address
            payload.zip_code = payload.address
            const response = await drbOptions.createDRBOptions(payload)
            vm.options = Object.assign({}, response.data)
          }
        }

        async function getAdditional(order) {
          vm.orders.map(item => {
            if( item.id !== order.id) item.isCollapsed = true
            return item
          })
          
          vm.case = {}
          vm.order = {}
          order.isCollapsed = !order.isCollapsed

          

          const case_response = await cases.get(order.case_id)
          
          vm.case.personal_images = case_response.data.personal_images
         
          const theme_response = await themes.getThemeById(order.theme_id)
          
          let theme_layout
          if(order.collection_id === '00000000-57c7-4c18-caa5-ce2103a01c54') {
            theme_layout = await themes.getThemeLayout('08098034-b9fa-11e8-a24e-06954d8af2a4', order.theme_id)
            
          } else if(order.collection_id === '00000000-57ea-b1f4-e8ca-e8030067b1f9') {
            theme_layout = await themes.getThemeLayout('08098034-b9fa-11e8-a24e-06954d8af2a4', order.theme_id)
          }

          const options_response = await drbOptions.getDRBOptions(order.id)
          vm.options = options_response.data

          vm.theme = {
            image: (theme_layout && theme_layout.data.length > 0) ? theme_layout.data[0].replacement_image : theme_response.data.image,
            name: theme_response.data.name
          }
          vm.$digest()
        }

        async function generateCSV(order) {
          const response = await guests.generateGuestsCSV(order.case_id)
          const filename = `${order.case.editable_case_id ? order.case.editable_case_id : order.case_id}_service_guests.csv`;
          const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
          if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
          } else {
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        }

        async function getGuests(order) {
          const response = await guests.getGuests(order.case_id)
          vm.guests = response.data
          vm.$digest()
        }

        async function deleteGuest(guest) {
          await guests.deleteGuest(guest.id)
          await getGuests(guest)
        }

        function goToLabels(order) {
          window.location.href = `/envelopeLabels/${order.case_id}`
        }

        async function updateOptions(order) {
        if(vm.options.name) {
          let payload = Object.assign({},vm.options)
          payload.city = payload.address
          payload.state = payload.address
          payload.zip_code = payload.address

          if(payload.id && payload.name) {
            const id = payload.id
            delete payload.id
            delete payload.archived
            delete payload.case_id
            
          const response = await drbOptions.updateDRBOptions(id, payload)
          } else {
            payload.case_id = order.case_id
            payload.order_id = order.id
          const response = await drbOptions.createDRBOptions(payload)
            
            
            vm.options = response.data
          }
        }
        
      }

      async function addSMS() {
        const sms_payload = {
          //STRIPE PLAN
          plans: [{plan: drbSubscriptions.sms_incoming}, {plan: drbSubscriptions.sms_outgoing}],
          email: vm.customer_info.email
        }
        const sms_sub = await drbSubscriptions.createSubscription(sms_payload)
        await retrieveStripeInfo()
      }

      async function checkout() {
        const res = await drbSubscriptions.getCardSession();
        vm.stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: res.data.id
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
      }

      async function cancelAllSubscriptions() {
        await Promise.all (
          vm.customer_info.subscriptions.data.map(async sub => {
            const result = await drbSubscriptions.cancelSubscription(sub.id)
            
          })
        )
        await retrieveStripeInfo()
      }

      async function cancelSubscription(id) {
        const result = await drbSubscriptions.cancelSubscription(id)
        await retrieveStripeInfo()
      }

      function tabNav(tab) {
        vm.showTab = tab
      }

      // function loadStripe() {
        
      //   var elements = vm.stripe.elements();
      
      //   var card = elements.create('card', {
      //       iconStyle: 'solid',
      //       style: {
      //         base: {
      //           iconColor: '#ccc',
      //           color: '#a6a6a6',
      //           fontWeight: 500,
      //           fontSize: '16px',
      //           fontSmoothing: 'antialiased',
        
      //           ':-webkit-autofill': {
      //             color: '#fce883',
      //           },
      //           '::placeholder': {
      //             color: '#ccc',
      //           },
      //         },
      //         invalid: {
      //           iconColor: '#ccc',
      //           color: '#ccc',
      //         },
      //       },
      //     });
      //   card.mount('#stripe-card');
      
      //   //registerElements([card], 'stripe-form');
        
      // }

				//******** end *********//
			}
		};
	}
})();