angular.module("app").service("user", function($http, $q, themes) {

	this.resetAppValues = function(){
		$http.defaults.headers.common["x-auth-token"] = null;
		localStorage.removeItem("token");
		this.user = null
		themes.categories = []
		themes.customThemes = []
		themes.pinnedThemes = []
	}

	this.signup = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "signup", payload).then(response => {
			localStorage.setItem("token", response.data.token);
			$http.defaults.headers.common["x-auth-token"] = response.data.token;
			this.trialDays = response.data.trialDays || null;
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.checkEmail = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "checkEmail", {email: payload}).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.login = function(payload) {
		const deferred = $q.defer();
		this.resetAppValues()
		$http.post(api + "signin", payload).then(response => {
			localStorage.setItem("token", response.data.token);
			let date = new Date()
			localStorage.setItem('time', date)
			$http.defaults.headers.common["x-auth-token"] = response.data.token;
			this.trialDays = response.data.trialDays || null;
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.refreshToken = function() {
		const deferred = $q.defer();
		let oldDate = new Date(localStorage.time)
		let newDate =  new Date()
		if(newDate - oldDate > 86400000) {
			$http.post(api + "refresh").then(response => {
				localStorage.setItem("token", response.data.token);
				let date = new Date().toString()
				localStorage.setItem('time', date)
				$http.defaults.headers.common["x-auth-token"] = response.data.token;
				deferred.resolve(response);
			}).catch(error => {
				deferred.resolve(error);
			});
			return deferred.promise;
		}

	};

	this.logout = function() {
		this.resetAppValues()
	};

	this.forgotPassword = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "forgot", {email: payload}).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.resetPassword = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "reset", payload).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	//TODO: update to deferred - test first?
	this.getIpLocation = function() {
		// const deferred = $q.defer();
		// $http.get(api + "iplocation").then(response => {
		// 	deferred.resolve(response)
		// }).cat(error => {
		// 	deferred.resolve(error)
		// })
		return $q.when($http.get(api + "iplocation"));
	};

	this.get = function() {
		const deferred = $q.defer();
		$http.get(api + "user").then(response => {
			this.user = response.data;
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.getStaffMembers = function() {
		const deferred = $q.defer();
		$http.get(api + `funeralHomeUsers`).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.createStaff = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "createStaff", payload).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.getNewStaff = function(token) {
		const deferred = $q.defer();
		$http.get(api + `newStaff/${token}`).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.removeStaff = function(user_id) {
		const deferred = $q.defer();
		$http.patch(api + `archiveUser/${user_id}`).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.resendActivationEmail = function(user_id) {
		const deferred = $q.defer();
		$http.get(api + `resendUser/${user_id}`).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.updateUsers = function(payload) {
		const deferred = $q.defer();
		let id = payload.id;
		delete payload.id;
		$http.patch(api + `user/${id}`, payload).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.update = function(payload) {
		const deferred = $q.defer();
		$http.patch(api + `user`, payload).then(response => {
			if(response.data.token) {
				localStorage.setItem("token", response.data.token);
				$http.defaults.headers.common["x-auth-token"] = response.data.token;
			}
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.updateManager = function(id, payload) {
		const deferred = $q.defer();
		$http.patch(api + `updateUserManager/${id}`, payload).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};

	this.sendInvite = function(payload) {
		const deferred = $q.defer();
		$http.post(api + "sendInviteToUploadCaseImages", payload).then(response => {
			deferred.resolve(response);
		}).catch(error => {
			deferred.resolve(error);
		});
		return deferred.promise;
	};
});
