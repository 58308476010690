(function() {

	angular.module('app').directive('envelopeLabels', envelopeLabelsController)

	function envelopeLabelsController(orders, cases, guests, funeralHomes, $q, tukios, user, $window, $timeout, helpers, $routeParams, drbSubscriptions) {
		return {

			templateUrl: 'pages/envelopeLabels/envelopeLabels.html',
      scope: true,
			link: function(scope) {
				var vm = scope

				angular.extend(scope, {
          guests: null,
          loading: true,
					selectedFont: 11,
					selectedWeight: null,
          selectWeight: selectWeight,
          updateFontSize: updateFontSize,
					backToDRB: backToDRB,
          updateFont: updateFont,
          fontSize: 12,
					guestLabels: [],
          fonts: [],
          year: null,
          present: null,
          printProduct: printProduct,
          printing: false,
        	updateSelection: updateSelection,
					getGuests: getGuests,
					is_authenticated: false,
					selection: 'name',
					type: 'guest',
					updateLabelType: updateLabelType,
					family_contact: '',
					family_contact_address: '',
					return_address: {},
					updateReturnAddress: updateReturnAddress

        })

        init()

				async function init() {
          vm.stripe = Stripe(drbSubscriptions.key);
          const subscriber = await drbSubscriptions.getActiveSubscription()
          if(subscriber.status === 200) {  
            await getGuests()
					} else {
            const res = await funeralHomes.get()
            if(res.data.stripe_subscription_status) {
              $location.path('/drbReturn')
            } else {
              $location.path('/drbLanding')
            }
            
          }
        }
        
        async function isServiceAuthenticated() {
					
					if(funeralHomes.home) {
						vm.is_authenticated = funeralHomes.home.is_service_authenticated;
            vm.freeTrial = funeralHomes.home.freeTrial !== null ? funeralHomes.home.freeTrial : null;
					} else {
            const HOME = await funeralHomes.get()
            vm.is_authenticated = HOME.data.is_service_authenticated;
            vm.freeTrial = HOME.data.freeTrial != null ? HOME.data.freeTrial : null;
					}
          vm.$digest()
        }
        
        async function getGuests() {
          const response = await guests.getGuests($routeParams.id)
          vm.guests = response.data
					vm.$digest()
					
					let page = 30
					let pageNames = []
					let count = 0
					for (let field in vm.guests){
						
						if(vm.guests[field].name) {
							if(vm.guests[field].address){
								pageNames.push(vm.guests[field])
								count ++
							} 
						}
					
						if(count == page || field == vm.guests.length -1){
							vm.guestLabels.push(pageNames)
							pageNames = []
							page = page + 30
						}
					}
					
				}
				
				async function updateLabelType() {
					if(vm.type === 'return') {
						const dbCase = await cases.get($routeParams.id)
						
						vm.family_contact_name = dbCase.data.family_contact_name ? dbCase.data.family_contact_name : ''
						vm.family_contact_address = dbCase.data.family_contact_address ? dbCase.data.family_contact_address : ''
						for (let i in vm.guestLabels) {
							for (let j in vm.guestLabels[i]) {
								vm.guestLabels[i][j] = {
									name: vm.family_contact_name,
									address: vm.family_contact_address,
									city: ''
								}
							}
						}
						vm.$digest()
					} else {
						vm.return_address = {}
						vm.guestLabels = []
						await getGuests()
						vm.$digest()
					}
				}

				function updateReturnAddress() {
					for (let i in vm.guestLabels) {
						for (let j in vm.guestLabels[i]) {
							vm.guestLabels[i][j] = {
								name: vm.return_address.name ? vm.return_address.name : vm.family_contact_name,
								address: vm.return_address.address ? vm.return_address.address : vm.family_contact_address,
								city: vm.return_address.address2 ? vm.return_address.address2 : ''
							}
						}
					}
					
				}

				function updateSelection() {
					if(vm.selection == 'name') {
						var obj = $('.outlines .name')
						vm.fontSize = parseInt(obj.css('font-size'))
						vm.selectedFont = null

					} else if (vm.selection == 'address'){
						obj = $('.outlines .address')
						vm.fontSize = parseInt(obj.css('font-size'))
						vm.selectedFont = null

					} else {
						obj = $('.outlines .city')
						vm.fontSize = parseInt(obj.css('font-size'))
						vm.selectedFont = null
					}
				}

        function updateFont(index) {
					let obj
					if(vm.selection == 'name') {
						obj = $('.outlines .name')
					} else  if(vm.selection == 'address') {
						obj = $('.outlines .address')
					} else {
						obj = $('.outlines .city')
					}
					obj.each(function() {
						$(this).css('font-family', vm.fonts[index].name)
						vm.selectedFont = index
						if (vm.fonts[index].weights) {
							if (vm.selectedWeight) {
								$(this).css('font-weight', vm.fonts[index].weights[vm.selectedWeight].weight)
							} else {
								$(this).css('font-weight', vm.fonts[index].weights[0].weight)
								vm.selectedWeight = 0
							}
						} else {
							$(this).css('font-weight', 'normal')
						}
					})
        }

        function selectWeight(index) {
          vm.selectedWeight = index
          updateFont(vm.selectedFont)
        }

        function updateFontSize() {
					let obj
					if(vm.selection == 'name') {
						obj = $('.outlines .name')
					} else  if(vm.selection == 'address') {
						obj = $('.outlines .address')
					} else {
						obj = $('.outlines .city')
					}
          obj.each(function() {
            $(this).css('font-size', vm.fontSize + 'px')
          })
        }

        async function printProduct() {
          vm.printing = true

					$('.outlines p').each(function(){
						if($(this).css('font-family') == '"Century Gothic"'){
							$(this).css({
								'-webkit-transform':'scale(0.98)',
								'-moz-transform':'scale(0.98)',
								'-ms-transform':'scale(0.98)',
								'transform':'scale(0.98)'
							})
						}
					})
					$('.outlines').css('border', '')
					$('.outlines').css('position', 'fixed')
					$('#productBuild .page').css('display', 'inherit')

					const html = $('#productBuild').html()

					const code = await prepCode(html)
					await generatePdf(code, true)

				 $('.outlines').css('position', 'inherit')
				 $('.outlines').css('border', '1px solid #ccc')
					vm.printing = false
				}

				function prepCode(html){
					let build = `${helpers.printHeader}${html}${helpers.printStyle}${helpers.printFooter}`

					build = build.replace(/elastic/g, '')
					const sizes = [8.5, 11]

					const product = {
						html: build,
						page_height: sizes[1],
						page_width: sizes[0]
					}
					product.html = product.html.replace(/transform:/g,'-webkit-transform:')
					vm.printing = false
					return product
				}

				async function generatePdf(product, isLastPdf){
					const response = await orders.generatePDF(product)
					const file = new Blob([response.data], {
						type: 'application/pdf'
					});
					let fileURL
					if(window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(file, 'product.pdf');
					} else {
						fileURL = URL.createObjectURL(file);
						const openWindow = window.open(fileURL);
						try {
							openWindow.focus()
						}
						catch(err) {
							$('#popup-modal').modal('show')
						}
					}
				}

        function backToDRB() {
          window.location.href = '/drbPortal'
        }

				vm.fonts = helpers.fonts
      }
    }
  }
})();
