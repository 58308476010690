(function() {
  angular.module("app").directive("settings", settingsController);

  function settingsController(
    helpers,
    user,
    funeralHomes,
    $compile,
    $timeout,
    $rootScope,
    orders,
    products,
    collections,
    funeralHomeSettings,
    funeralHomeLogo,
    layouts,
    pages,
    funeralHomeDefaultLayouts,
  ) {
    return {
      templateUrl: "pages/settings/settings.html",
      controllerAs: "vm",
      controller: function() {
        var vm = this;

        angular.extend(vm, {
          //Variables and Functions
          tab: "staff",
          form: {},
          addStaff: addStaff,
          removeStaff: removeStaff,
          staffMembers: null,
          removeStaffModal: removeStaffModal,
          home: {},
          resendActivationEmail: resendActivationEmail,
          updateFuneralHome: updateFuneralHome,
          showDeleteAccountModal: showDeleteAccountModal,
          deleteAccount: deleteAccount,
          accountid: null,
          user: {},
          updateUser: updateUser,
          saving: null,
          message: null,
          error: null,
          selectedFont: "Century Gothic",
          dateFormats: [],
          fonts: helpers.fonts,
          updateFont: updateFont,
          updateDate: updateDate,
          previewDate: previewDate,
          preferences: {},
          casefields: [],
          updatePresets: updatePresets,
          printSettings: [],
          page: {},
          printProduct: printProduct,
          settings: {},
          products: [],
          selectedProduct: null,
          selectProduct: selectProduct,
          selectedLayout: null,
          selectLayout: selectLayout,
          adminDefault: true,
          resetDefault: resetDefault,
          selectedCollection: null,
          selectCollection: selectCollection,
          collections: [],
          selectedWeight: null,
          scale: [],
          deleteLayout: deleteLayout,
          updatePrintParameters: updatePrintParameters,
          print: false,
          resend: false,
          changeManager: changeManager,
          disablePasswordReset: false,
          logos: {},
          uploadLogo: uploadLogo,
          updateLayout: updateLayout,
          layoutProducts: [],
          layoutLayouts: [],
          selectedLayoutProduct: null,
          selectLayoutProduct: selectLayoutProduct,
          selectDefaultLayout: selectDefaultLayout,
          selectedPMDLayout: null,
          deleteLayoutLayout: deleteLayoutLayout,
          passwordReset: {},
          previewDate: previewDate
        });

        init();

        function init() {
          getUser();
          getHomeInfo();
          getHomeSettings();
          getProducts();
          getPMDProducts();
          getDateInfo();
        }

        function getUser() {
          user.get().then(response => {
            vm.user = response.data;
            vm.userEmail = response.data.email
            vm.userName = response.data.name
            if(vm.user.email.split('.').pop() == 'dps'){
              vm.disablePasswordReset = true
            }
          });
        }

        function getStaffMembers() {
          user.getStaffMembers().then(response => {
            vm.staffMembers = response.data;
            for (let i in response.data) {
              if (response.data[i].manager) {
                vm.home.manager = response.data[i].name;
              }
            }
          });
        }

        function getLogos() {
          funeralHomeLogo.getLogos().then(response => {
            vm.logos = response.data
          });
        }

        function getHomeInfo() {
          if (funeralHomes.home) {
            vm.home = funeralHomes.home;
            getStaffMembers();
            getLogos();
          } else {
            funeralHomes.get().then(response => {
              vm.home = response.data;
              getStaffMembers();
              getLogos();
            });
          }

        }

        function getDateInfo() {
          vm.dateFormats = [
            'M/D/YY',
            'MM/DD/YY',
            'M/D/YYYY',
            'MM/DD/YYYYY',
            'YYYY MMM D',
            'YYYY MMMM D',
            'YYYY MMM Do',
            'YYYY MMMM Do',
            'MMM D, YYYY',
            'MMM Do, YYYY',
            'MMMM D, YYYY',
            'MMMM Do, YYYY',
            'dddd, MMMM D, YYYY',
            'dddd, MMMM Do, YYYY',
          ]
        }

        function getHomeSettings() {
          funeralHomeSettings.get().then(response => {
            if (response.status == 200 && response.data) {
              vm.settings = {};
              vm.print = true;
              vm.settings.id = response.data.id
              vm.settings.left = parseFloat(response.data.print_parameter_left) || null;
              vm.settings.top = parseFloat(response.data.print_parameter_top) || null;
              vm.settings.right = parseFloat(response.data.print_parameter_right) || null;
              vm.settings.bottom = parseFloat(response.data.print_parameter_bottom) || null;

              vm.caseFields.name_of_deceased.newTitle = response.data.case_title_name_of_deceased || ''
              vm.caseFields.date_of_birth.newTitle = response.data.case_title_date_of_birth || ''
              vm.caseFields.date_of_death.newTitle = response.data.case_title_date_of_death || ''
              vm.caseFields.clergy_name.newTitle = response.data.case_title_clergy_name || ''
              vm.caseFields.interment_place.newTitle = response.data.case_title_interment_place || ''
              vm.caseFields.service_date.newTitle = response.data.case_title_service_date || ''

              if(response.data.default_font) {
                for (let index in vm.fonts) {
                  if (vm.fonts[index].name === response.data.default_font) {
                    vm.selectedFont = index;
                    vm.settings.defaultFont = response.data.default_font
                  }
                }
              }
              if(response.data.default_pmd_layout) {
                vm.settings.layout = response.data.default_pmd_layout
              }
              if(response.data.date_format) {
                vm.settings.dateFormat = response.data.date_format
              }
            }
            else {
              vm.print = false;
              if (!vm.settings) {
                vm.settings = {};
                vm.settings.left = 0;
                vm.settings.top = 0;
              }
            }

            if (vm.settings) {
              vm.settings.horizontal = vm.settings.right != null ? "right" : "left"
              vm.settings.vertical = vm.settings.bottom != null ? "down" : "top"
            } else {
              vm.settings.horizontal = "left";
              vm.settings.vertical = "top";
            }
            buildProduct();
          });
        }

        function addStaff() {
          vm.saving = true;
          vm.error = null;
          if (!vm.form.name) {
            vm.saving = false;
            vm.error = "Please enter a name";
          } else if (!validateEmail(vm.form.email)) {
            vm.saving = false;
            vm.error = "Please enter a valid email";
          } else {
            vm.form.password = Math.random()
              .toString(36)
              .substring(7);
            user.createStaff(vm.form).then(response => {
              if(response.status == 409) {
                vm.error = "This email is already in use."
              }
              $timeout(() => {
                vm.saving = false;
                vm.form = {};
                getStaffMembers();
              }, 1000);
            });
          }
        }

        function removeStaffModal(id) {
          vm.staffid = id;
          $("#delete-staff-modal").modal("show");
        }

        function showDeleteAccountModal() {
          $("#delete-account-modal").modal("show");
        }

        function deleteAccount() {
          user.removeStaff(vm.user.id).then(response => {
            user.logout();
            // Keep window.location instead of $location because we want to reset variables
            window.location.href = "/";
          });
        }

        function removeStaff() {
          user.removeStaff(vm.staffid).then(data => {
            getStaffMembers();
            $("#delete-staff-modal").modal("hide");
          });
        }

        function resendActivationEmail(id) {
          vm.resend = true;
          user.resendActivationEmail(id).then(() => {
            alert('Email Sent')
          });
        }

        function updatePrintParameters() {
          //make sure any printer settings are only shifting content in one direction
          let settingsPayload = {}

          if (vm.settings.horizontal) {
            if (vm.settings.horizontal == "left") {
              vm.settings.right = null;
              settingsPayload.print_parameter_left = vm.settings.left
              settingsPayload.print_parameter_right = vm.settings.right
            } else if (vm.settings.horizontal == "right") {
              vm.settings.left = null;
              settingsPayload.print_parameter_right = vm.settings.right
              settingsPayload.print_parameter_left = vm.settings.left
            }
          }
          if (vm.settings.vertical) {
            if (vm.settings.vertical == "top") {
              vm.settings.bottom = null;
              settingsPayload.print_parameter_top = vm.settings.top
              settingsPayload.print_parameter_bottom = vm.settings.bottom
            } else if (vm.settings.vertical == "down") {
              vm.settings.top = null;
              settingsPayload.print_parameter_bottom = vm.settings.bottom
              settingsPayload.print_parameter_top = vm.settings.top
            }
          }
          if(vm.settings.defaultFont) {
            settingsPayload.default_font = vm.settings.defaultFont
          }
          if(vm.settings.dateFormat) {
            settingsPayload.date_format = vm.settings.dateFormat
          }

          settingsPayload.case_title_name_of_deceased = vm.settings.case_title_name_of_deceased || null

          settingsPayload.case_title_date_of_birth = vm.settings.case_title_date_of_birth || null

          settingsPayload.case_title_date_of_death = vm.settings.case_title_date_of_death || null

          settingsPayload.case_title_clergy_name = vm.settings.case_title_clergy_name || null

          settingsPayload.case_title_interment_place = vm.settings.case_title_interment_place || null

          settingsPayload.case_title_service_date = vm.settings.case_title_service_date || null

          if (vm.print || vm.settings.id) {
            funeralHomeSettings.update(vm.settings.id, settingsPayload).then(response => {
              if(response.status != 200) {
                vm.homeerror = 'Something went wrong, try again.'
              } else {
                vm.message = 'Saved!'
              }
            });
          } else {
            funeralHomeSettings.create(settingsPayload).then(response => {
              if(response.status != 200) {
                vm.homeerror = 'Something went wrong, try again.'
              } else {
                vm.message = 'Saved!'
              }
            });
          }
          buildProduct()
          $timeout(function () {
            vm.message = null
            vm.homeerror= null
          }, 3000);
        }

        function changeManager(member) {
          vm.home.manager = member.name;
          for (let i in vm.staffMembers) {
            vm.staffMembers[i].manager = vm.staffMembers[i].id == member.id ? true : false
          }
        }

        function updateLayout(layout) {
          vm.settings.layout = layout
          updatePrintParameters()
        }


        function updateFuneralHome() {
          vm.saving = true;
          vm.homeerror = null;
          if (!vm.home.name) {
            vm.saving = false;
            vm.homeerror = "Please enter a valid funeral home name";
          } else {
            funeralHomes
              .update({ name: vm.home.name })
              .then(response => {
                vm.saving = false;
                vm.message = "Successfully Saved Changes.";
                for (let k in vm.staffMembers) {
                  let payload = {}
                  payload.manager = vm.staffMembers[k].manager
                  user.updateManager(vm.staffMembers[k].id, payload).then(user => {
                    getStaffMembers();
                  });
                }

                $timeout(function() {
                  vm.message = null;
                }, 2000);
              });
          }
          buildProduct();
        }

        function uploadLogo(size){
          $timeout(() => {
            if(size == 'small'){
              $('#small_logo')[0].click()
            } else if(size == 'medium'){
              $('#medium_logo')[0].click()
            } else if(size == 'large'){
              $('#large_logo')[0].click()
            }
          })
        }

        $rootScope.$on('smallLogoUpload', (event, payload) => {
          vm.logos = payload
        })

        $rootScope.$on('mediumLogoUpload', (event, payload) => {
          vm.logos = payload
        })

        $rootScope.$on('largeLogoUpload', (event, payload) => {
          vm.logos = payload
        })

        //Custom PageSet Functions

        function getProducts() {
          collections.getAll().then(response => {
            vm.collections = response.data;
          });
        }

        function selectProduct(product) {
          vm.selectedProduct = product;
          layouts.get(product.id).then(response =>{
            vm.defaultTemplates = response.data
            vm.selectedLayout = null;
          })
        }

        function filterSelected(layouts) {
          let adminTemplateIndex = null
          let adminTemplateDefault = true
          layouts.map((layout, index) => {
            !layout.home_template && layout.name === "Admin" && (adminTemplateIndex = index)
            layout.default && layout.home_template && layout.name !== "Admin" && (adminTemplateDefault = false)
          })
          !adminTemplateDefault && layouts[adminTemplateIndex] && (layouts[adminTemplateIndex].default = false)
          return layouts
        }

        function selectLayout(layout) {
          vm.selectedLayout = layout;
          pages.getHomeLayoutPageSets(layout.id).then(response =>{
            vm.pageSets = filterSelected(response.data)
          })
        }

        function selectCollection(index) {
          vm.selectedCollection = vm.collections[index];
          products.getByCollection(vm.collections[index].id).then(response =>{
            vm.products = response.data
          })
        }

        function getPMDProducts() {
          collections.getAll().then(response => {
            for(let collection of response.data) {
              if(collection.name == 'Precious Memories') {
                products.getByCollection(collection.id).then(products =>{
                  vm.layoutProducts
                  for (let product of products.data) {
                    layouts.get(product.id).then(response =>{
                      if (response.data.length > 1) {
                        vm.layoutProducts.push(product)
                      }
                    })
                  }

                })
              }
            }
          });
          funeralHomeDefaultLayouts.get().then(response => {
            vm.defaultLayouts = response.data
          })
        }

        function selectLayoutProduct(product) {
          vm.selectedPMDLayout = null
          vm.selectedLayoutProduct = product
          layouts.get(product.id).then(response =>{
            vm.layoutLayouts = response.data
          })
        }

        function selectDefaultLayout(layout) {
          for(let item of vm.defaultLayouts) {
            if (item.product.id == layout.product_id) {
              funeralHomeDefaultLayouts.delete(item.id).then(response => {
              })
            }
          }
          let payload = {}
          payload.layout_id = layout.id
          funeralHomeDefaultLayouts.create(payload).then(() => {
            funeralHomeDefaultLayouts.get().then(response => {
              vm.defaultLayouts = response.data
            })
          })
        }

        function deleteLayoutLayout(layout) {
          funeralHomeDefaultLayouts.delete(layout.id).then(() => {
            funeralHomeDefaultLayouts.get().then(response => {
              vm.defaultLayouts = response.data
            })
          })

        }

        function resetDefault(temp) {
          for (let pageSet of vm.pageSets) {
            if(pageSet.id != temp.id ) {
              let setPayload = Object.assign({}, pageSet)
              setPayload.default = false
              pageSet.default = false
              let id = setPayload.id
              delete setPayload.id
              delete setPayload.archived
              if(pageSet.home_template) {
                pages.updatePageSet(id, setPayload).then(response =>{})
              }
            } else {
              let setPayload = Object.assign({}, pageSet)
              setPayload.default = true
              pageSet.default = true
              let id = setPayload.id
              delete setPayload.id
              delete setPayload.archived
              pages.updatePageSet(id, setPayload).then(response =>{})
            }
          }
        }

        function deleteLayout(temp) {
          if(temp.home_template) {
            pages.archivePageSet(temp.id).then(response =>{
              pages.getHomeLayoutPageSets(vm.selectedLayout.id).then(response =>{
                vm.pageSets = filterSelected(response.data)
              })
            })
          }
        }

        function updateUser() {
          let payload = {};
          vm.saving = true;
          vm.usererror = null;

          function validateForm(){
            return new Promise((resolve, reject) => {
              if(vm.userEmail !== vm.user.email){
                user.checkEmail(vm.user.email).then(response => {
                  if (response.status !== 200) {
                    reject(response.data.message)
                  } else if(vm.userName !== vm.user.name && vm.user.name.length < 1) {
                    reject('Please enter a user name')
                  } else if(vm.passwordReset.new !== vm.passwordReset.repeat){
                    reject('Passwords do not match')
                  } else if (vm.passwordReset.new.length < 5) {
                    reject('Passsword must be at least 5 characters long.')
                  } else {
                    resolve()
                  }
                })
              } else {
                if(vm.user.name !== vm.user.name || vm.user.name.length < 1) {
                  reject('Please enter a user name')
                } else if(vm.passwordReset.new !== vm.passwordReset.repeat){
                  reject('Passwords do not match')
                } else if (vm.passwordReset.new.length < 5) {
                  reject('Passsword must be at least 5 characters long.')
                }else {
                  resolve()
                }
              }
            })
          }

          validateForm().then(message => {
            payload.name = vm.user.name;
            payload.email = vm.user.email;
            payload.password = vm.passwordReset.new;
            user.update(payload).then(response => {
              vm.user.newPassword = undefined;
              vm.user.repeatPassword = undefined;
              vm.user = response.data
              vm.usererror = 'Saved!'
              getStaffMembers();
              $timeout(() => {
                vm.usererror = null
              }, 2000)
              vm.saving = false
            })
          }).catch(err => {
            $timeout(() => {
              vm.usererror = err
              vm.saving = false
            }, 100)
          })
        }

        function updateFont(index) {
          vm.selectedFont = index;
          vm.settings.defaultFont = vm.fonts[index].name;
          updatePrintParameters()
        }

        function updateDate(index) {
          vm.settings.dateFormat = index
          updatePrintParameters()
        }

        function previewDate(format) {
          return moment().format(format)
        }

        function updatePresets() {
          for (let key in vm.caseFields) {
            if (!vm.caseFields.hasOwnProperty(key)) continue;
            let obj = vm.caseFields[key];
            for (let prop in obj) {
              if(prop == 'newTitle') {
                if(obj.newTitle.length == 0) {
                  vm.settings[obj.titleField] = null
                } else {
                  vm.settings[obj.titleField] = obj.newTitle
                }
              }
            }
          }
          updatePrintParameters()
        }

        function buildProduct() {
          vm.page.sizes = [11, 8.5];
          vm.page.positions = [0.5, 0.25];
          //update the print page with correct offsets
          if (vm.settings) {
            if (vm.settings.top) {
              vm.page.top = (vm.page.positions[1] - vm.settings.top) * 72;
            } else if (vm.settings.bottom) {
              vm.page.top = (vm.page.positions[1] + vm.settings.bottom) * 72;
            } else {
              vm.page.top = vm.page.positions[1] * 72;
            }
            if (vm.settings.left) {
              vm.page.left = (vm.page.positions[0] - vm.settings.left) * 72;
            } else if (vm.settings.right) {
              vm.page.left = (vm.page.positions[0] + vm.settings.right) * 72;
            } else {
              vm.page.left = vm.page.positions[0] * 72;
            }
          } else {
            vm.page.left = vm.page.positions[0] * 72;
            vm.page.top = vm.page.positions[1] * 72;
          }
          //ensure offset does not go off the page
          if (vm.page.top < 0) vm.page.top = 0;
          if (vm.page.left < 0) vm.page.left = 0;

          $(".printPage").css({
            padding: vm.page.top + "px 0 0 " + vm.page.left + "px",
            height: vm.page.sizes[1] * 72 + "px",
            width: vm.page.sizes[0] * 72 + "px",
            position: "relative",
            "font-family": "Century Gothic"
          });

          $(".printOutlines").css({
            "border-left": "1px solid #888",
            "border-top": "1px solid #888",
            "font-family": "Century Gothic",
            width:
              (11 - (vm.page.positions[0] * 2 - vm.settings.left)) * 72 + "px",
            height:
              (8 - (vm.page.positions[1] * 2 - vm.settings.top)) * 72 + "px"
          });

          $(".eightLine").css({
            border: "1px solid #888",
            position: "absolute",
            top: "400px",
            left: 8 * 72 + vm.page.left + "px",
            width: "1px",
            height: "150px"
          });
          $(".boxes").css({
            position: "absolute",
            height: "75px",
            border: "1px solid #888",
            width: "140px",
            "tex-align": "center",
            "padding-top": "5px",
            "font-size": "12px"
          });
          $(".listPrint p").css({
            "font-family": "Century Gothic",
            width: "600px",
            color: "#194c73",
            "line-height": "18px",
            "font-size": "12px",
            "margin-bottom": "0px"
          });
          $(".listPrint .printNumber").css({
            "padding": "5px 0px"
          });
          $(".listPrint .printItem").css({
            "padding-left": "15px",
            "font-style": "italic"
          });

          //arrow styling
          $(".arrow").css({
            width: 8 * 72 + "px",
            left: vm.page.left + "px",
            top: "500px",
            position: "absolute"
          });
          $(".line").css({
            width: 8 * 72 - 20 + "px",
            left: "12px",
            position: "absolute",
            "margin-top": "5px",
            background: "blue",
            height: "2px",
            float: "left"
          });
          $(".top").css({
            top: vm.page.top + "px",
            position: "absolute",
            left: "350px",
            width: "200px",
            height: "50px",
            "font-size": "12px",
            border: "1px solid #888",
            padding: "5px 20px"
          });
          $(".point").css({
            width: "0",
            height: "0",
            "border-top": "6px solid transparent",
            "border-bottom": "6px solid transparent"
          });
          $(".right-point").css({
            "border-left": "12px solid blue",
            float: "right"
          });
          $(".left-point").css({
            "border-right": "12px solid blue",
            float: "left"
          });
          $("#productBuild .theme").css("background-image", "none");
        }

        function printProduct() {
          $("#page textarea").each(function() {
            let textarea = $(this).prop("outerHTML");
            let paragraph = textarea.replace(/textarea/g, "p");
            paragraph = paragraph.replace(/\r\n|\r|\n/g, "<br />");
            $(this).replaceWith(paragraph);
          });

          $("#page p").each(function() {
            if ($(this).css("font-family") == '"Century Gothic"') {
              $(this).css({
                "-webkit-transform": "scale(0.98)",
                "-moz-transform": "scale(0.98)",
                "-ms-transform": "scale(0.98)",
                transform: "scale(0.98)"
              });
            }
          });

          let html = $("#printBuild").html();

          let code = prepCode(html);
          generatePdf(code, true);

          function prepCode(html) {
            let build = helpers.printHeader + html + helpers.printStyle + helpers.printFooter;
            build = build.replace(/elastic/g, "");
            let sizes = vm.page.sizes;

            let product = {
              html: build,
              page_height: 8.5,
              page_width: 11
            };
            product.html = product.html.replace(
              /transform:/g,
              "-webkit-transform:"
            );
            return product;
          }

          function generatePdf(product, isLastPdf) {
            orders.generatePDF(product).then(response => {
              let file = new Blob([response.data], {
                type: "application/pdf"
              });
              let fileURL;
              if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, "product.pdf");
              } else {
                fileURL = URL.createObjectURL(file);
                let openWindow = window.open(fileURL);
                try {
                  openWindow.focus();
                } catch (err) {
                  $("#popup-modal").modal("show");
                }
              }
            });
          }
        }

        vm.caseFields = {
          name_of_deceased: {
            titleField: "case_title_name_of_deceased",
            field: "case.name_of_deceased",
            title: "In Memory of",
            preset: true,
            newTitle: '',
          },
          date_of_birth: {
            titleField: "case_title_date_of_birth",
            field: "case.date_of_birth",
            title: "Date of Birth",
            preset: true,
            newTitle: '',
          },
          date_of_death: {
            titleField: "case_title_date_of_death",
            field: "case.date_of_death",
            title: "Date of Death",
            preset: true,
            newTitle: '',
          },
          clergy_name: {
            titleField: "case_title_clergy_name",
            field: "case.clergy_name",
            title: "Clergy",
            preset: true,
            newTitle: '',
          },
          interment_place: {
            titleField: "case_title_interment_place",
            field: "case.interment_place",
            title: "Place of Interment",
            preset: true,
            newTitle: '',
          },
          service_date: {
            titleField: "case_title_service_date",
            field: "case.service_date",
            title: "Service Date",
            preset: true,
            newTitle: '',
          }
        };

        vm.printSettings = [
          { value: 0, label: "None" },
          { value: 0.03125, label: '1/32"' },
          { value: 0.0416, label: '1/24"' },
          { value: 0.0625, label: '1/16"' },
          { value: 0.125, label: '1/8"' },
          { value: 0.25, label: '1/4"' }
        ];

        vm.layouts = ['Full Bleed', 'White Border']

        //******** end *********//
      }
    };
  }
})();
