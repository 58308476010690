(function() {
  angular.module("app").directive("dashboard", dashboardController);

  function dashboardController(
    orders,
    cases,
    $q,
    tukios,
    user,
    $window,
    $timeout,
    $routeParams,
    $location,
    $rootScope,
    socket,
  ) {
    return {
      templateUrl: "pages/dashboard/dashboard.html",
      controllerAs: "vm",
      controller: function($scope, $location) {
        var vm = this;

        angular.extend(vm, {
          activeSearch: false,
          orders: null,
          tab: "active",
          orderBy: "-sortable_created_at",
          descending: true,
          orders: [],
          loading: false,
          archiveOrder: archiveOrder,
          unarchiveOrder: unarchiveOrder,
          showArchiveOrder: showArchiveOrder,
          showUnarchiveOrder: showUnarchiveOrder,
          editOrderCase: editOrderCase,
          createTukiosFilm: createTukiosFilm,
          goToOrder: goToOrder,
          goToLabels: goToLabels,
          showPrintInfo: showPrintInfo,
          hidePrintModal: hidePrintModal,
          searchCriteria: "Name",
          searchMessage: false,
          dateSearchEnd: null,
          dateSearchStart: null,
          getActiveOrders: getActiveOrders,
          getArchivedOrders: getArchivedOrders,
          searchOrders: searchOrders,
          page: 1,
          pages: [],
          // DATE VARIABLES
          open1: open1,
          open2: open2,
          inlineOptions: {
            customClass: getDayClass,
            minDate: new Date(),
            showWeeks: true
          },
          dateOptions: {
            formatYear: "yyyy",
            maxDate: new Date(2020, 5, 22),
            minDate: new Date(),
            startingDay: 1
          },
          altInputFormats: ["M!/d!/yyyy"],
          today: today,
          clear: clear,
          toggleMin: toggleMin,
          popup1: { opened: false },
          popup2: { opened: false },
          events: [
            {
              date: tomorrow,
              status: "full"
            },
            {
              date: afterTomorrow,
              status: "partially"
            }
          ],
          goToPrint: goToPrint,
          archiveList: [],
          archiveMany: archiveMany,
          addArchiveList: addArchiveList,
          archiveShow: [],
          duplicateOrder: duplicateOrder,
        });

        init();

        function init() {
          getActiveOrders();
          toggleMin();
          clearDefaultImage();
          user.refreshToken()
          socket.disconnect()
          // isIEModal()
          isBetaModal()
        }

        function isIEModal() {
          if(isIE || isEdge) {
            $('#is-IE-modal').modal('show')
          }
        }

        function isBetaModal() {
          if(isBETA) {
            $('#is-BETA-modal').modal('show')
          }
        }

        function getActiveOrders(page) {
          page ? vm.page = page : vm.page = 1
          vm.tab = "active";
          vm.orders = [];
          vm.pages = [];
          orders.getFuneralHomeOrders(false, page - 1 || 0).then(response => {
            const range = Math.ceil(response.data.total / 20);
            for (let i = 1; i <= range; i++) {
              vm.pages.push(i);
            }
            vm.orders = response.data.orders
          });
        }

        function getArchivedOrders(page) {
          page ? vm.page = page : vm.page = 1

          vm.tab = "archived";
          vm.orders = [];
          vm.pages = [];
          orders.getFuneralHomeOrders(true, page - 1 || 0).then(response => {
            const range = Math.ceil(response.data.total / 20);
            for (let i = 1; i <= range; i++) {
              vm.pages.push(i);
            }
            vm.orders = response.data.orders
          });
        }

        function searchOrders(searchTerm, page) {
          page ? vm.page = page : vm.page = 1
          vm.searchTerm = searchTerm
          vm.activeSearch = true;
          vm.orders = [];
          vm.pages = [];
          vm.searchError = null;
          let searchArchived = false;
          vm.tab == "archived"
            ? (searchArchived = true)
            : (searchArchived = false);

          const request = {
            archived: searchArchived,
            page: page - 1 || 0
          };

          const validateForm = new Promise((resolve, reject) => {
            if (searchTerm == "name_of_deceased") {
              if (vm.nameSearch) {
                request[searchTerm] = vm.nameSearch;
                vm.idSearch = null
                vm.dateSearchStart = null
                vm.dateSearchEnd = null
                vm.editableCaseIdSearch = null
                resolve();
              } else {
                reject("Please enter a name to search");
              }
            } else if (searchTerm == "date_of_death") {
              if (!vm.dateSearchStart || !vm.dateSearchEnd) {
                reject("Please enter a start and end date.");
              } else {
                (request["date_of_death_start"] = vm.dateSearchStart),
                  (request["date_of_death_end"] = vm.dateSearchEnd);
                vm.idSearch = null
                vm.nameSearch = null
                resolve();
              }
            } else if (searchTerm == "editable_case_id") {
              if (vm.editableCaseIdSearch) {
                request[searchTerm] = vm.editableCaseIdSearch;
                vm.idSearch = null
                vm.dateSearchStart = null
                vm.dateSearchEnd = null
                vm.nameSearch = null
                resolve();
              } else {
                reject("Please enter an editable case id");
              }
            }
          });

          validateForm
            .then(() => {
              orders.searchFuneralHomeOrders(request).then(response => {
                if (response.status !== 200) {
                  if(response.data.validation){
                    vm.searchError = response.data.validation[0].message
                    if(vm.searchError.indexOf('GUID') != -1) {
                      vm.searchError = 'Case ID must be long form ID not Editable Case ID'
                    }
                  } else {
                    vm.searchError = response.data.message;
                  }
                } else if (response.data.orders.length < 1) {
                  vm.searchError = "No orders matched your search.";
                } else {
                  var range = Math.ceil(response.data.total / 20);
                  for (var i = 1; i <= range; i++) {
                    vm.pages.push(i);
                  }
                  vm.orders = response.data.orders
                  response.data.orders.map(order => {
                  });
                }
              });
            })
            .catch(error => {
              vm.searchError = error;
              $scope.$apply();
            });
        }

        function createTukiosFilm(order) {
            if(!order.case.date_of_death){
              alert('Please update the case details for this order to include Date of Death')
            } else if(!order.case.date_of_birth){
              alert('Please update the case details for this order to include Date of Birth')
            } else {
              tukios.create({
                order_id: order.id,
                case_id: order.case.id,
                date_of_birth: order.case.date_of_birth,
                date_of_death: order.case.date_of_death,
                name_of_deceased: order.case.name_of_deceased
              }).then(response => {
                if(response.status == 200){
                  if(response.data.url){
                    $window.open(response.data.url, '_blank')
                  }
                }
              })
            }
        }

        function showArchiveOrder(order) {
          getSocket(order.case.id).then(data => {
            if(data.success) {
              vm.archiveId = order.id;
              $("#archive-modal").modal("show");
            } else {
              lockedSocket(data)
            }
          })
        }

        function showUnarchiveOrder(order) {
          getSocket(order.case.id).then(data => {
            if(data.success) {
              vm.archiveId = order.id;
              $("#un-archive-modal").modal("show");
            } else {
              lockedSocket(data)
            }
          })
        }

        function goToOrder() {
          localStorage.removeItem("order_id");
          orders.newOrder = true
          $location.path("order");
        }

        function goToLabels() {
          $location.path("labels");
        }

        function showPrintInfo() {
          if (!localStorage.hidePrintModal) {
            $("#print-alert").modal("show");
          }
        }

        function goToPrint(orderId, caseId) {
          getSocket(caseId).then(data => {
            if(data.success) {
              $location.path(`order/${orderId}/${caseId}`)
            } else {
              lockedSocket(data)
            }
          })
        }

        function hidePrintModal() {
          localStorage.hidePrintModal = true;
        }

        function archiveOrder() {
          if(!vm.archiveList.length) {
            orders.archiveOrder(vm.archiveId).then(response => {
              getActiveOrders();
              $("#archive-modal").modal("hide");
              $("#un-archive-modal").modal("hide");
            });
          } else {
            for(let order of vm.archiveList) {
              orders.archiveOrder(order.id).then(response => {
                $("#archive-modal").modal("hide");
                //$("#un-archive-modal").modal("hide");
              });
            }
            $timeout(function() {
              vm.archiveList = []
              getActiveOrders()
            }, 1000)
          }
        }

        function unarchiveOrder() {
          orders.unarchiveOrder(vm.archiveId).then(response => {
            getArchivedOrders();
            $("#archive-modal").modal("hide");
            $("#un-archive-modal").modal("hide");
          });
        }

        function editOrderCase(orderId, caseId) {
          getSocket(caseId).then(data => {
            if(data.success) {
              orders.editCase = true
              $location.path(`order/${orderId}/${caseId}`);
            } else {
              lockedSocket(data)
            }
          })
        }

        // DATE FUNCTIONS
        function today() {
          vm.dateSearchStart = new Date();
          vm.dateSearchEnd = new Date();
        }

        function clear() {
          vm.nameSearch = "";
          vm.idSearch = "";
          vm.editableCaseIdSearch = "";
          vm.dateSearchStart = null;
          vm.dateSearchEnd = null;
          vm.searchError = null;
          getActiveOrders();
        }

        function toggleMin() {
          vm.inlineOptions.minDate = vm.inlineOptions.minDate
            ? null
            : new Date();
          vm.dateOptions.minDate = vm.inlineOptions.minDate;
        }

        function clearDefaultImage() {
          localStorage.removeItem('defaultImgUpdated')
        }

        function setDate(year, month, day) {
          vm.dateSearchStart = new Date(year, month, day);
        }

        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        var afterTomorrow = new Date();
        afterTomorrow.setDate(tomorrow.getDate() + 1);

        function getDayClass(data) {
          var date = data.date,
            mode = data.mode;
          if (mode === "day") {
            var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

            for (var i = 0; i < vm.events.length; i++) {
              var currentDay = new Date(vm.events[i].date).setHours(0, 0, 0, 0);

              if (dayToCheck === currentDay) {
                return vm.events[i].status;
              }
            }
          }

          return "";
        }

        function open1() {
          vm.popup1.opened = true;
        }

        function open2() {
          vm.popup2.opened = true;
        }

        function getSocket(case_id) {
          var deferred = $q.defer();
          if(socket.connectedSocket){
            if (socket.connectedSocket.connected) {
              socket.emit('check_case_connection', vm.socketData)
              socket.on('case_connection', function(data){
                  deferred.resolve(data)
              })
              return deferred.promise
            } else {
              socket.disconnect()
              user.get().then(response => {
                socket.connect()
                  vm.socketData = {
                    user_id : user.user.id,
                    case_id : case_id
                  }
                  socket.emit('check_case_connection', vm.socketData)
                  socket.on('case_connection', function(data){
                      deferred.resolve(data)
                  })
              })
              return deferred.promise
            }
          } else {
              socket.disconnect()
              user.get().then(response => {
                socket.connect()
                  vm.socketData = {
                    user_id : user.user.id,
                    case_id : case_id
                  }
                  socket.emit('check_case_connection', vm.socketData)
                  socket.on('case_connection', function(data){
                      deferred.resolve(data)
                  })
              })
              return deferred.promise
            }

        }

        function lockedSocket(data) {
          socket.disconnect()
          vm.lockedCase = data.message
          $('#case-locked-modal').modal('show');
        }

        function archiveMany() {
          vm.archiveList = vm.orders.filter(order => {
            return order.checked
          })
          $("#archive-modal").modal("show");

        }

        function addArchiveList() {
          $timeout(function(){
            vm.archiveShow = vm.orders.filter(order => {
              return order.checked
            })
          }, 200)
        }

        function duplicateOrder(order){
          $rootScope.duplicateOrder = order
          localStorage.removeItem("order_id");
          orders.newOrder = true
          $location.path("order");
        }
        //******** end *********//
      }
    };
  }
})();
