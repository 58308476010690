angular.module("app").service("themes", function($http, $q, $rootScope) {
  const service = this;

  this.categories = []
  this.pinnedThemes = []
  this.lastSelectedProduct = ''
  this.selectedLayouts = {}

  this.getThemeCategoriesByCollection = function(collection_id) {
    const deferred = $q.defer();
    $http
      .get(api + `themeCategories?collection_id=${collection_id}`)
      .then(response => {
        deferred.resolve(response);
        this.categories = response.data
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemesByCategory = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themes?theme_category_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getPinnedThemes = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `pinnedThemes?collection_id=${id}`)
      .then(response => {
        deferred.resolve(response);
        this.pinnedThemes = response.data
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.createPinnedThemes = function(payload) {
    const deferred = $q.defer();
    $http
      .post(api + `createPinnedTheme`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.deletePinnedThemes = function(id) {
    const deferred = $q.defer();
    $http
      .delete(api + `pinnedThemes/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeLayout = function(id, theme) {
    const deferred = $q.defer();
    $http
      .get(api + `themeLayouts?layout_id=${id}&theme_id=${theme}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeLayoutByProduct = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themeLayouts?product_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getSelectedThemeLayoutByProduct = function(id, theme) {
    const deferred = $q.defer();
    $http
      .get(api + `themeLayouts?product_id=${id}&theme_id=${theme}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeLayoutByLayout = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themeLayouts?layout_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getThemeById = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `themes/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getWatermarks = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `watermarks/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };
});
