angular.module('app').service('funeralHomes', function($http, $q) {

	this.create = function(payload) {
		const deferred = $q.defer();
		$http.post(api + 'createFuneralHome', payload).then(response => {
			deferred.resolve(response)
		}).catch((error) => {
			deferred.resolve(error)
		})
    return deferred.promise
	}

	this.get = function() {
		const deferred = $q.defer();
		$http.get(api + `funeralHomes`).then(response => {
			this.freeTrial = response.data.freeTrial || null;
			this.home = response.data
			deferred.resolve(response)
		}).catch((error) => {
			deferred.resolve(error)
		})
    return deferred.promise
	}

	this.update = function(payload) {
		const deferred = $q.defer();
		$http.patch(api + 'funeralHomes', payload).then(response => {
			deferred.resolve(response)
		}).catch((error) => {
			deferred.resolve(error)
		})
    return deferred.promise
	}

	this.startFreeTrial = function() {
		const deferred = $q.defer();
		$http.patch(api + 'funeralHomes?startTrial=true').then(response => {
			deferred.resolve(response)
		}).catch((error) => {
			deferred.resolve(error)
		})
    return deferred.promise
	}

	this.uploadImage = function(file, signed_request) {
		const deferred = $q.defer()

		const xhr = new XMLHttpRequest();
		xhr.open("PUT", signed_request);
		xhr.setRequestHeader('x-amz-acl', 'public-read');
		xhr.onload = function() {
			if (xhr.status === 200) {
				deferred.resolve(true)
			}
		}
		xhr.onerror = function() {
			alert("Could not upload file.");
		};
		xhr.send(file);
		return deferred.promise
	}

	this.checkSlug = function(slug) {
		const deferred = $q.defer();
		$http.get(api + `checkURLAvailable?slug=${slug}`).then(response => {
			deferred.resolve(response)
		}).catch((error) => {
			deferred.resolve(error)
		})
    return deferred.promise
	}

})
