(function() {
	angular.module("app").directive("siteMaintenance", maintenanceController);

	function maintenanceController(user) {
		return {
			templateUrl: "pages/siteMaintenance/siteMaintenance.html",
			scope: true,
			link: function(scope) {
				var vm = scope

				angular.extend(scope, {
					returnDate: new Date("2019-02-22")
				})

				//******** end *********//
			}
		};
	}
})();
