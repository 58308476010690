angular.module('app').filter('replacespaces', function() {
    return function(input) {
        if (input) {
            return input.replace(/\s+/g, '%20');
        }
    }
});

angular.module('app').filter('isPreprintedTheme', function() {
    return function(input) {
      var isPreprinted = false
      angular.forEach(input, function(theme){
        angular.forEach(theme.collections, function(collection){
          if(collection.selected && collection.paperType == 'preprinted'){
            isPreprinted = true
          }
        })
      })
      if(isPreprinted){
        return input
      } else {
        return false
      }
    }
});

angular.module('app').filter('isPlainTheme', function() {
    return function(input) {
      var isPlain = false
      angular.forEach(input, function(theme){
        angular.forEach(theme.collections, function(collection){
          if(collection.selected && collection.paperType == 'plain'){
            isPlain = true
          }
        })
      })
      if(isPlain){
        return input
      } else {
        return false
      }
    }
});

angular.module('app').filter('filterByCollection', function() {
    return function(input, name) {
      var hasCollection = false
      angular.forEach(input, function(theme){

        angular.forEach(theme.collections, function(collection){
          if(collection.selected == true && collection.name == name){
            hasCollection = true
          }
        })
      })
      if(hasCollection){
        return input
      } else {
        return false
      }
    }
});

angular.module('app').filter("trust", ['$sce', function($sce) {
  return function(htmlCode){
    return $sce.trustAsHtml(htmlCode);
  }
}]);

angular.module('app').filter('reverse', function() {
  return function(items) {
    return items.slice().reverse();
  };
});
