angular.module('app').service('dom', function($compile, helpers) {

const dom = this;

///PAGE LOAD////
  this.multiSelectAlert = function() {
    $(window).on('load', function() {
      !localStorage.multiSelectAlert ? (
        localStorage.multiSelectAlert = true,
        $('#multi-select-alert').modal('show')
      ) : (
        false
      )
    })
  }

  this.singleTextAlert = function() {
    $(window).on('load', function() {
      !localStorage.singleTextAlert ? (
        localStorage.singleTextAlert = true,
        $('#single-text-editor').modal('show')
      ) : (
        false
      )
    })
  }

  this.adjustPageHeight = function() {
    let windowHeight = $(window).height()
    // $('.product .printedarea:last-child').css('margin-bottom', windowHeight)
  }

  this.setEditorHeight = function() {
    const height = $(window).height() - 60
    $('#editor').css('height', height)
  }

  this.setMargin = function() {
    $('.printedarea:last-child').css({
      // marginBottom: $(window).height() - 500
    })
  }

  this.getPageWidth = function() {
    const width = $(window).width()
    const margin = width/2.4
    $('.scroll-tooltip').css('margin-left', margin)
  }

////HELPER FNS////
  this.getAllStyles = function(elem) {
    if (!elem) return []; // Element does not exist, empty list.
    let win = document.defaultView || window,
      style, styleNode = [];
    if (win.getComputedStyle) { /* Modern browsers */
      style = win.getComputedStyle(elem, '');
      for (let i = 0; i < style.length; i++) {
        styleNode.push(style[i] + ':' + style.getPropertyValue(style[i]));
        //               ^name ^           ^ value ^
      }
    } else if (elem.currentStyle) { /* IE */
      style = elem.currentStyle;
      for (let name in style) {
        styleNode.push(name + ':' + style[name]);
      }
    } else { /* Ancient browser..*/
      style = elem.style;
      for (let i = 0; i < style.length; i++) {
        styleNode.push(style[i] + ':' + style[style[i]]);
      }
    }
    return styleNode;
  }

  this.insertAndHighlightNewText = function(element, selector) {
    var insert = function(element, selector){
      return new Promise(function(resolve, reject){
        $('#editor .product .focused .outlines').append(element)
        if($(selector).hasClass('oldQuill') || $(selector).hasClass('custom-verse-preview') || $(selector).hasClass('quill')) {
          $(selector).css({
            'cursor': 'pointer',
            'position': 'absolute',
            'top': '20px',
            'left': '20px',
            'width': '270px',
            'height': 'auto',
            'border': 'none',
            'z-index': '10',
          })
          $(selector).css('outline', '')
          $(selector).find('.ql-tooltip').css('visibility', 'hidden')

        }
        resolve()
      })
    }
    var click = function(selector){
      return new Promise(function(resolve, reject){
      $(selector).dblclick()
      resolve()
      })
    }

    var destroy = function(selector){
      return new Promise(function(resolve, reject){
      $(selector).removeAttr('id')
      resolve()
      })
    }
    insert(element, selector).then(function(){
      return click(selector)
    }).then(function(){
      destroy(selector)
    })
  }

  this.insertAndHighlightMemorialText = function(element, selector) {
    var insert = function(element, selector){
      return new Promise(function(resolve, reject){
        $('#editor .product .printedarea .outlines').last().append(element)
        if($(selector).hasClass('oldQuill') || $(selector).hasClass('custom-verse-preview') || $(selector).hasClass('quill')) {
          $(selector).css({
            'cursor': 'pointer',
            'position': 'absolute',
            'top': '20px',
            'left': '20px',
            'width': '270px',
            'height': 'auto',
            'border': 'none',
            'z-index': '10',
          })
          $(selector).css('outline', '')
          $(selector).find('.ql-tooltip').css('visibility', 'hidden')

        }
        resolve()
      })
    }
    var click = function(selector){
      return new Promise(function(resolve, reject){
      $(selector).dblclick()
      resolve()
      })
    }

    var destroy = function(selector){
      return new Promise(function(resolve, reject){
      $(selector).removeAttr('id')
      resolve()
      })
    }
    insert(element, selector).then(function(){
      return click(selector)
    }).then(function(){
      destroy(selector)
    })
  }

  this.isEven = function(n) {
    return n % 2 == 0;
  }

  this.setVerseInlineStyles = () => {
    $('.ql-editor').find('*').each(function(){
      let align = $(this).css('text-align')
      this.style.setProperty('text-align', align, 'important');
    })
    $('.ql-editor').find('*[class*=ql-color]').each(function() {
      let color = $(this).css('color')
      $(this).css('color', color + '!important')
      let colorClass = 'ql-color-' + color
      $(this).removeClass(colorClass)
    })
    $('.ql-editor').find('*[class*=ql-bg]').each(function() {
      let color = $(this).css('background-color')
      $(this).css('background-color', color)
    })
    $('.ql-editor').find('*[class*=ql-font]').each(function(){
      let font = $(this).css('font-family')
      if(font) {
        if(font == 'sans-serif' || font == 'Sans Serif') {
          font = 'Century Gothic'
        }
        $(this).css('font-family', font)
      } else {
        $(this).css('font-family', 'inherit')
      }
    })
    $('.ql-editor').css({'box-sizing': 'border-box', 'font-family': '', 'font-size': '', 'color': ''} )
    $('#productBuild').find('div.ql-container').each(function(){
      $(this).css('color', '')
      if($(this).css('font-size') != '1em') {
        $(this).css({'font-size': '1em'})
      }
      $(this).css('height', $(this)[0].scrollHeight + 'px')
    })

    $('.ql-editor').find('*[class*=ql-weight]').each(function(){
      let match = false
      let weight = $(this).css('font-weight')
      let weightName
      for (let font of helpers.fonts) {
        if (font.name == $(this).css('font-family').replace(/"/g, '')) {
          if(font.weights){
            for (let weights of font.weights) {
              if(weights.weight == $(this).css('font-weight')) {
                match = true
                $(this).css('font-weight', weights.weight)
                break;
              }
            }
          }
        }
      }
      if (!match) {
        if(weight == 800) {
          weightName = 'ql-weight-Bold'
        } else if( weight == 700) {
          weightName = 'ql-weight-Bold-Italic'
        } else if( weight == 600) {
          weightName = 'ql-weight-Italic'
        } else {
          weightName = ''
        }
        $(this).removeClass(weightName)
      }
    })
  }

  //// CREATE HTML ELEMENTS ////

  this.HtmlElement = function(elem) {
    this.element = (elem instanceof HTMLElement) ? elem : document.createElement(elem)
  }

  this.HtmlElement.create = function(elem) {
    return new dom.HtmlElement(elem)
  }

  // Add id
  this.HtmlElement.prototype.addId = function(id) {    
    this.element.id = id || '';
    return this;
  };

  // Add a single class
  this.HtmlElement.prototype.addClass = function(className) {
    className && this.element.classList.add(className);
    return this;
  };

  // Add multiple classes. Use of rest parameter
  // allows classNames to be either a comma-
  // separated list or an array of args.
  this.HtmlElement.prototype.addClasses = function(...classNames) {
    for (let className of classNames) {
        this.addClass(className);
    }

    return this;
  };

  // Add text content to element
  this.HtmlElement.prototype.addTextContent = function(text = '') {
    this.element.textContent = text;
    return this;
  };

  this.HtmlElement.prototype.addAttr = function(data, value) {
    data && this.element.setAttribute(data, value);
    return this;
  };

  this.HtmlElement.prototype.addAttrs = function(attrs) {
    for (let data of attrs) {      
      this.addAttr(data.data, data.value);
  }

  return this;
  };

  // Add single child element and append to parent
  this.HtmlElement.prototype.addChild = function(args = {}) {
    const child = HtmlElement.create(args.elem)
      .addId(args.id)
      .addClasses(args.classes) // addClasses can take an array or a comma-separated list
      .addTextContent(args.textContent)
      .addDataAttrs(args.dataAttrs);
    this.append(child);
    return this;
  };

  // Add multiple child elements
  // Takes array of objects
  this.HtmlElement.prototype.addChildren = function(children = []) {
      for (let child of children) {
          this.addChild(child);
      }
      
      return this;
  };

  // Gets child of the current HtmlElement and
  // wraps it in an instance of HtmlElement
  this.HtmlElement.prototype.getChild = function(selector) {
    return new HtmlElement(this.element.querySelector(selector));
  };

  // Appends an HtmlElement's inner element property
  // to the current HtmlElement. Not chainable,
  // but would be if you add return this.
  this.HtmlElement.prototype.append = function(htmlElement) {
    this.element.appendChild(htmlElement.element);
  };

  // Appends the current HtmlElement's inner
  // element property to a DOM element.
  // Return this to make chainable.
  this.HtmlElement.prototype.appendTo = function(domElement) {
    domElement.appendChild(this.element);
  };

////INTERACT WITH ELEMENTS////
  this.deselectElements = function() {
    $('*').removeClass('selected')
    $('.resizer, .rotator, .deletor').css({
      'display': 'none'
    })
  }

  this.deselectMultiselect = function() {
    $('*').removeClass('multi-select')
    $('.resizer, .rotator, .deletor').css({
      'display': 'none'
    })
  }

////IMAGES////
  this.sizePlaceholderImage = function(img) {
    let image = $(img[0])
    let radius = null
    let border = null
    //get border radius
    if(image.css('border-top-left-radius') !== '0px') {
      if(image.css('border-top-left-radius') === '50%') {
        radius = '50%'
      } else {
        radius = parseInt(image.css('border-top-left-radius')) + 'px'
      }
    }
    //If a border is applied get border
    if(image.css('border-left-width') !== '0px'){
      let color = image.css('border-left-color')
      let newHex = dom.rgb2hex(color)

      let border = {
        width: parseInt(image.css('border-left-width')),
        color: newHex.substring(1)
      }
    }
    let src = image.css('background-image').replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
    const newImage = new Image();
    newImage.src = src;
    $(newImage).load(function() {
      let width = newImage.width,
        height = newImage.height
      if( width >= 2800 || height >= 2800) {
        image.css('background-image', `url(${src})`)
      }
      let ratio = (height / width)
      width = image.width()
      height = image.width() * ratio
      if(!radius) {
        radius = 'none'
      }
      if(!border) {
        border = {}
        border.width = '0px'
      }
      if(!border) {
        border.color = 'none'
      }
      image.css({
        width: (width) + 'px',
        height: (height) + 'px',
        'border-radius': radius || 'none',
        'border': border.width + 'px solid ' + border.color,
      })
      dom.positionResizer()
      dom.positionRotator()
      dom.positionDelete()
    })
  }

  this.sizeImage = function(img, loading, upload) {
    let image = $(img[0])
    let src = image.css('background-image').replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
    const newImage = new Image();
    newImage.src = src;

    if (loading) {
      $timeout(function() {
        add(upload, newImage, image, src)
      }, 5000)
    } else {
      $(newImage).load(function() {
        add(upload, newImage, image, src)
      })
    }

    function add(upload, newImage, image, src,) {
      let width = newImage.width,
        height = newImage.height,
        pageWidth = parseInt($('#editor .printedarea').css('width')),
        large,
        small,
        ratio
        if( width >= 2800 || height >= 2800) {
          large = true
          image.css('background-image', 'url(' + src + ")")
        }
        if( width <= 280 || height <= 280) {
          small = true
        }
      let containerWidth = parseInt(image.css('width'))
      if (width > pageWidth) {
        ratio = pageWidth / width
        width = pageWidth
        height = height * ratio
      }
      image.css({
        width: (width / 4) + 'px',
        height: (height / 4) + 'px',
        'border-radius': '0px',
        'border': 'none'
      })

      if(large && !localStorage.largeImageAlert && upload === true) {
        $('#largeImage-modal').modal('show')
      }
      // else if(small && !localStorage.smallImageAlert) {
        // $('#smallImage-modal').modal('show')
      // }
    }
  }

////POSITION ICONS////
  this.positionResizer = function() {
    const target = $('.resizer-target')
    const top = parseInt(target.css('height')) + parseInt(target.css('top'))
    const left = parseInt(target.css('width')) + parseInt(target.css('left'))
    $('.focused .resizer').css({
      top: top,
      left: left
    })
  }

  this.positionRotator = function() {
    const target = $('.rotator-target')
    const top = parseInt(target.css('top')) - 20
    const left = parseInt(target.css('width')) + parseInt(target.css('left'))
    $('.focused .rotator').css({
      top: top,
      left: left
    })
  }

  this.positionDelete = function(){
    const target = $('.deletor-target')
    const top = parseInt(target.css('top')) - 20
    const right = parseInt(target.css('width')) + parseInt(target.css('right'))
    const left = parseInt(target.css('left')) - 20
    $('.focused .deletor').css({
      top: top,
      left: left
    })
  }

  this.positionCroptool = function(target) {
    let top = parseInt(target.css('height')) + parseInt(target.css('top'))
    let left = parseInt(target.css('width')) + parseInt(target.css('left'))

    $('.crop-tool').css({
      top: top,
      left: left
    })
  }

  this.positionWatermarkLayerResizer = function() {
    //if there is a selectable watermark make the icon visible
    if($('.theme .resizer-target').length) {
      $('.watermark-resizer').css('visibility', 'visible')
    } else {
      $('.watermark-resizer').css('visibility', 'hidden')
    }
    const target = $('.resizer-target')
    //find the top/left add the width/height to get bottom/right corner of the currently selected watermark
    let top = parseInt(target.css('height')) + parseInt(target.css('top'))
    let left = parseInt(target.css('width')) + parseInt(target.css('left'))
    //move the watermark resizer icon to the bottom right corner
    $('.theme .watermark-resizer').css({
      top: top,
      left: left,
      cursor: 'pointer',
      'font-size': '20px',
      'outline': 'none !important',
      'color': 'white',
      'text-shadow': '0px 0px 1px black'
    })
  }

  this.positionWatermarkLayerDelete = function() {
    //if there is a selectable watermark make the icon visible
    if($('.theme .deletor-target').length) {
      $('.watermark-deletor').css('visibility', 'visible')
    } else {
      $('.watermark-deletor').css('visibility', 'hidden')
    }
    const target = $('.deletor-target')
    //find the top/left add the width/height to get bottom/right corner of the currently selected watermark
    let top = parseInt(target.css('top')) - 20
    let right = parseInt(target.css('width')) + parseInt(target.css('right'))
    //move the watermark resizer icon to the bottom right corner
    $('.theme .watermark-deletor').css({
      top: top,
      right: right
    })
  }

////LAYERS////
  this.changeOutlines = function(color) {
    $('.outlines').css({
      'outline-color': color
    })
    $('.third, .two-third, .middle').css({
      'border-right-color': color
    })
  }

  this.disableBackground = function() {
    //remove the class the allow for draggable background
    $('.bgdraggable').unbind('mousedown')
    $('.bgdraggable').css('cursor', '')
    $('.focused').removeClass('bgdraggable')
    //background is set to repeat
    $('.theme').css({
      'background-repeat': 'no-repeat'
    })
    // $('.bg-size').remove()
    $('#editor .product .bg-size').remove()
    // vm.$apply()
  }

  this.selectWatermark = function(element) {
    const target = element.target
    //only run select if not already selected
    if(!$(target).hasClass('resizer-target')){
      //remove targeting classes from elements and outline from current target
      $('.resizer-target').css({
        'outline': 'none'
      })
      $('.watermark').removeClass('resizer-target deletor-target watermarkDraggable')
      //add targeting class to new target
      $(target).addClass('resizer-target deletor-target watermarkDraggable')
      //add outline to new target
      $('.resizer-target').css({
        outline: '1px dashed limegreen'
      })
      //run positioning functions for resizer and deltor icons
      dom.positionWatermarkLayerResizer()
      dom.positionWatermarkLayerDelete()
    }
  }

////ALIGNMENT////
  this.alignText = function(selectedElement, align) {
    let selected = $('.multi-select')
    if (!selected.length) {
      selected = $(selectedElement);
    }
    selected.css('text-align', align)
  }

  this.removeAlightmentOutline = function() {
    $('.alignCenter').remove();
    $('.alignMiddle').remove();
    $('.alignMiddleTop').remove();
    $('.alignMiddleBottom').remove();
  }

////COLOR CONVERSION///
  this.componentToHex = function(c) {
    let hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  this.rgbToHex = function(r, g, b) {
    return "#" + dom.componentToHex(r) + dom.componentToHex(g) + dom.componentToHex(b);
  }

  this.rgb2hex = function(rgb) {
    rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
    return (rgb && rgb.length === 4) ? "#" +
      ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
  }

  this.hex2rgb = function(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  this.convertHex = function(hex, opacity) {
    if (hex && opacity) {
      hex = hex.replace('#', '');
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      let result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
      if (result) {
        return result;
      } else {
        return rgba('0,0,0,0.1')
      }
    }
  }

});
