angular.module('app').service('funeralHomeSettings', function($http, $q) {

  this.get = function() {
    const deferred = $q.defer();
    $http.get(api + `funeralHomeSettings`)
      .then(response => {
        this.settings = response.data
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'createFuneralHomeSettings', payload)
      .then(response => {
        this.settings = response.data
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `funeralHomeSettings/${id}`, payload)
      .then(response => {
        this.settings = response.data
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

})
