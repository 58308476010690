(function() {
	angular.module("app").directive("alertbanner", alertBannerController);
	function alertBannerController($compile, messages) {
		return {
			templateUrl: "pages/alert-banner/alertBanner.html",
			controllerAs: "abm",
			controller: function() {
        var vm = this;

        angular.extend(vm, {
          alert: null
        })

        init()

        function init() {
          vm.alert = {}
          messages.get().then(response => {
            vm.alert = response.data.length > 0 ? response.data[0] : null 
            if(vm.alert) $('#alertBanner').css('display', 'block')
          })
         
        }
      }
    };
  };
})();
