(function() {

  angular.module('app').directive('case', caseController)

  function caseController(cases, $routeParams, $timeout, $rootScope, socket, user) {
    return {

      templateUrl: 'pages/order/cases/case.html',
      controllerAs: 'cvm',
      controller: function($scope) {
        var vm = this
        var ta = document.getElementById('obituary-textarea');
        autosize($(ta));

        angular.extend(vm, {
          //Variables and Functions
          accordionOpen: accordionOpen,
          tabOpen: tabOpen,
          tab: 'deceased',
          saving: false,
          loading: false,
          case: {},
          caseId: null,
          updateCase: updateCase,

          //tab manipulation
          next: next,
          back: back,
          step: 1,

          //Case field manipulation
          loadingimages: false,
          dateAlert: dateAlert,
          calculateAge: calculateAge,
          populatePallBearers: populatePallBearers,
          populateHonoraryPallBearers: populateHonoraryPallBearers,
          addChild: addChild,
          addSibling: addSibling,
          addOrganization: addOrganization,
          addClergy: addClergy,
          addService: addService,
          addVisitation: addVisitation,
          addMusic: addMusic,
          addSongs: addSongs,
          addPallBearers: addPallBearers,
          addHonoraryPallBearers: addHonoraryPallBearers,
          addSocieties: addSocieties,
          addOtherMedal: addOtherMedal,
          addUSStation: addUSStation,
          addFiringParty: addFiringParty,
          removeChild: removeChild,
          removeSibling: removeSibling,
          removeOrganization: removeOrganization,
          removeClergy: removeClergy,
          removeVisitation: removeVisitation,
          removeService: removeService,
          removeMusic: removeMusic,
          removeSong: removeSong,
          removePallBearer: removePallBearer,
          removeHonoraryPallBearer: removeHonoraryPallBearer,
          removeSociety: removeSociety,
          removeOtherMedal: removeOtherMedal,
          removeUSStation: removeUSStation,
          removeFiringParty: removeFiringParty,
          removeImage: removeImage,
          removeCustomField: removeCustomField,
          removeOtherFamily: removeOtherFamily,
          removePrecededBy: removePrecededBy,
          removeFamilyGroupItem: removeFamilyGroupItem,
          setServicesDefault: setServicesDefault,
          uploadImages: uploadImages,
          dateOptions: {
            changeYear: true,
            changeMonth: true,
            yearRange: "",
          },
          dob: null,
          dod: null,
          age: null,
          ageYears: null,
          ageMonths: null,
          ageDays: null,
          alert: alert,
          makeImgDefault: makeImgDefault,

          //additional variables and functions
          alertText: '',
          loadingimages: false,

          //Accordians
          deceasedStatus: {
            isFirstOpen: false,
            isSecondOpen: false,
            isThirdOpen: false,
            isFourthOpen: false,
            isFifthOpen: false
          },
          serviceStatus: {
            isFirstOpen: false,
            isSecondOpen: false,
            isThirdOpen: false,
            isFourthOpen: false
          },
          militaryStatus: {
            isFirstOpen: false,
            isSecondOpen: false,
            isThirdOpen: false,
            isFourthOpen: false
          },
          addCustomField: addCustomField,
          addOtherFamily: addOtherFamily,
          addPrecededBy: addPrecededBy,
          addItemToFamilyGroup: addItemToFamilyGroup,
          addFamilyGroup: addFamilyGroup,
          family_groups: [],
          sendInvite: sendInvite,
          invite: {
            number_allowed: 20
          },
          showCaseEditWarning: false,
          inviteOptions: {
            changeYear: true,
            changeMonth: true,
            yearRange: "",
            maxDate: "+ 14d",
            minDate: 1,
          },
          memorials: [],
          archiveMemorial: archiveMemorial,
          newMemorial: {},
          createMemorial: createMemorial,
          seeinvite: false,
          chars: 0,
          relation_group: null,
        })

        init()

        function init() {
          getYear()
        }

        $scope.$watch("orderProducts", (newValue, oldValue) => {
          if(!$rootScope.duplicateOrder) {
            vm.showCaseEditWarning = newValue.length || oldValue.length ? true : false
          }
        })

        //this function updates the year range to include the current calendar year instead of using a preset range.
        function getYear() {
          var year = new Date().getFullYear()
          vm.dateOptions.yearRange = '1900:' + (year + 1)
          vm.inviteOptions.yearRange = `2019:${year + 1}`
        }

        function calculateAge(){
          if(!vm.case.date_of_birth){
            vm.case.date_of_birth = null
          }
          if(!vm.case.date_of_death){
            vm.case.date_of_death = null
          }
          if(vm.case.date_of_birth && vm.case.date_of_death){
            var birth = moment.utc(vm.case.date_of_birth.toString())
            var death = moment.utc(vm.case.date_of_death.toString())

            var years = death.diff(birth, 'years')
            vm.case.age = death.diff(birth, 'years')
            vm.case.age_years = death.diff(birth, 'years')
            death = death.subtract(vm.case.age_years, 'years')
            vm.case.age_months = death.diff(birth, 'months')
            death = death.subtract(vm.case.age_months, 'months')
            vm.case.age_days = death.diff(birth, 'days')
          }
        }

        function alert(msg) {
          alert(msg)
        }

        function dateAlert() {
          $timeout(function(){
            var birth = vm.case.date_of_birth ? moment.utc(vm.case.date_of_birth).format("YYYY-MM-DDTHH:mm:ss:SSSZ") : 0
            var death = vm.case.date_of_death ? moment.utc(vm.case.date_of_death).format("YYYY-MM-DDTHH:mm:ss:SSSZ") : 0
            var interment = vm.case.interment_date ? moment.utc(vm.case.interment_date).format("YYYY-MM-DDTHH:mm:ss:SSSZ") : 0
            var present = moment().format()
            if(vm.services) {
              var minService = vm.services.length > 0 ? vm.services.reduce((prev, curr) => {
                return prev.date < curr.date ? moment.utc(prev.date).format("YYYY-MM-DDTHH:mm:ss:SSSZ") : moment.utc(curr.date).format("YYYY-MM-DDTHH:mm:ss:SSSZ");
              }) : 0
            }
           
            if(vm.visitations) {
              var minVisitation = vm.visitations.length > 0 ? vm.visitations.reduce((prev, curr) => {
                return prev.date < curr.date ? moment.utc(prev.date).format("YYYY-MM-DDTHH:mm:ss:SSSZ") : moment.utc(curr.date).format("YYYY-MM-DDTHH:mm:ss:SSSZ");
              }) : 0
  
            }

            if(birth > death || birth > interment || birth > minService|| birth > minVisitation || birth > present){
              $('.birth-alert').css('border', '4px solid red')
            } else {
              $('.birth-alert').css('border', '1px solid #758096')
            }
            if(birth > death){
              $('.birth-death-error').show()
            } else {
              $('.birth-death-error').hide()
            }
            if(birth > interment){
              $('.birth-interment-error').show()
            } else {
              $('.birth-interment-error').hide()
            }
            if(birth > minService ){
              $('.birth-service-error').show()
            } else {
              $('.birth-service-error').hide()
            }
            if(birth > present){
              $('.birth-present-error').show()
            } else {
              $('.birth-present-error').hide()
            }
            if(death > present || death > interment || death > minService) {
              $('.death-alert').css('border', '4px solid red')
            } else {
              $('.death-alert').css('border', '1px solid #758096')
            }
            if(death > present){
              $('.death-present-error').show()
            } else {
              $('.death-present-error').hide()
            }
            if(death > interment){
              $('.death-interment-error').show()
            } else {
              $('.death-interment-error').hide()
            }
            if(death > minService){
              $('.death-service-error').show()
            } else {
              $('.death-service-error').hide()
            }

            if(moment.utc(vm.case.interment_date).isBefore(present, 'day') ){
              $('.interment-alert').css('border', '4px solid red')
             $('.interment-present-error').show()
            } else {
              $('.interment-alert').css('border', '1px solid #758096')
             $('.interment-present-error').hide()
            }


            if(vm.services){
              vm.services.map(service => {
                if(!service.dateError) service.dateError = {present: null, death: null, interment: null }
                service.dateError.present = moment.utc(service.date).isBefore(present, 'day') ? true : false ;
                service.dateError.death = moment.utc(service.date).isBefore(vm.case.date_of_death, 'day') ? true : false ;
                service.dateError.interment = moment.utc(service.date).isAfter(vm.case.interment_date, 'day') ? true : false ;
              })
            }
            
            if(vm.visitations){
              vm.visitations.map(visitation => {
                if(!visitation.dateError) visitation.dateError = {present: null, death: null, interment: null }
                visitation.dateError.present = moment.utc(visitation.date).isBefore(present, 'day') ? true : false ;
                visitation.dateError.death = moment.utc(visitation.date).isBefore(vm.case.date_of_death, 'day') ? true : false ;
                visitation.dateError.interment = moment.utc(visitation.date).isAfter(vm.case.interment_date, 'day') ? true : false ;        
              })
            }
          }, 500)
        }

        function getCase(id){
          vm.case = {}
          cases.get(id).then(response => {
            vm.case = response.data
            vm.other_family = vm.case.additional_family.filter(item => item.type === 'other_family')
          })
        }

        const openCaseListener = $rootScope.$on('opencase', function(event, payload){
          vm.case = payload.case_details
          if(vm.case.additional_family) {
            vm.family_groups = _.uniqBy(vm.case.additional_family, 'type').map(group => {
              if(group.type === 'other_family' || group.type === 'preceded_by') return;
              return {
                type: group.type,
                items: vm.case.additional_family.filter(item => item.type === group.type)
              }
            }).filter(group => group)
          }
          vm.other_family = vm.case.additional_family ?  vm.case.additional_family.filter(item => item.type === 'other_family') : [];
          vm.preceded_by = vm.case.additional_family ? vm.case.additional_family.filter(item => item.type === 'preceded_by') : [];
          vm.services = vm.case.services ? vm.case.services.filter(item => item.type.toLowerCase() === 'service') : [];
          vm.visitations = vm.case.services ? vm.case.services.filter(item => item.type.toLowerCase() === 'visitation') : [];
          vm.custom_fields = payload.custom_fields
          if(vm.case.us_stations_where_served && vm.case.us_stations_where_served.length > 0) {
            vm.case.us_stations_where_served = vm.case.us_stations_where_served.map(station => {
              return JSON.parse(station)
            })
          }

          if(vm.case.date_of_death || vm.case.service_date){
            calculateAge()
            dateAlert()
            getMemorials()
          }
          $("#case-modal").modal("show");
        })
        
        function makeImgDefault(filename) {
					vm.case.personal_images_to_remove = vm.case.personal_images.slice()
					vm.case.personal_images = _.remove(vm.case.personal_images, function(interval) {
						return interval !== filename
					})
					vm.case.personal_images_to_add = vm.case.personal_images
					vm.case.personal_images_to_add.unshift(filename)
          localStorage.setItem('defaultImgUpdated', 'true')
          updateCase()
				}

        $scope.$on('$destroy', () => openCaseListener())

        function updateCase() {
          const id = vm.case.id
          vm.saving = true

          let combined_fam = vm.preceded_by ? vm.other_family.concat(vm.preceded_by) : vm.other_family
          vm.family_groups.map(group => {
            if(group.items.length > 0) {
              combined_fam = combined_fam.concat(group.items)
            }
          })
          
          const additional_family = combined_fam.map(item => ((item.id && delete item.id), item))

          let combined_services = vm.visitations ? vm.services.concat(vm.visitations) : vm.services;
          const services = combined_services.map(item => {
            const obj = {};
            item.location && (obj.location = item.location);
            item.time && (obj.time = item.time);
            item.date && (obj.date = item.date);
            item.city && (obj.city = item.city);
            item.state && (obj.state = item.state);
            item.type && (obj.type = item.type.toLowerCase());
            item.default ? (obj.default = item.default) : obj.default = false;
            return obj;
          })      
        
          vm.case.additional_family = additional_family
          vm.case.services = services

          //check case object for empty strings and set them to null for the database
          for (let value of Object.keys(vm.case)) {
            if(vm.case[value] && (typeof vm.case[value] == 'string' || vm.case instanceof Date)) {
              const isDate = vm.case[value]
              isDate != 'Invalid date' && (vm.case[value] = isDate)
            }

            if(value === 'services') {
              vm.case.services.map(item => {
                if(item.date) {
                  item.date = item.date != 'Invalid date' ? moment.utc(item.date.toString()).format('M/D/YY') : null;
                  return item
                }
              })
            }
          }
          
          //occassionally id returns undefined, trying to ensure consistency.
          const casePayload = Object.assign({}, vm.case)
          delete casePayload.id
          delete casePayload.funeral_home_id
          delete casePayload.created_at
          delete casePayload.updated_at
          delete casePayload.created_by
          delete casePayload.updated_by
          delete casePayload.archived
          delete casePayload.case_id
          delete casePayload.age
          delete casePayload.age_years
          delete casePayload.age_months
          delete casePayload.age_days
          delete casePayload.personal_images
          delete casePayload.justServices
          delete casePayload.visitations
          delete casePayload.other_family
          delete casePayload.other_family_combined
          delete casePayload.preceded_by
          delete casePayload.preceded_by_combined
          delete casePayload.service_date

          for(var i in casePayload){
            if(casePayload[i] == null && !i.includes('date')){
              delete casePayload[i]
            } else if (casePayload[i] === "") {
              casePayload[i] = null
            }
          }

          cases.update(id, casePayload).then(async response => {
              vm.case = response.data

              vm.family_groups = _.uniqBy(vm.case.additional_family, 'type').map(group => {
                if(group.type === 'other_family' || group.type === 'preceded_by') return;
                return {
                  type: group.type,
                  items: vm.case.additional_family.filter(item => item.type === group.type)
                }
              }).filter(group => group)

              vm.other_family = vm.case.additional_family ?  vm.case.additional_family.filter(item => item.type === 'other_family') : [];
              vm.preceded_by = vm.case.additional_family ? vm.case.additional_family.filter(item => item.type === 'preceded_by') : [];
              vm.services = vm.case.services ? vm.case.services.filter(item => item.type.toLowerCase() === 'service') : [];
              vm.visitations = vm.case.services ? vm.case.services.filter(item => item.type.toLowerCase() === 'visitation') : [];
             
              vm.case.us_stations_where_served = vm.case.us_stations_where_served.map(station => {
                return JSON.parse(station)
              })

              $rootScope.$emit("caseUpdated", response.data);
              calculateAge()
              dateAlert()
              vm.saving = false
          })

          if(vm.custom_fields){
            const formFields = vm.custom_fields
            vm.custom_fields = []
            for(var i in formFields){
              let obj = {}
              obj.field_title = formFields[i].field_title
              obj.field_value = formFields[i].field_value

              if(formFields[i].id){
                const caseId = formFields[i].id
                cases.updateCaseCustomField(caseId, obj).then(response => {
                  vm.custom_fields.push(response.data)
                })
              } else {
                obj.case_id = id
                cases.createCaseCustomField(obj).then(response => {
                  vm.custom_fields.push(response.data)
                })
              }
            }
          }
        }

        function next() {
          if(vm.tab == 'deceased') {
            vm.tab = 'obituary'
            vm.step = 2
          }
          else if(vm.tab == 'obituary') {
            vm.tab = 'service'
            vm.step = 3
          }
          else {
            vm.tab = 'military'
            vm.step = 4
          }
        }

        function back() {
          if(vm.tab == 'obituary') {
            vm.tab = 'deceased'
            vm.step = 1
          }
          else if(vm.tab == 'service') {
            vm.tab = 'obituary'
            vm.step = 2
          }
          else {
            vm.tab = 'service'
            vm.step = 3
          }
        }

        function addChild() {
          if(!vm.case.children){
            vm.case.children = []
          }
          vm.case.children.push('')
        }

        function addSibling() {
          if(!vm.case.siblings){
            vm.case.siblings = []
          }
          vm.case.siblings.push('')
        }

        function addOrganization() {
          if(!vm.case.organizations){
            vm.case.organizations = []
          }
          vm.case.organizations.push('')
        }
        
        function addClergy() {
          if(!vm.case.clergy){
            vm.case.clergy = []
          }
          vm.case.clergy.push('')
        }

        function addMusic() {
          if(!vm.case.songs){
            vm.case.songs = []
          }
          vm.case.songs.push('')
        }

        function addSongs() {
          if(!vm.case.special_songs){
            vm.case.special_songs = []
          }
          vm.case.special_songs.push('')
        }

        function populatePallBearers() {
          var bearerLength
          if(vm.case.details.pallbearers && vm.case.details.pallbearers.length) {
            bearerLength = vm.case.details.pallbearers.length + 1
          }
          else {
            vm.case.details.pallbearers = []
            bearerLength = 1
          }
          for(var i = 0; vm.case.details.pallbearers.length < 8; i++){
              var object = {
                title: 'PallBearer ',
                value: ''
              }
              vm.case.details.pallbearers.push(object)
          }
        }

        function addPallBearers() {
          if(!vm.case.pallbearers){
            vm.case.pallbearers = []
          }
          vm.case.pallbearers.push('')
        }

        function populateHonoraryPallBearers() {
          var bearerLength
          if(vm.case.details.honorary_pallbearers && vm.case.details.honorary_pallbearers.length) {
            bearerLength = vm.case.details.honorary_pallbearers.length + 1
          }
          else {
            vm.case.details.honorary_pallbearers = []
            bearerLength = 1
          }
          for(var i = 0; vm.case.details.honorary_pallbearers.length < 8; i++){
              var object = {
                title: 'PallBearer ',
                value: ''
              }
              vm.case.details.honorary_pallbearers.push(object)
          }
        }

        function addHonoraryPallBearers() {
          if(!vm.case.honorary_pallbearers){
            vm.case.honorary_pallbearers = []
          }
          vm.case.honorary_pallbearers.push('')
        }

        function addSocieties() {
          if(!vm.case.societies){
            vm.case.societies = []
          }
          vm.case.societies.push('')
        }

        function addOtherMedal() {
          if(!vm.case.other_medals){
            vm.case.other_medals = []
          }
          vm.case.other_medals.push('')
        }

        function addUSStation() {
          if(!vm.case.us_stations_where_served){
            vm.case.us_stations_where_served = []
          }

          var obj = {
            station_name: '',
            departure_date: '',
            departure_place: '',
            return_date: '',
            return_place: ''
          }
          vm.case.us_stations_where_served.push(obj)
        }

        function addFiringParty() {
          if(!vm.case.firing_parties_for_funeral_service){
            vm.case.firing_parties_for_funeral_service = []
          }
          vm.case.firing_parties_for_funeral_service.push('')
        }

        function addCustomField() {
          if(!vm.custom_fields){
            vm.custom_fields = []
          }

          let obj = {
            field_title: '',
            field_value: ''
          }

          vm.custom_fields.push(obj)
        }

        function addOtherFamily() {
          if(!vm.other_family){
            vm.other_family = []
          }

          const obj = {
            name: '',
            relation: '',
            type: 'other_family'
          }

          vm.other_family.push(obj)          
        }

        function addPrecededBy() {
          if(!vm.preceded_by){
            vm.preceded_by = []
          }

          const obj = {
            name: '',
            relation: '',
            type: 'preceded_by'
          }

          vm.preceded_by.push(obj)
        }

        function addFamilyGroup() {
          if(vm.relation_group) {
            const obj = {
              type: vm.relation_group,
              items: [{
                name: '',
              relation: '',
              type: vm.relation_group
              }]
            }
            vm.family_groups.push(obj)
            vm.relation_group = null
          }
        }

        function addItemToFamilyGroup(selection) {
          vm.family_groups.map(group => {
            if(group.type === selection.type) {
              const obj = {
                name: '',
                relation: '',
                type: group.type
              }
              group.items.push(obj)
            }
          })
        }

        function addService() {
          if(!vm.services){
            vm.services = []
          }

          const obj = {
            date: '',
            time: '',
            location: '',
            city: '',
            state: '',
            type: 'service',
            default: vm.services.length > 0 ? false : true
          }

          vm.services.push(obj)
        }

        function addVisitation() {
          if(!vm.visitations){
            vm.visitations = []
          }

          const obj = {
            date: '',
            time: '',
            location: '',
            type: 'visitation',
            default: false
          }

          vm.visitations.push(obj)
        }

        //Removal Functions

        function removeChild(object, array) {
          vm.case.children = _.remove(vm.case.children, function(interval) {
            return interval !== object
          })
        }

        function removeSibling(object, array) {
          vm.case.siblings = _.remove(vm.case.siblings, function(interval) {
            return interval !== object
          })
        }

        function removeOrganization(object, array) {
          vm.case.organizations = _.remove(vm.case.organizations, function(interval) {
            return interval !== object
          })
        }

        function removeClergy(object, array) {
          vm.case.clergy = _.remove(vm.case.clergy, function(interval) {
            return interval !== object
          })
        }

        function removeService(object, array) {
          vm.services = _.remove(vm.services, function(interval) {
            return interval !== object
          })
        }
        
        function removeVisitation(object, array) {
          vm.visitations = _.remove(vm.visitations, function(interval) {
            return interval !== object
          })
        }

        function removeMusic(object, array) {
          vm.case.songs = _.remove(vm.case.songs, function(interval) {
            return interval !== object
          })
        }

        function removeSong(object, array) {
          vm.case.special_songs = _.remove(vm.case.special_songs, function(interval) {
            return interval !== object
          })
        }

        function removePallBearer(object, array) {
          vm.case.pallbearers = _.remove(vm.case.pallbearers, function(interval) {
            return interval !== object
          })
        }
        
        function removeHonoraryPallBearer(object, array) {
          vm.case.honorary_pallbearers = _.remove(vm.case.honorary_pallbearers, function(interval) {
            return interval !== object
          })
        }

        function removeSociety(object, array) {
          vm.case.societies = _.remove(vm.case.societies, function(interval) {
            return interval !== object
          })
        }

        function removeOtherMedal(object, array) {
          vm.case.othermedals = _.remove(vm.case.other_medals, function(interval) {
            return interval !== object
          })
        }

        function removeUSStation(object, array) {
          vm.case.us_stations_where_served = _.remove(vm.case.us_stations_where_served, function(interval) {
            return interval !== object
          })
        }

        function removeFiringParty(object, array) {
          vm.case.firing_parties_for_funeral_service = _.remove(vm.case.firing_parties_for_funeral_service, function(interval) {
            return interval !== object
          })
        }

        function removeImage(filename){
          cases.deleteCaseImage(vm.case.id, filename).then(response => {
            vm.case.personal_images = response.data
            vm.case.personal_images_to_remove ? vm.case.personal_images_to_remove.push(filename) : vm.case.personal_images_to_remove = [filename]
          })
        }

        function removeCustomField(id, object){
          vm.custom_fields = _.remove(vm.custom_fields, function(interval) {
            return interval !== object
          })

          if(object.field_title == ''){
            // DO NOTHING
          } else {
            cases.deleteCaseCustomField(id).then(response => {})
          }
        }

        function removeOtherFamily(id, object){
          vm.other_family = _.remove(vm.other_family, function(interval) {
            return interval !== object
          })
        }

        function removePrecededBy(id, object){
          vm.preceded_by = _.remove(vm.preceded_by, function(interval) {
            return interval !== object
          })
        }

        function removeFamilyGroupItem(selection, object) {
          vm.family_groups.map(group => {
            if(group.type === selection.type) {
              const new_group = _.remove(group.items, function(interval) {
                return interval === object
              })
              return new_group
            }
          })
        }

        function setServicesDefault(object) {
          let combinedarray = vm.services.concat(vm.visitations)
          let services = combinedarray.map(item => {item === object ? item.default = true : item.default = false; return item})

          vm.services = services.filter(item => item.type.toLowerCase() === 'service')
          vm.visitations = services.filter(item => item.type.toLowerCase() === 'visitation')
        }

        function uploadImages(){
          $timeout(function(){
            $('#personal_images')[0].click()
          })
        }

        const caseModalUploadListener = $rootScope.$on('caseModalUpload', (event, payload) => {
          vm.case.personal_images = payload
        })

        $scope.$on('$destroy', () => caseModalUploadListener())

        $(document).on("keydown", function($event) {
          socket.socketTimer = socket.time
          socket.timeOutConnection()
          if($($event.target).hasClass('end-tab') && $event.keyCode == 9){
            $event.preventDefault()
            $timeout(function(){
              $('.start-tab').focus()
            }, 500)
          }
        })

        $(document).on("keydown", function($event) {
          if($($event.target).hasClass('end-modal-tabs') && $event.keyCode == 9){
            $event.preventDefault()
            $timeout(function(){
              $('.save-tab').focus()
            }, 200)
          }
        })
        
        function accordionOpen(){
          vm.deceasedStatus.isFirstOpen == true ? vm.deceasedStatus.isSecondOpen = true : null
          vm.deceasedStatus.isSecondOpen == true ? vm.deceasedStatus.isThirdOpen = true : null
          vm.deceasedStatus.isThirdOpen == true ? vm.deceasedStatus.isFourthOpen = true : null
          vm.deceasedStatus.isFourthOpen == true ? vm.deceasedStatus.isFifthOpen = true : null

          vm.serviceStatus.isFirstOpen == true ? vm.serviceStatus.isSecondOpen = true : null
          vm.serviceStatus.isSecondOpen == true ? vm.serviceStatus.isThirdOpen = true : null
          vm.serviceStatus.isThirdOpen == true ? vm.serviceStatus.isFourthOpen = true : null

          vm.militaryStatus.isFirstOpen == true ? vm.militaryStatus.isSecondOpen = true : null
          vm.militaryStatus.isSecondOpen == true ? vm.militaryStatus.isThirdOpen = true : null
          vm.militaryStatus.isThirdOpen == true ? vm.militaryStatus.isFourthOpen = true : null
        }

        function tabOpen(){
          if(vm.tab == 'obituary'){
            vm.tab = 'service'
            $timeout(function(){
              vm.serviceStatus.isFirstOpen = true
            }, 100)
          }
          else if(vm.tab == 'service'){
            vm.tab = 'military'
            $timeout(function(){
              vm.militaryStatus.isFirstOpen = true
            }, 100)
          }
          else if(vm.tab == 'military'){
            vm.tab = 'other'
          }
        }

        const socketUpdateOrderCaseListender = $rootScope.$on('socketUpdateOrder', function(){
          if(vm.case.id) {
            updateCase()
          }
        })

        $scope.$on('$destroy', () => socketUpdateOrderCaseListender())

        function sendInvite() {
          let payload = {
            name_of_deceased: vm.case.name_of_deceased,
            recipient: vm.invite.name,
            email: vm.invite.email,
            case_id: vm.case.id,
            expiration_date: vm.invite.expiration_date,
            number_allowed: vm.invite.number_allowed
          }

          user.sendInvite(payload).then(response => {
            if(response.data.emailSent) {
              vm.invite = {}
              vm.emailSent = true
              $timeout(function() {
                vm.emailSent = false
                vm.seeinvite = false
              }, 1000)
            }
          })
        }

        function getMemorials() {
          cases.getCaseMemorialsForCase(vm.case.id).then(response => {
            vm.memorials = response.data
          })
        }

        function archiveMemorial(id) {
          cases.archiveCaseMemorial(id).then(response => {
            cases.getCaseMemorialsForCase(vm.case.id).then(response => {
              vm.memorials = response.data
            })
          })
        }

        function createMemorial() {
          vm.newMemorial.case_id = vm.case.id
          cases.createCaseMemorial(vm.newMemorial).then(response => {
            if(response.status == 201) {
							vm.memorials.unshift(response.data)
							vm.newMemorial = {}
              vm.chars = 0
						} else {
							//handle error?
						}
          })
        }

        function getChars() {
          $('#case-memorial-textarea').on('input', function(){
  			    var currentLength = $(this).val().length;
  					vm.chars = currentLength
  				});
        }

        applyWhenElementExists('#case-memorial-textarea', getChars, 50)

        function applyWhenElementExists(selector, myFunction, intervalTime) {
          const interval = setInterval(() => {
            if (jQuery(selector).length > 0) {
               myFunction();
               clearInterval(interval);
            }
          }, intervalTime);
        }

        //******** end *********//
      }
    }
  }

})();
