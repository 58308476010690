angular.module('app').service('collections', function($http, $q) {

  this.getAll = function() {
    const deferred = $q.defer();
    $http.get(api + 'collections')
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.get = function(id) {
    const deferred = $q.defer();
    $http.get(api + `collections/${id}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
