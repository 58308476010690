(function() {
	angular.module("app").directive("familyUpload", familyUploadController);

	function familyUploadController(cases, $routeParams, $rootScope, $timeout, helpers) {
		return {
			templateUrl: "pages/familyUpload/familyUpload.html",
			controllerAs: "vm",
			controller: function() {
				var vm = this;
        angular.extend(vm, {
          case: {},
					uploadImage: uploadImage,
					upload: $routeParams.token,
					view: {
						upload: true,
						memorials: false
					},
					switchViews: switchViews,
					memorials: [],
					saveMemorial: saveMemorial,
					newMemorial: {},
					chars: 0,
					logo: '',
					uploading: false
        })

        init()

        function init() {
          getCase()
        }

        function getCase() {
          cases.getAsGuest($routeParams.token).then(response => {
            vm.case = response.data
						vm.logo = `${helpers.imgixLogo}${vm.case.logo}`
						vm.case.personal_images = vm.case.personal_images.reverse()
          })
					cases.getCaseMemorialsAsGuest($routeParams.token).then(response => {
						vm.memorials = response.data
					})
        }

				function uploadImage(){
					vm.uploading = true
          $timeout(function(){
            $('#family_images')[0].click()
          })
        };

				$rootScope.$on('familyModalUpload', (event, payload) => {
          vm.case.personal_images = payload.reverse()
					vm.uploading = false
        })


				function switchViews(selection) {
					if(selection == 'upload') {
						vm.view.upload = true
						vm.view.memorials = false
					} else {
						vm.view.memorials = true
						vm.view.upload = false
					}
				}

				function saveMemorial() {
					vm.newMemorial.case_id = vm.case.id
					cases.createCaseMemorialAsGuest($routeParams.token, vm.newMemorial).then(response => {
						if(response.status == 201) {
							vm.memorials.unshift(response.data)
							vm.newMemorial = {}
							vm.chars = 0
						} else {
							//handle error?
						}
					})
				}

				$('#memorial-textarea').on('input', function(){
			    var currentLength = $(this).val().length;
					vm.chars = currentLength
				});

			//*** END ***//
      }
    }
  }
})();
