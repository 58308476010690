angular.module('app').service('tukios', function($http, $q) {

  this.create = function(order) {
    const deferred = $q.defer();
    $http.post(api + `createTukiosFilm`, order)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
