(function() {
	angular.module("app").directive("login", loginController);

	function loginController(user, $q, $timeout, $routeParams, $location, funeralHomes) {
		return {
			templateUrl: "pages/login/login.html",
			controllerAs: "vm",
			controller: function() {
				var vm = this;

				angular.extend(vm, {
					form: {},
					forgotemail: null,
					login: login,
					loggingin: false,
					forgotPassword: forgotPassword,
					submitting: false,
					resetForm: resetForm,
					showServerToggle: false,
					toggleServer: toggleServer,
					servers: [
						{
							name: "local",
							url: "//localhost:8080/"
						},
						{
							name: "dps-develop",
							url: "https://dps-develop.herokuapp.com/"
						},
						{
							name: "int-test",
							url: "https://dps-int-test.herokuapp.com/"
						},
						{
							name: "dps-prod",
							url: "https://dps-prod.herokuapp.com/"
						}
					]
				});

				function filterUrl() {
					$routeParams.showReset
						? (vm.action = "forgot")
						: vm.action != "forgot";
					vm.showServerToggle = isLocal ? true : false;
				}

				filterUrl();

				function toggleServer(url) {
					api = url
					console.log(`%c manually-selected API = ${api}`, "color: rgb(186, 73, 72); font-weight: bold; font-style: italic");
				}

				function login() {
					vm.loggingin = true;
					funeralHomes.home = null;
					user.login(vm.form).then(function(response) {
						response.data.funeral_home_active == true
							? (vm.active = true)
							: (vm.active = false);
						if (response.status !== 200) {
							vm.loggingin = false;
							vm.loginError = response.data.message;
						} else {
							if (vm.active == false) {
								$("#alert-modal").modal("show");
							} else if (user.trialDays) {
								FS.identify(response.data.id, {
								  displayName: vm.form.email,
								  email: vm.form.email
								});
								vm.loggingin = false;
								$location.path("welcome");
							} else {
								FS.identify(response.data.id, {
								  displayName: vm.form.email,
								  email: vm.form.email
								});
								vm.loggingin = false;
								$location.path("dashboard");
							}
						}
					});
				}

				function resetForm() {
					vm.loginError = null;
				}

				function forgotPassword() {
					vm.submitting = true;
					if (!vm.forgotemail) {
						vm.message = "Please enter a valid email.";
					} else {
						user.forgotPassword(vm.forgotemail).then(function(response) {
							if (response.status == 200) {
								vm.message = "Reset password email sent.";
							} else if (response.data.message) {
								vm.message = response.data.message;
								vm.showThirdPartySupport = true;
							}
							vm.submitting = false;
							vm.forgotemail = null;
						});
					}
				}

				//******** end *********//
			}
		};
	}
})();
