angular.module('app').service('pages', function($http, $q, $rootScope, funeralHomeSettings) {

  const service = this

  function convertAllDates(payload) {
    const dateFormat = funeralHomeSettings.settings && funeralHomeSettings.settings.date_format ? funeralHomeSettings.settings.date_format : 'LL'

    const createdDate = payload.data.case.created_at ? moment.utc(payload.data.case.created_at).format(dateFormat) : null
    payload.data.case.created_at = createdDate

    const serviceDate = payload.data.case.service_date ? moment.utc(payload.data.case.service_date).format(dateFormat) : null
    payload.data.case.service_date = serviceDate

    const birthDate = payload.data.case.date_of_birth ? moment.utc(payload.data.case.date_of_birth).format(dateFormat) : null
    payload.data.case.date_of_birth = birthDate

    const deathDate = payload.data.case.date_of_death ? moment.utc(payload.data.case.date_of_death).format(dateFormat) : null
    payload.data.case.date_of_death = deathDate

    const marriageDate = payload.data.case.date_of_marriage ? moment.utc(payload.data.case.date_of_marriage).format(dateFormat) : null
    payload.data.case.date_of_marriage = marriageDate

    const intermentDate = payload.data.case.interment_date ? moment.utc(payload.data.case.interment_date).format(dateFormat) : null
    payload.data.case.interment_date = intermentDate

    const dateEnteredDate = payload.data.case.date_entered_service ? moment.utc(payload.data.case.date_entered_service).format(dateFormat) : null
    payload.data.case.date_entered_service = dateEnteredDate

    const dateDischargedDate = payload.data.case.date_discharged_from_service ? moment.utc(payload.data.case.date_discharged_from_service).format(dateFormat) : null
    payload.data.case.date_discharged_from_service = dateDischargedDate

    return payload
  }

  //PAGE SETS
  this.get = function(id) {
    const deferred = $q.defer();
    $http.get(api + `pageSets?layout_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getHomePageSets = function(id) {
    const deferred = $q.defer();
    $http.get(api + `pageSets?funeral_home_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getHomeLayoutPageSets = function(id) {
    const deferred = $q.defer();
    $http.get(api + `pageSets?layout_id=${id}&custom_pages=true`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getOrderPageSets = function(id) {
    const deferred = $q.defer();
    $http.get(api + `orderPageSets?order_layout_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createOrderPageSet = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createOrderPageSet`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createPageSet = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createPageSet`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.updatePageSet = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `pageSets/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }
  this.updateOrderPageSet = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `orderPageSets/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.archivePageSet = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `archivePageSet/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  //PAGES
  this.getPages = function(id) {
    const deferred = $q.defer();
    $http.get(api + `pages?page_set_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getOrderPageDetails = function(id) {
    const deferred = $q.defer();
    $http.get(api + `orderPageDetails?order_page_set_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }
  this.getEditorOrderPageDetails = function(id) {
    const deferred = $q.defer();
    $http.get(api + `editorOrderPageDetails?order_page_set_id=${id}`)
      .then(response => {
      const sanitizedData = convertAllDates(response)
      this.editorObject = sanitizedData.data      
      deferred.resolve(sanitizedData)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createOrderPageDetails = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createOrderPageDetails`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createPage = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createPage`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.updateOrderPage = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `orderPageDetails/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.archiveOrderPage = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `archiveOrderPageDetails/${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

});
