angular.module('app').service('vimeo', function($http, $q) {

  this.getVideos = function(){
    return $q.when($http({
      method: 'GET',
      url: 'https://api.vimeo.com/users/63234192/videos',
      headers: {
        'funeralhomeid': undefined,
        'userid': undefined,
        'x-auth-token': undefined,
        'x-api-token': undefined,
        'x-access-token': undefined,
        'Authorization': "bearer 34210aeac4e02a251b8821a53620e93c"
      },
      params: {
        callback: 'JSON_CALLBACK'
      }
    }))
  }
  
})
