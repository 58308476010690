(function() {

  angular.module('app').directive('support', supportController)

  function supportController($routeParams, vimeo, $sce) {
    return {

      templateUrl: 'pages/support/support.html',
      controllerAs: 'vm',
      controller: function() {
        var vm = this

        angular.extend(vm, {
          //Variables and Functions
          tab: 'contact',
          videos: [],
          pdfFiles: [],
          showVideo: true,
          goToTab: goToTab,
          loading: false,
          walkThrough: '<iframe src=\"https://player.vimeo.com/video/248145490?badge=0&autopause=0&player_id=0&app_id=2221\" width=\"1920\" height=\"1080\" frameborder=\"0\" title=\"Creating a Print Package - Full Walk Through\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>'
        })

        init()

        function init() {
          videoPlayPause()
          goToTab()
          // getVideos()
          getPDFs()
        }

        function getPDFs() {
          vm.pdfFiles = [
            {
              name: 'Clearing Cache',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Clearing-Cache.pdf'
            },
            {
              name: 'Uploading a Logo',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Uploading-a-Logo.pdf'
            },
            {
              name: 'Creating a Custom Theme',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-A-Custom-Theme.pdf'
            },
            {
              name: 'Memories Box',
              url: 'https://bassmollett-uploads.s3-us-west-2.amazonaws.com/Memories-Box.pdf'
            },
            {
              name: 'Creating an Opacity Box',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-An-Opacity-Box.pdf'
            },
            {
              name: 'Removing a Silhouette',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Removing-a-Silhouette.pdf'
            },
            {
              name: 'Adjusting Layer Level',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Adjusting-Layer-Level.pdf'
            },
            {
              name: 'Creating a Duplicate Order',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-a-Duplicate-Order.pdf'
            },
            {
              name: 'Image Editor',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Image-Editor.pdf'
            },
            {
              name: 'Creating a Saved Layout',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-a-Saved-Layout.pdf'
            },
            {
              name: 'Adding and Removing Pages',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Adding-And-removing-pages.pdf'
            },
            {
              name: 'Floral Pix App',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Floral-Pix-App.pdf'
            },
            {
              name: 'Uploading a Logo',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Uploading-A-Logo.pdf'
            },
            {
              name: 'Choosing a Product Line',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Choosing-a-Product-Line.pdf'
            },
            {
              name: 'Creating a Custom Verse or Phrase',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-a-Custom-Verse-or-Phrase.pdf'
            },
            {
              name: 'Creating a New Case',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-a-New-Case.pdf'
            },
            {
              name: 'Creating a New Print Package',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Creating-a-New-Print-Package.pdf'
            },
            {
              name: 'Memorial Ornament Labels',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Memorial-Ornament-Labels.pdf'
            },
            {
              name: 'Text Editing Tool',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Text-Editing-Tool.pdf'
            },
            {
              name: 'Uploading Photos',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Uploading-Photos.pdf'
            },
            {
              name: 'Remembrance Registry System',
              url: 'https://s3-us-west-2.amazonaws.com/bassmollett-uploads/Remembrance-Registry-System.pdf'
            }
          ]
        }

        function getVideos(){
          vm.loading = true
  				vimeo.getVideos().then(function(response){
            vm.videos = response.data.data
            vm.loading = false
  				})
        }

        function goToTab(tab){
          if(tab){
            vm.tab = tab
          } else if($routeParams.tab) {
            vm.tab = $routeParams.tab
          } else {
            vm.tab = 'contact'
          }
        }

        function videoPlayPause() {
          // when the modal is opened...
          $('#vid-modal1').on('show.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video1')[0].play()
          })
          $('#vid-modal1').on('hide.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video1')[0].pause()
          })

          // when the modal is opened...
          $('#vid-modal2').on('show.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video2')[0].play()
          })
          $('#vid-modal2').on('hide.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video2')[0].pause()
          })

          // when the modal is opened...
          $('#vid-modal3').on('show.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video3')[0].play()
          })
          $('#vid-modal3').on('hide.bs.modal', function () {
            // call play() on the <video> DOM element
            $('#video3')[0].pause()
          })
        }


        //******** end *********//
      }
    }
  }

})();
