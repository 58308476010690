(function() {
	angular.module("app").directive("drbLanding", drbLandingController);

	function drbLandingController(
    cases,
    drbOptions,
    funeralHomes,
    guests,
    helpers,
    orders,
    themes,
    user,
    $q,
    $window,
    $timeout,
    $routeParams,
    drbSubscriptions,
		$location,
    $rootScope)	{
		return {
			templateUrl: 'pages/drbLanding/drbLanding.html',
			scope: true,
			link: function(scope) {
        var vm = scope

        angular.extend(scope, {
          splash: true,
          payment: false,
          selectPlan: selectPlan,
          addSMS: addSMS,
          stripe: {},
          loading: false,
          plan: null,
          smsSelected: {
            annualSMS: false,
            monthlySMS: false,
          },
          selectedSMS: null,
          selectedPlan: null,
          stripeSubmitting: false,
          // checkout: checkout
        })

        init();

        async function init() {
          vm.loading = true
          const subscriber = await drbSubscriptions.getActiveSubscription()
          if(subscriber.status === 200) {
           
            $location.path('/drbPortal')
            vm.$apply() 
            
          
          } else {
            
              const res = await funeralHomes.get();
              vm.$apply()
              if(res.data.stripe_subscription_status !== null) {
                $location.path('/drbReturn')
                
              } else {
                vm.loading = false
                vm.$apply()
              }
            
          }
        }

        

        function selectPlan(plan){
          loadStripe()
          vm.plan = plan
          //STRIPE PLAN
          vm.selectedPlan = plan === 'monthly' ? {plan: drbSubscriptions.drb_monthly} : {plan: drbSubscriptions.drb_annual}
         
          vm.payment = true
        }

        function addSMS(plan) {
          //STRIPE PLAN
          vm.selectedSMS = plan === 'monthly' && vm.smsSelected.monthlySMS ? [{plan: drbSubscriptions.sms_incoming}, {plan: drbSubscriptions.sms_outgoing}] : plan === 'annual' && vm.smsSelected.annualSMS ? [{plan: drbSubscriptions.sms_incoming}, {plan: drbSubscriptions.sms_outgoing}] : null
        }
        
        function loadStripe() {
          vm.stripe = Stripe(drbSubscriptions.key);
          var elements = vm.stripe.elements();
        
          var card = elements.create('card', {
              iconStyle: 'solid',
              style: {
                base: {
                  iconColor: '#ccc',
                  color: '#a6a6a6',
                  fontWeight: 500,
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
          
                  ':-webkit-autofill': {
                    color: '#fce883',
                  },
                  '::placeholder': {
                    color: '#ccc',
                  },
                },
                invalid: {
                  iconColor: '#ccc',
                  color: '#ccc',
                },
              },
            });
          !vm.loading && card.mount('#stripe-card');
        
          registerElements([card], 'stripe-form');
       
        }

        

      async function registerElements(elements, exampleName) {

        const formClass = `.${exampleName}`;
        const example = document.querySelector(formClass);

        const form = example.querySelector('form');
        // var resetButton = example.querySelector('a.reset');
        const error = form.querySelector('.error');
        const errorMessage = error.querySelector('.message');

        function enableInputs() {
          Array.prototype.forEach.call(
            form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.removeAttribute('disabled');
            }
          );
        }

        function disableInputs() {
          Array.prototype.forEach.call(
            form.querySelectorAll(
              "input[type='text'], input[type='email'], input[type='tel']"
            ),
            function(input) {
              input.setAttribute('disabled', 'true');
            }
          );
        }

        async function triggerBrowserValidation() {
          // The only way to trigger HTML5 form validation UI is to fake a user submit
          // event.
          const submit = document.createElement('input');
          submit.type = 'submit';
          submit.style.display = 'none';
          form.appendChild(submit);
          submit.click();
          submit.remove();
        }

        // Listen for errors from each Element, and show error messages in the UI.
        let savedErrors = {};
        elements.forEach(function(element, idx) {
          element.on('change', function(event) {
            if (event.error) {
              error.classList.add('visible');
              savedErrors[idx] = event.error.message;
              errorMessage.innerText = event.error.message;
            } else {
              savedErrors[idx] = null;

              // Loop over the saved errors and find the first one, if any.
              var nextError = Object.keys(savedErrors)
                .sort()
                .reduce(function(maybeFoundError, key) {
                  return maybeFoundError || savedErrors[key];
                }, null);

              if (nextError) {
                // Now that they've fixed the current error, show another one.
                errorMessage.innerText = nextError;
              } else {
                // The user fixed the last error; no more errors.
                error.classList.remove('visible');
              }
            }
          });
        });

        // Listen on the form's 'submit' handler...
        form.addEventListener('submit', async function(e) {
          vm.stripeSubmitting = true
          e.preventDefault();
          
          // Trigger HTML5 validation UI on the form if any of the inputs fail
          // validation.
          var plainInputsValid = true;
          Array.prototype.forEach.call(form.querySelectorAll('input'), function(
            input
          ) {
            if (input.checkValidity && !input.checkValidity()) {
              plainInputsValid = false;
              return;
            }
          });
          if (!plainInputsValid) {
            triggerBrowserValidation();
            return;
          }

          // Show a loading screen...
          example.classList.add('submitting');

          // Disable all inputs.
          disableInputs();

          // Gather additional customer data we may have collected in our form.
          var name = form.querySelector(`#stripe-name`);
          var email = form.querySelector(`#stripe-email`);
          var address1 = form.querySelector(`#stripe-address`);
          var city = form.querySelector(`#stripe-city`);
          var state = form.querySelector(`#stripe-state`);
          var zip = form.querySelector(`#stripe-zip`);
          var country = form.querySelector(`#stripe-country`);
          var additionalData = {
            name: name ? name.value : undefined,
            email: email ? email.value : undefined,
            address_line1: address1 ? address1.value : undefined,
            address_city: city ? city.value : undefined,
            address_state: state ? state.value : undefined,
            address_zip: zip ? zip.value : undefined,
            address_country: country ? country.value : undefined,
          };

          // Use Stripe.js to create a token. We only need to pass in one Element
          // from the Element group in order to create a token. We can also pass
          // in the additional customer data we collected in our form.
          const result_token_one = await vm.stripe.createToken(elements[0], additionalData)
          // Stop loading!
          example.classList.remove('submitting');

          if (result_token_one.token) {
            // If we received a token, show the token ID.
            example.classList.add('submitted');
            let plans = []
            vm.selectedPlan && plans.push(vm.selectedPlan)
            const payload = {
                sourceToken: result_token_one.token.id,
                email: additionalData.email,
                name: additionalData.name,
                phone: additionalData.phone,
                address: additionalData.address_line1,
                city: additionalData.address_city,
                country: additionalData.address_country,
                zip: additionalData.address_zip,
                plans: plans,
                trial: true,
              }
            const subscription = await drbSubscriptions.createSubscription(payload)
          }
          else {
            // Otherwise, un-disable inputs.
            enableInputs();
          }
          //the second token should not be needed as it will take the default payment from the first, but this is in case it fails
          if(vm.selectedSMS) {
          const result_token_two = await vm.stripe.createToken(elements[0], additionalData)
          // Stop loading!
          example.classList.remove('submitting');

          if (result_token_two.token) {
            // If we received a token, show the token ID.
            example.classList.add('submitted');
            let plans = []
            

          
              const sms_payload = {
                sourceToken: result_token_two.token.id,
                email: additionalData.email,
                plans: vm.selectedSMS,
                //collection_method: 'send_invoice'
              }
              const sms_sub = await drbSubscriptions.createSubscription(sms_payload)

            }
            else {
              // Otherwise, un-disable inputs.
              enableInputs();
            }
              
            
            
          }
          const subscriber = await drbSubscriptions.getActiveSubscription()
            if(subscriber.status === 200) {
              $location.path('/drbPortal')
              vm.$apply() 
            } else {
              const uhoh = true
            }
          vm.stripeSubmitting = false
          vm.$apply()  
        });

        // resetButton.addEventListener('click', function(e) {
        //   e.preventDefault();
        //   // Resetting the form (instead of setting the value to `''` for each input)
        //   // helps us clear webkit autofill styles.
        //   form.reset();

        //   // Clear each Element.
        //   elements.forEach(function(element) {
        //     element.clear();
        //   });

        //   // Reset error state as well.
        //   error.classList.remove('visible');

        //   // Resetting the form does not un-disable inputs, so we need to do it separately:
        //   enableInputs();
        //   example.classList.remove('submitted');
        // });
      }

      // function checkout() {
      //   var stripe = Stripe('pk_test_KOHgStjyUxStQxkkdRUnvrqC00MwN6jegH');
      //   stripe.redirectToCheckout({
      //     // Make the id field from the Checkout Session creation API response
      //     // available to this file, so you can provide it as parameter here
      //     // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      //     sessionId: 'cs_test_HwA2zyPj8Dlpv3HW2HjLJXiTNeykYCx45LcDlPomYrVqa7xmTvAB4Bja'
      //   }).then(function (result) {
      //     // If `redirectToCheckout` fails due to a browser or network
      //     // error, display the localized error message to your customer
      //     // using `result.error.message`.
      //   });
      // }
      
      // function checkout() {
      //   var stripe = Stripe('pk_test_KOHgStjyUxStQxkkdRUnvrqC00MwN6jegH');
      //     // When the customer clicks on the button, redirect
      //     // them to Checkout.
      //     stripe.redirectToCheckout({
      //       items: [{plan: 'plan_ElXMcVnt7y8G4N', quantity: 1}],

      //       successUrl: 'http://localhost:8080/drbPortal',//$location.path('/drbPortal'),
      //       cancelUrl: 'http://localhost:8080/drbLanding',//$location.path('/drbLanding'),
      //     })
      //     .then(function (result) {
      //       if (result.error) {
      //         // If `redirectToCheckout` fails due to a browser or network
      //         // error, display the localized error message to your customer.
      //         var displayError = document.getElementById('error-message');
      //         displayError.textContent = result.error.message;
      //       }
      //     });
        
      // };

      //******** end *********//
      }
    }
  }
})();