angular.module('app').service('messages', function($http, $q) {

  this.get = function() {
    const deferred = $q.defer();
    $http.get(api + `statusMessages`)
      .then((response) => {
      this.message = response.data
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
