angular.module('app').service('layouts', function($http, $q) {

  var service = this

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createLayout`, payload)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.get = function(id) {
    const deferred = $q.defer();
    $http.get(api + `layouts?product_id=${id}`)
      .then((response) => {
      this.selectedProducts = response.data
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getById = function(id) {
    const deferred = $q.defer();
    $http.get(api + `layouts/${id}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getForCustomThemes = function(product_id) {
    const deferred = $q.defer();
    $http.get(api + `layoutsForCustomTheme/${product_id}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.uploadReplacementImage = function(layout_id, payload) {
    const deferred = $q.defer();
    var config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.post(api + `uploadReplacementImage/${layout_id}`, payload, config)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `layouts/${id}`, payload)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
