angular.module('app').service('guests', function($http, $q) {
  
  this.getGuests = function(id) {
    let deferred = $q.defer();
    $http
      .get(api + `guestsForCase?case_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  }
  
  this.generateGuestsCSV = function(id) {
    let deferred = $q.defer();
    $http
      .get(api + `guestsDataCSV?case_id=${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.deleteGuest = async function(id) {
    const post =  $http.delete(`${api}guests/${id}`)
    try { return await post} catch (err) { throw err }
  }

})