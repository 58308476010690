(function() {
  angular.module("app").directive("customVerse", customVerseController);
  function customVerseController(
    cases,
    assets,
    $routeParams,
    $timeout,
    $compile,
    customVerses,
    $rootScope,
    dom,
    helpers,
  ) {
    return {
      templateUrl: "pages/editor/customVerse/custom-verse.html",
      controllerAs: "cvm", // CUSTOM VERSE MODEL
      controller: function($scope) {
        const cvm = this;

        angular.extend(cvm, {
            customVerse: customVerse,
            customVerseWeight: customVerseWeight,
            populateVerse: populateVerse,
            createVerse: createVerse,
            updateVerse: updateVerse,
            updateVerseSelected: updateVerseSelected,
            previewVerse: previewVerse,
            addVerse: addVerse,
            verseUpdate: verseUpdate,
            deleteVerse: deleteVerse,
            compiledVerses: [],
            saveVerseAndAddToProduct: saveVerseAndAddToProduct,
            updatingVerse: false,
            creatingVerse: true,
        });

        // LAUNCHES THE CUSTOM VERSE EDITOR
        function customVerse() {
          cvm.compiledVerses = customVerses.compiledVerses
          $('#verse-modal').modal('show')
          populateVerse()
          cvm.updatingVerse = false
          cvm.creatingVerse = true
        }

        function customVerseWeight(index){
          cvm.verseWeight = index
          verseUpdate(cvm.selectedFont)
        }

        // POPULATES CUSTOM VERSE TEXT BOX WITH EITHER A TYPING PROMPT OR SELECTED VERSE
        function populateVerse(verse, index) {
          verse && verse.id ? cvm.verseId = verse.id : cvm.verseId = null
          cvm.selectedVerseIndex = index
          if(verse){
            cvm.updatingVerse = true
            cvm.creatingVerse = false
          } else {
            cvm.updatingVerse = false
            cvm.creatingVerse = true
          }
          var el1
          var el2
          if(verse){
            el1 = $compile(verse.html)(cvm)
          } else {
            el1 = $compile('<p class="custom-verse-preview" style="line-height: 1; width: 320px; color:rgb(0, 0, 0)"  ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" contenteditable="true">Type Text Here</p>')(cvm)

          }
          if(verse){
            el2 = $compile('<p class="custom-verse-reference" style="color:rgb(0, 0, 0)" ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" contenteditable="true">' + verse.reference + '</p>')(cvm)
          } else {
            el2 = $compile('<p class="custom-verse-reference" style="color:rgb(0, 0, 0)" ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" contenteditable="true">Type Reference Here</p>')(cvm)
          }
          $('.custom-verse-text').remove()
          $('.custom-verse-preview').remove()
          $('.custom-verse-reference').remove()
          $('.ql-toolbar').remove()
          $('.ql-tooltip').remove()
          $('.custom-verse-content').append(el1)

          $('.custom-verse-content').append(el2)

          $('.custom-verse-text').dblclick()
          $('.custom-verse-preview').dblclick()
        }

        function createVerse(){
          let obj = {}
          const reference = $('.custom-verse-reference').text()
          const height = $('.custom-verse-text').prop('scrollHeight')
          const width = $('.custom-verse-text').width()

          // Save HTML to dB without inline styling in order to prevent interference with Quill
          $('.custom-verse-text').removeClass('quill')
          $('.custom-verse-text').addClass('oldQuill')
          obj.html = $('.custom-verse-text').prop('outerHTML')

          dom.setVerseInlineStyles()

          if($('.custom-verse-content .ql-clipboard').length) $('.custom-verse-content .ql-clipboard').remove()
          if($('.custom-verse-content .ql-tooltip').length) $('.custom-verse-content .ql-tooltip').remove()

          obj.reference = reference
          $('.custom-verse-text').css({
            "width": width
          })
          const htmlWithInlineStyling = $('.custom-verse-text').prop('outerHTML')
          obj.html_for_render = `${helpers.printHeader}${htmlWithInlineStyling}${helpers.cvStyle}${helpers.printFooter}`
          customVerses.create(obj).then(response => {
            customVerses.compiledVerses.unshift(response.data)
            $('#verse-modal').modal('hide')
            $('.custom-verse-text').addClass('quill')
            $('.custom-verse-text').removeClass('oldQuill')
          })
        }

        function updateVerse() {
          let obj = {}
          const reference = $('.custom-verse-reference').text()
          const height = $('.custom-verse-text').prop('scrollHeight')
          const width = $('.custom-verse-text').width()

          // Save HTML to dB without inline styling in order to prevent interference with Quill
          $('.custom-verse-text').removeClass('quill')
          $('.custom-verse-text').addClass('oldQuill')
          obj.html = $('.custom-verse-text').prop('outerHTML')

          dom.setVerseInlineStyles()

          if($('.custom-verse-content .ql-clipboard').length) $('.custom-verse-content .ql-clipboard').remove()
          if($('.custom-verse-content .ql-tooltip').length) $('.custom-verse-content .ql-tooltip').remove()

          obj.reference = reference
          $('.custom-verse-text').css({
            "width": width
          })
          const htmlWithInlineStyling = $('.custom-verse-text').prop('outerHTML')
          obj.html_for_render = `${helpers.printHeader}${htmlWithInlineStyling}${helpers.cvStyle}${helpers.printFooter}`
          customVerses.update(cvm.verseId, obj).then(response => {
            $('#verse-modal').modal('hide')
            customVerses.getAll()
            $('.custom-verse-text').addClass('quill')
            $('.custom-verse-text').removeClass('oldQuill')
          })
        }

        function updateVerseSelected(change) {
          setUndoState()
          var verseSelected = $(cvm.verseSelected.element)
          var type = verseSelected.data('type')
          cvm.saved = false

          verseSelected.css({
            'background-color': cvm.verseselected.backgroundcolor
          })

          if (type == 'text') {
            verseSelected.css('height', cvm.verseSelected.height + 'px')
            verseSelected.css('font-size', cvm.verseSelected.fontsize + 'px')
            if (change == 'rgb') {
              cvm.verseSelected.fontcolor = rgbToHex(cvm.verseSelected.fontrgbcolor.r, cvm.verseSelected.fontrgbcolor.g, cvm.verseSelected.fontrgbcolor.b)
            }
            verseSelected.css('color', cvm.verseSelected.fontcolor)
            cvm.verseSelected.fontrgbcolor = hexToRgb(cvm.verseSelected.fontcolor)
            verseSelected.css('text-decoration', cvm.verseSelected.textdecoration)
          }
        }

        function previewVerse(index) {
          var el
          el = $compile(cvm.home.verses[index].preview)(cvm)
          $('.custom-verse-preview').remove()
          $('.append-here').append(el)
          $('#versePreview').modal('show')
        }

        function addVerse(index) {
          $scope.setUndoState()
          const verses = customVerses.compiledVerses
          var el
          var html = verses[index].html.replace(/(?:\r\n|\r|\n)/g, '')
          if(html.includes('contenteditable')){
            //Do nothing
          } else {
            var html = html.splice(html.indexOf(' '), 0, ' contenteditable="true"')
          }

          if(html.indexOf('oldQuill') != -1) {
            //do nothing
          } else {
            html = html.splice(html.indexOf(' '), 0, ` ng-click="selectElement($event)" ondblclick="document.execCommand('selectAll',false,null)" data-type="text"`)
            html = html.splice(html.indexOf('class="'), 7, `class="draggable element `)
          }

          html = html.splice(html.indexOf(' '), 0, ' id="new-verse"')
          el = $compile(html)($scope)
          var selector = '#new-verse'
          dom.insertAndHighlightNewText(el, selector)
        }

        function verseUpdate(index) {
          setUndoState();
          $(cvm.verseSelected.element).css('padding', '10px')
          $(cvm.verseSelected.element).css('font-family', cvm.fonts[index].name)
          cvm.selectedFont = index
          if (cvm.fonts[index].weights) {
            if (cvm.verseWeight) {
              $(cvm.verseSelected.element).css('font-weight', cvm.fonts[index].weights[cvm.verseWeight].weight)
            } else {
              $(cvm.verseSelected.element).css('font-weight', cvm.fonts[index].weights[0].weight)
              cvm.selectedWeight = 0
            }
          } else {
            $(cvm.verseSelected.element).css('font-weight', 'normal')
          }
          adjustHeight()
        }

        function deleteVerse(verse, index) {
          if(confirm("Are you sure you want to delete this reference?") == true){
            if(verse.funeral_home_id){
              customVerses.delete(verse.id).then(response => {
                cvm.compiledVerses.splice(index, index + 1)
              })
            } else {
              customVerses.createExclusion({custom_verse_id: verse.id}).then(response => {
                cvm.compiledVerses.splice(index, index + 1)
              })
            }

          }
        }

        function saveVerseAndAddToProduct(){
          if(cvm.creatingVerse){
            let obj = {}
            const height = $('.custom-verse-text').prop('scrollHeight')
            const width = $('.custom-verse-text').width()
            obj.reference = $('.custom-verse-reference').text()

            // Save HTML to dB without inline styling in order to prevent interference with Quill
            $('.custom-verse-text').removeClass('quill')
            $('.custom-verse-text').addClass('oldQuill')
            obj.html = $('.custom-verse-text').prop('outerHTML')

            dom.setVerseInlineStyles()

            if($('.custom-verse-content .ql-clipboard').length) $('.custom-verse-content .ql-clipboard').remove()
            if($('.custom-verse-content .ql-tooltip').length) $('.custom-verse-content .ql-tooltip').remove()
            $('.custom-verse-text').css({
              "width": width
            })
            const htmlWithInlineStyling = $('.custom-verse-text').prop('outerHTML')
            obj.html_for_render = `${helpers.printHeader}${htmlWithInlineStyling}${helpers.cvStyle}${helpers.printFooter}`
            customVerses.create(obj).then(response => {
              customVerses.compiledVerses.unshift(response.data)
              $scope.setUndoState()
              var el
              var html = response.data.html.replace(/(?:\r\n|\r|\n)/g, '')
              if(html.includes('contenteditable')){
                //Do nothing
              } else {
                var html = html.splice(html.indexOf(' '), 0, ' contenteditable="true"')
              }

              if(html.indexOf('oldQuill') != -1) {
                //do nothing
              } else {
                html = html.splice(html.indexOf(' '), 0, ` ng-click="selectElement($event)" ondblclick="document.execCommand('selectAll',false,null)" data-type="text"`)
                html = html.splice(html.indexOf('class="'), 7, `class="draggable element `)

              }

              html = html.splice(html.indexOf(' '), 0, ' id="new-verse"')
              el = $compile(html)($scope)

              var selector = '#new-verse'
              dom.insertAndHighlightNewText(el, selector)

              $('#verse-modal').modal('hide')
            })
          } else {
            let obj = {}
            const reference = $('.custom-verse-reference').text()
            const height = $('.custom-verse-text').prop('scrollHeight')
            const width = $('.custom-verse-text').width()

            // Save HTML to dB without inline styling in order to prevent interference with Quill
            $('.custom-verse-text').removeClass('quill')
            $('.custom-verse-text').addClass('oldQuill')
            obj.html = $('.custom-verse-text').prop('outerHTML')

            dom.setVerseInlineStyles()

            if($('.custom-verse-content .ql-clipboard').length) $('.custom-verse-content .ql-clipboard').remove()
            if($('.custom-verse-content .ql-tooltip').length) $('.custom-verse-content .ql-tooltip').remove()

            obj.reference = reference
            $('.custom-verse-text').css({
              "width": width
            })
            const htmlWithInlineStyling = $('.custom-verse-text').prop('outerHTML')
            obj.html_for_render = `${helpers.printHeader}${htmlWithInlineStyling}${helpers.cvStyle}${helpers.printFooter}`
            customVerses.update(cvm.verseId, obj).then(response => {
              $scope.setUndoState()
              var el
              var html = response.data.html.replace(/(?:\r\n|\r|\n)/g, '')
              if(html.includes('contenteditable')){
                //Do nothing
              } else {
                var html = html.splice(html.indexOf(' '), 0, ' contenteditable="true"')
              }

              if(html.indexOf('oldQuill') != -1) {
                //do nothing
              } else {
                html = html.splice(html.indexOf(' '), 0, ` ng-click="selectElement($event)" ondblclick="document.execCommand('selectAll',false,null)" data-type="text"`)
                html = html.splice(html.indexOf('class="'), 7, `class="draggable element `)

              }

              html = html.splice(html.indexOf(' '), 0, ' id="new-verse"')
              el = $compile(html)($scope)

              var selector = '#new-verse'
              dom.insertAndHighlightNewText(el, selector)

              $('#verse-modal').modal('hide')
              customVerses.getAll()
            })

          }
        }

        $('#verse-modal').on('dblclick', 'p', function(e) {
						if($(this).hasClass('custom-verse-text') || $(this).hasClass('custom-verse-preview')) {
							$scope.newQuill($(this))
						}
        })
        $('#verse-modal').on('dblclick', '.oldQuill', function(e) {
						if($(this).hasClass('custom-verse-text') || $(this).hasClass('custom-verse-preview')) {
							$scope.enableQuill($(this))
						}

        })
      }
    }
  }
})()
