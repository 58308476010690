(function() {

  angular.module('app').directive('layoutTemplate', templateController)

  function templateController($routeParams, orders, user, funeralHomes, $rootScope, $q, assets, $compile, $document, cases, $timeout, themes, pages) {
    return {
      templateUrl: 'pages/editor/layoutTemplate/template.html',
      controllerAs: 'tvm',
      controller: function($scope) {
        var vm = this

        angular.extend(vm, {
          //variables and functions
          defaultTemplates: [],
          product: {},
          selectedProductTemplates: [],
          save: save,
          newTemplate: {},
          defaultTemplate: null,
          setProductTemplate: setProductTemplate,
          response: {},
          saving: false,
          message: null,
          productPages: [],
          home: {},
          layout: {},
          productPages: {},
        })

        const templateListener = $rootScope.$on('template', function(event){
          getOrder()
        })

        $scope.$on('$destroy', () => templateListener())

        function getOrder() {
          vm.order = pages.editorObject.order
          vm.product = pages.editorObject.product
          vm.layout = pages.editorObject.layout
          vm.pageSet = pages.editorObject.page_set
          vm.productPages = pages.editorObject.pages

          setProduct()
        }

        function setProduct() {
          pages.getHomeLayoutPageSets(vm.layout.layout_id).then(response => {
            vm.selectedProductTemplates = response.data
            for (let pageSet of vm.selectedProductTemplates) {
              if(pageSet.default) {
                vm.defaultTemplate = pageSet
              }
            }
          })
          $rootScope.$emit('setTemplate')
        }


        function setProductTemplate(template) {
          vm.loading = true
          for (let page of vm.productPages) {
            pages.archiveOrderPage(page.id).then(response => {
            })
          }
          let payload = Object.assign({}, template)
          payload.page_set_id = template.id
          // payload.order_layout_id = vm.layout.id
          delete payload.archived
          delete payload.id
          delete payload.funeral_home_id
          delete payload.order_layout_id
          pages.updateOrderPageSet(vm.pageSet.id, payload).then(response => {
            vm.pageSet = response.data
            pages.getPages(template.id).then(response => {
              let productPages = response.data
              for (let i in response.data){
                let payload = Object.assign({}, response.data[i])
                payload.order_page_set_id = vm.pageSet.id
                delete payload.id
                delete payload.archived
                pages.createOrderPageDetails(payload).then(response => {
                  if(i == productPages.length - 1) {
                    const data = {
                      'template': vm.pageSet
                    }
                    $rootScope.$emit('newTemplate', data)
                    vm.loading = false
                    $('#template-modal').modal('hide')
                  }
                })
              }
            })
          })
        }

        async function save() {

          let defaultTemplate = vm.newTemplate.default

          if($scope.quillActive) await domHelpers.saveDOMReset($scope.quillActive)
					if($scope.quillActive) await helpers.applyWhenElementExists($('.quill'), $scope.disableQuill, 50, 40)

          if($('#editor .product .printedarea').length >= 1) {

            $('.outlines').css({
              width: '100%',
              height: '100%'
            })

            let payload = {
              default: false,
              home_template: true,
              layout_id: vm.layout.layout_id,
              name: vm.newTemplate.name,
            };

            pages.createPageSet(payload).then(async response => {
              let newPageSet = response.data
              let updateSetPayload = Object.assign({}, newPageSet)
              updateSetPayload.page_set_id = newPageSet.id
              delete updateSetPayload.id
              delete updateSetPayload.archived

              pages.updateOrderPageSet(vm.pageSet.id, updateSetPayload).then(async response => {
                let updatedSet = response.data
                vm.pageSet = updatedSet
                let newPages = []
                
                await Promise.all(
                  $('.product .printedarea').toArray().map(async (value, index) => {

                    let changeLeft = false
                    if ($(value).css('left') == '0px') {
                      changeLeft = true
                      $(value).css('left', '50%')
                    }

                    let pagePayload = Object.assign({}, vm.productPages[index])
                    
                    delete pagePayload.page_set_id
                    pagePayload.template = $(value).prop('outerHTML').replace(/transform:.*scale\((?=.{0,10}\)).+?\)/g, '').replace(/left: '0px'/g, 'left: "50%"')
                    pagePayload.page_set_id = newPageSet.id
                    pagePayload.page_number = index + 1
                    delete pagePayload.id
                    delete pagePayload.order_page_set_id
                    delete pagePayload.archived

                    const newPage = await pages.createPage(pagePayload)
                    newPages.push(newPage.data)

                    changeLeft ? $(value).css("left", "0px") : $(value).css("left", "50%");

                  })
                )

                let pagesDetected = true
                newPages.length === 0 && (pagesDetected = false)
                newPages.map(page => !page.template && (pagesDetected = false))

                if(pagesDetected) {
                  await pages.updatePageSet(newPageSet.id, {default: defaultTemplate || false}).then(res => {
                    newPageSet.default = res.data.default
                  })
                } else {
                  await pages.updatePageSet(newPageSet.id, {default: false}).then(res => pages.archivePageSet(newPageSet.id))
                }

                if(newPageSet.default === true) {
                  for (let set of vm.selectedProductTemplates) {
                    let setPayload = Object.assign({}, set)
                    setPayload.default = false
                    
                    let id = setPayload.id
                    delete setPayload.id
                    delete setPayload.archived
                    if(set.home_template) {
                      pagesDetected && pages.updatePageSet(id, setPayload).then(response => {})
                    }
                  }
                }
                pagesDetected && $rootScope.$emit('setTemplate')
                $('#template-modal').modal('hide')
              })
            })

            vm.pageSet.name = vm.newTemplate.name
            vm.newTemplate = {}

            } else {
              if(alert('Your product did not load correctly, please return to the Print Package page and try again.')) {
                $('#template-modal').modal('hide')
              } else {
                $('#template-modal').modal('hide')
              }
            }
        }

        //******** end *********//
      }
    }
  }

})();
