angular.module('app').service('socket', function ($rootScope, $timeout, $location) {

  const socket = this;

  socket.time = 600000
  socket.socketTimeOut = null

  this.connect = function() {
    this.socketTimer = socket.time
    socket.connectedSocket = io.connect(api, {
      "transports": ['websocket']
    });
    socket.timeOutConnection()
  }

  this.timeOutConnection = function() {
    if(socket.socketTimeOut) {
      $timeout.cancel(socket.socketTimeOut)
    }
    socket.socketTimeOut = $timeout(function() {
      if(socket.connectedSocket) {
        socket.connectedSocket.close();

        if($location.path().indexOf('order') != -1) {
          $rootScope.$broadcast('socketUpdateOrder')
        }
        if($location.path().indexOf('editor') != -1) {
          $rootScope.$broadcast('socketSavePages')
        }
      }
    }, this.socketTimer)
  }

  this.on = function (eventName, callback) {
    socket.connectedSocket.on(eventName, function () {
      var args = arguments;
      $rootScope.$apply(function () {
        callback.apply(socket.connectedSocket, args);
      });
    });
  },

  this.emit = function (eventName, data, callback) {
    socket.data = data
    socket.connectedSocket.emit(eventName, data, function () {
      var args = arguments;
      $rootScope.$apply(function () {
        if (callback) {
          callback.apply(socket.connectedSocket, args);
        }
      });
    })
  };

  this.disconnect = function() {
    if(socket.connectedSocket) {
      socket.connectedSocket.close();
    }
  }

});
