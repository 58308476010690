angular.module('app').service('orders', function($http, $q, cases) {

  this.editCase = false
  this.newOrder = false

  function convertDashboardDates(payload){
    payload.data.orders.map(order => {
      order.sortable_created_at = order.created_at
      const createdDate = order.created_at ? moment.utc(order.created_at).format('ll') : null
      order.created_at = createdDate

      order.sortable_date_of_death = order.case.date_of_death
      const deathDate = order.case.date_of_death ? moment.utc(order.case.date_of_death).format('ll') : null
      order.case.date_of_death = deathDate
      return order
    })

    return payload
  }

  this.getFuneralHomeOrders = function(archived, page) {
    const deferred = $q.defer();
    $http.get(api + `funeralHomeOrders?archived=${archived}&page=${page}`)
      .then(response => {
      deferred.resolve(convertDashboardDates(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.searchFuneralHomeOrders = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `searchFuneralHomeOrders`, payload )
      .then(response => {
      deferred.resolve(convertDashboardDates(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'createOrder', payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.generatePDF = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'generatePDF', payload, {responseType: 'arraybuffer'})
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `orders/${id}`, payload )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getOrderById = function(id) {
    const deferred = $q.defer();
    $http.get(api + `orders/${id}` )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.archiveOrder = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `archiveOrder/${id}` )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.unarchiveOrder = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `unarchiveOrder/${id}` )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.duplicateOrder = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `duplicateOrder`, payload)
      .then(response => {
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.generateJPEG = async payload => {
    const post = $http.post(api + 'generateJPEG', payload)
    try { return await post } catch (err) { throw err }
  }

})
