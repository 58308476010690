angular.module("app", [
  "ngRoute",
  "ui.bootstrap",
  "ngAnimate",
  "ui.checkbox",
  'slick',
  "angularSpectrumColorpicker",
  "ui.date",
  "angular-urlsafe-base64"
]);

angular.module("app").config(function($routeProvider, $locationProvider) {
  $locationProvider.html5Mode({
    enabled: true
  });
  $routeProvider
    .when("/", {
      template: "<landing></landing>"
    })
    .when("/login", {
      template: "<login></login>"
    })
    .when("/signup", {
      template: "<signup></signup>"
    })
    .when("/reset/:id", {
      template: "<reset></reset>"
    })
    .when("/welcome", {
      template: "<welcome></welcome>"
    })
    .when("/dashboard", {
      template: "<dashboard></dashboard>"
    })
    .when("/order", {
      template: "<order></order>"
    })
    .when("/order/:orderId/:caseId", {
      template: "<order></order>"
    })
    .when("/order/:orderId", {
      template: "<order></order>"
    })
    .when("/settings", {
      template: "<settings></settings>"
    })
    .when("/staff/:id", {
      template: "<staff></staff>"
    })
    .when("/support", {
      template: "<support></support>"
    })
    .when("/style-guide", {
      template: "<style-guide></style-guide>"
    })
    .when("/editor/:orderid/:order_page_sets_id", {
      template: "<editor></editor>"
    })
    .when("/customThemes", {
      template: "<custom-themes-dashboard></custom-themes-dashboard>"
    })
    .when("/editCustomTheme/:id", {
      template: "<custom-theme></custom-theme>"
    })
    .when("/editCustomTheme", {
      template: "<custom-theme></custom-theme>"
    })
    .when("/labels", {
      template: "<labels></labels>"
    })
    .when("/familyUpload/:token/:caseId", {
      template: "<family-upload></family-upload>"
    })
    .when("/floralpix-privacy-policy", {
      template: "<privacy-policy></privacy-policy>"
    })
    .when("/drbLanding", {
      template: "<drb-landing></drb-landing>"
    })
    .when("/drbPortal", {
      template: "<drb-portal></drb-portal>"
    })
    .when("/drbReturn", {
      template: "<drb-return></drb-return>"
    })
    .when("/envelopeLabels/:id", {
      template: "<envelope-labels></envelope-labels>"
    })
    .when("/site-maintenance", {
      //template: "<site-maintenance></site-maintenance>"
      redirectTo: "/login" 
    })
    .otherwise({
      redirectTo: "/login" 
      // redirectTo: "/site-maintenance" 
    });
});

angular.module("app").run(function($http, $rootScope, $location) {
  if (localStorage.getItem("token")) {
    $http.defaults.headers.common["x-auth-token"] = localStorage.getItem(
      "token"
    );
  }
});

let api;
let isLocal;
/* 
  If you are adding logic to determine which api you wish to use for a testing site, add only FULL URLS. We need to avoid adding any type of logic that might be interpreted by the browser on production, which includes and kind of `indexOf` or `includes` that directs to only partial language.
  for example:

  window.location.hostname.indexOf('theme') >= 0

  This would catch the themes subpages and change their associated API.

  Instead:

  window.location.hostname.indexOf('themeTest--dev-dps.netlify.com') >= 0 

  The above would capture only the intended target URL.

  Ideally, before submitting PR the exception URL will be removed from this logic.
*/
if (window.location.hostname == "localhost") {
  isLocal = true;
  // api = "https://drb-test.herokuapp.com/";
  // api = "https://drb-dev.herokuapp.com/";
  // api = "https://dps-develop.herokuapp.com/";
  // api = "https://dps-int-test.herokuapp.com/";
  api = "https://api.directorsprintsuite.com/";
  // api = "//localhost:8000/"
} else if (window.location.href.indexOf("dev-dps.netlify") >= 0) {
  // api = "https://dps-develop.herokuapp.com/";
  api = "https://dps-int-test.herokuapp.com/";
} else if (window.location.href.indexOf("dps-int-test.netlify") >= 0) {
  api = "https://dps-int-test.herokuapp.com/";
} else if (window.location.href.indexOf("dev.directorsprintsuite") >= 0) {
  api = "https://dps-develop.herokuapp.com/";
} else if (window.location.href.indexOf("test.directorsprintsuite") >= 0) {
  api = "https://dps-int-test.herokuapp.com/";
} else {
  api = "https://api.directorsprintsuite.com/";
  //prefer api.directorsprintsuite.com unless something is wrong with the api
  // api = "https://dps-prod.herokuapp.com/";
}

let isBETA
if(window.location.href.indexOf("beta") >= 0) {
  isBETA = true
}
console.log(
  `%c api = ${api}`,
  "color: gray; font-weight: bold; font-style: italic"
);

let isBeta;
if (window.location.href.indexOf("beta") >= 0) {
  isBeta = true;
}
if (window.location.href.indexOf("localhost") >= 0) {
  isBeta = true;
}

// please note,
// that IE11 now returns undefined again for window.chrome
// and new Opera 30 outputs true for window.chrome
// but needs to check if window.opr is not undefined
// and new IE Edge outputs to true now for window.chrome
// and if not iOS Chrome check
// so use the below updated condition
let isChrome = false;
const isChromium = window.chrome;
const winNav = window.navigator;
const vendorName = winNav.vendor;
const isOpera = typeof window.opr !== "undefined";
const isIEedge = winNav.userAgent.indexOf("Edge") > -1;
const isIOSChrome = winNav.userAgent.match("CriOS");

if (isIOSChrome) {
  // is Google Chrome on IOS
  isChrome = true;
} else if (
  isChromium !== null &&
  typeof isChromium !== "undefined" &&
  vendorName === "Google Inc." &&
  isOpera === false &&
  isIEedge === false
) {
  // is Google Chrome
  isChrome = true;
} else {
  // not Google Chrome
  isChrome = false;
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

$.fn.inlineStyle = function(prop) {
  var styles = this.attr("style"),
    value;
  styles &&
    styles.split(";").forEach(function(e) {
      var style = e.split(":");
      if ($.trim(style[0]) === prop) {
        value = style[1];
      }
    });
  return value;
};

$.fn.getCursorPosition = function() {
  var input = this.get(0);
  if (!input) return; // No (input) element found
  if ("selectionStart" in input) {
    // Standard-compliant browsers
    return input.selectionStart;
  } else if (document.selection) {
    // IE
    input.focus();
    var sel = document.selection.createRange();
    var selLen = document.selection.createRange().text.length;
    sel.moveStart("character", -input.value.length);
    return sel.text.length - selLen;
  }
};

//Polyfills

// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    if (typeof start !== "number") {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

if (!String.prototype.splice) {
  /**
   * {JSDoc}
   *
   * The splice() method changes the content of a string by removing a range of
   * characters and/or adding new characters.
   *
   * @this {String}
   * @param {number} start Index at which to start changing the string.
   * @param {number} delCount An integer indicating the number of old chars to remove.
   * @param {string} newSubStr The String that is spliced in.
   * @return {string} A new string with the spliced substring.
   */
  String.prototype.splice = function(start, delCount, newSubStr) {
    return (
      this.slice(0, start) + newSubStr + this.slice(start + Math.abs(delCount))
    );
  };
}

if (!Array.prototype.find) {
  console.log("Find Polyfill Running");
  Object.defineProperty(Array.prototype, "find", {
    value: function(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== "function") {
        throw new TypeError("predicate must be a function");
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    }
  });
}