(function() {
	'use strict'
	angular.module('app').directive('customThemesDashboard', customThemesDashboardController)

	function customThemesDashboardController(customThemes, themes, $q, $routeParams, $rootScope, $location, $timeout) {
		return {

			templateUrl: 'pages/custom-themes/custom-themes-dashboard.html',
			controllerAs: 'vm',
			controller: function() {
				var vm = this

				angular.extend(vm, {
					category: {},
					archiveTheme: archiveTheme,
					initiateTheme: initiateTheme,
					showLegal: showLegal,
				})

				getCustomThemes()
				//prevent static modal backdrop from themeNew from persisting.
				$('.modal-backdrop').remove();

				function getCustomThemes() {
					customThemes.getCustomThemes().then(response => {
						vm.themes = response.data
					})
				}

				function archiveTheme(theme) {
					if (window.confirm('Are you sure you want to delete this theme?')) {
						customThemes.archiveCustomTheme(theme.id).then(() => {
							vm.themes = _.remove(vm.themes, function(index) {
								return index !== theme
							})
						})
					}
				}

				function showLegal() {
					$('#rights-modal').modal('show')
				}

				function initiateTheme() {
					$('#rights-modal').modal('hide')
					$timeout(function(){
						$location.path(`/editCustomTheme`)
					}, 250)

				}

				//******** end *********//
			}
		}
	}

})();
