angular.module('app').config(['$httpProvider', function($httpProvider) {
  $httpProvider.interceptors.push(function($q, $location) {
    var service = {
        responseError: responseError
    };

    return service;

    function responseError(rejection) {
        if (rejection.status === 401) {
          if($location.url() !== '/login'){
            window.location.href = '/login'
            rejection.handled = true;
          } else {
            rejection.handled = true;
          }
        }

        return $q.reject(rejection);
    }
  });
}]);
