(function() {

	angular.module('app').directive('labels', labelsController)

	function labelsController(orders, cases, $q, tukios, user, $window, $timeout, helpers) {
		return {

			templateUrl: 'pages/labels/labels.html',
      scope: true,
			link: function(scope) {
				var vm = scope

				angular.extend(scope, {
          orders: null,
          loading: true,
					selectedFont: 11,
					selectedWeight: null,
          selectWeight: selectWeight,
          updateFontSize: updateFontSize,
					backToCases: backToCases,
          updateFont: updateFont,
          fontSize: 14,
          deceasedNames: [],
          fonts: [],
          year: null,
          present: null,
          printProduct: printProduct,
          printing: false,
          dateOptions: {
            changeYear: true,
            changeMonth: true,
            yearRange: ""
          },
					filter: {
						startDate: '',
						endDate: '',
						includeArchived: false
					},
					selection: 'name',
					dateStyle: 'longDate',
					updateSelection: updateSelection,
					getCases: getCases
        })

        init()

				function init() {
          getCases()
					getYear()
				}

				//this function updates the year range to include the current calendar year instead of using a preset range.
        function getYear() {
          var year = new Date().getFullYear()
          vm.dateOptions.yearRange = '1900:' + year
        }

        function getCases(filterValues) {

					if(!filterValues){
						vm.year = moment().year()
						vm.present = moment()

						vm.dateRange = vm.year + '-01-01'
						vm.filter.startDate = moment(vm.dateRange).calendar()
						vm.filter.endDate = moment(vm.present).format("MM, DD, YYYY")
					}

          var deferred = $q.defer()
					vm.loading = true
					vm.deceasedNames = []

          vm.filter.startDate = new Date(vm.filter.startDate).toISOString()
          vm.filter.endDate = new Date(vm.filter.endDate).toISOString()

					const payload = {
						date_range_start: vm.filter.startDate,
						date_range_end: vm.filter.endDate
					}

					cases.getCasesForLabels(payload).then(response => {
						vm.availableCases = response.data

						let page = 42
						let pageNames = []
						let count = 0
						for (let field in vm.availableCases){
							if(vm.availableCases[field].date_of_death) {
									if(vm.availableCases[field].name_of_deceased){
										if(vm.filter.includeArchived) {
											pageNames.push(vm.availableCases[field])
										 count ++
									 } else {
										 if(!vm.availableCases[field].archived) {
											 pageNames.push(vm.availableCases[field])
										 count ++
										 }
									 }
									}
							}
							if(count == page || field == vm.availableCases.length -1){
								vm.deceasedNames.push(pageNames)
								pageNames = []
								page = page + 42
							}
						}

						for (let name in vm.deceasedNames) {
							if(name == vm.deceasedNames.length -1) {
								while(vm.deceasedNames[name].length < 42) {
									vm.deceasedNames[name].push(' ')
								}
							}
						}

						deferred.resolve(response.data)
					})

          vm.loading = false
					return deferred.promise
        }

				function updateSelection() {

					if(vm.selection == 'name') {
						var obj = $('.outlines .name')
						vm.fontSize = parseInt(obj.css('font-size'))
						vm.selectedFont = null

					} else {
						obj = $('.outlines .labelDate')
						vm.fontSize = parseInt(obj.css('font-size'))
						vm.selectedFont = null

					}
				}

        function updateFont(index) {

					if(vm.selection == 'name') {
						var obj = $('.outlines .name')
					} else {
						obj = $('.outlines .labelDate')
					}
          obj.each(function() {
              $(this).css('font-family', vm.fonts[index].name)
              vm.selectedFont = index
              if (vm.fonts[index].weights) {
                if (vm.selectedWeight) {
                  $(this).css('font-weight', vm.fonts[index].weights[vm.selectedWeight].weight)
                } else {
                  $(this).css('font-weight', vm.fonts[index].weights[0].weight)
                  vm.selectedWeight = 0
                }
              } else {
                $(this).css('font-weight', 'normal')
              }
            })
        }

        function selectWeight(index) {
          vm.selectedWeight = index
          updateFont(vm.selectedFont)
        }

        function updateFontSize() {

					if(vm.selection == 'name') {
						var obj = $('.outlines .name')
					} else {
						obj = $('.outlines .labelDate')
					}
          obj.each(function() {
            $(this).css('font-size', vm.fontSize + 'px')
          })
        }

        function printProduct() {
          vm.printing = true

					$('.outlines p').each(function(){
						if($(this).css('font-family') == '"Century Gothic"'){
							$(this).css({
								'-webkit-transform':'scale(0.98)',
								'-moz-transform':'scale(0.98)',
								'-ms-transform':'scale(0.98)',
								'transform':'scale(0.98)'
							})
						}
					})
					$('.outlines').css('border', '')
					$('.outlines').css('position', 'fixed')
					$('#productBuild .page').css('display', 'inherit')

					var html = $('#productBuild').html()

					var code = prepCode(html)
					generatePdf(code, true)

         $('.outlines').css('position', 'inherit')

					function prepCode(html){
						var build = `${helpers.printHeader}${html}${helpers.printStyle}${helpers.printFooter}`

						build = build.replace(/elastic/g, '')
						var sizes = [8.5, 11]

						var product = {
							html: build,
							page_height: sizes[1],
							page_width: sizes[0]
						}
						product.html = product.html.replace(/transform:/g,'-webkit-transform:')
            vm.printing = false
						return product
					}

					function generatePdf(product, isLastPdf){
						orders.generatePDF(product).then(function(response){

							var file = new Blob([response.data], {
								type: 'application/pdf'
							});
							var fileURL
							if(window.navigator.msSaveOrOpenBlob) {
								window.navigator.msSaveOrOpenBlob(file, 'product.pdf');
							} else {
								fileURL = URL.createObjectURL(file);
								var openWindow = window.open(fileURL);
								try {
									openWindow.focus()
								}
								catch(err) {
									$('#popup-modal').modal('show')
								}
							}
						})
          }
				}

        function backToCases() {
          window.location.href = '/dashboard/'
        }

        vm.fonts = helpers.fonts

      }
    }
  }
})();
