angular.module('app').service('funeralHomeDefaultLayouts', function($http, $q) {

  this.get = function() {
    const deferred = $q.defer();
    $http.get(api + `funeralHomeDefaultLayouts`)
      .then(response => {
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'createFuneralHomeDefaultLayout', payload)
      .then(response => {
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.delete = function(id) {
    const deferred = $q.defer();
    $http.delete(api + `funeralHomeDefaultLayouts/${id}`)
      .then(response => {
        deferred.resolve(response)
      }).catch(error => {
        deferred.resolve(error)
      })
      return deferred.promise
  }
  // **** end **** //
})
