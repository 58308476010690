angular.module('app').service('assets', function($http, $q) {

  this.getAssetCategories = function(subcategory_id) {
    var deferred = $q.defer();
    $http.get(api + `assetCategories`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getAssetsByCategory = function(category_id) {
    var deferred = $q.defer();
    $http.get(api + `assetSubcategories?category_id=${category_id}&get_all_assets=true`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.searchAssets = function(name) {
    var deferred = $q.defer();
    $http.get(api + `assets?name=${name}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
