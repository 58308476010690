angular.module('app').service('funeralHomeLogo', function($http, $q) {

  this.logos = null

  this.createLogo = function(size, file) {
    const deferred = $q.defer();
    var config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.post(api + `createFuneralHomeLogo?size=${size}`, file, config)
      .then((response) => {
        this.logos = response.data
        deferred.resolve(response)
      }).catch((error) => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.updateLogo = function(size, id, file) {
    const deferred = $q.defer();
    var config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.patch(api + `funeralHomeLogos/${id}?size=${size}`, file, config)
      .then((response) => {
        this.logos = response.data
        deferred.resolve(response)
      }).catch((error) => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

  this.getLogos = function() {
    const deferred = $q.defer();
    var config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.get(api + `funeralHomeLogos`)
      .then((response) => {
        this.logos = response.data
        deferred.resolve(response)
      }).catch((error) => {
        deferred.resolve(error)
      })
      return deferred.promise
  }

})
