angular.module('app').service('convertImage', function($http, $q, $base64, imageService, helpers) {

  this.convertOpacity = function(object, large){
    let urlPath = ''
    let urlFile = ''
    let transparent = large ? helpers.transparentLarge : helpers.transparent
    if(!$(object)[0].hasAttribute('src')) {
      if($(object).css("background-image").indexOf('imgix') != -1) {
        const urlBase = $(object)
          .css("background-image")
          .replace("url(", "")
          .replace(")", "")
          .replace(/\"/gi, "")
          .split('.net/')

        urlPath = urlBase.shift() + '.net/'
        urlFile = urlBase.join('.net/')

      } else if($(object).css("background-image").indexOf('amazon') != -1){

        const urlBase = $(object)
          .css("background-image")
          .replace("url(", "")
          .replace(")", "")
          .replace(/\"/gi, "")
          .split('/')

        urlFile = urlBase.pop()
        urlPath = urlBase.join('/') + '/'
      }
    } else if($(object)[0].hasAttribute('src')) {
      let src = $(object).attr('src')
      if(src.indexOf('imgix') != -1) {
        const urlBase = $(object)
          .attr('src')
          .replace(/\"/gi, "")
          .split('.net/')

        urlPath = urlBase.shift() + '.net/'
        urlFile = urlBase.join('.net/')
      } else if(src.indexOf('amazon') != -1) {
          const urlBase = $(object)
            .attr('src')
            .replace(/\"/gi, "")
            .split('/')

          urlFile = urlBase.pop()
          urlPath = urlBase.join('/') + '/'
      }
    }

    //Set Border & radius
    let border;
    let radius;
    let img = new Image();
    img.src = urlPath + urlFile
    img.onload = function() {
      let imgRatio = this.width / this.height;
      let imageHeight = this.height;
      let imageWidth = this.width;

      $("<img/>")
        .attr(
          "src",
          $(object)
          .css("background-image")
          .replace("url(", "")
          .replace(")", "")
          .replace(/\"/gi, "")
        )
        .load(function() {
          //Prevent memory leak

          $(this).remove();

          let width = parseInt($(object).css("width"));
          let height = parseInt($(object).css("height"));
          let containRatio = width / height;

          //Get other Values
          let bg = urlFile

          let actwidth = parseInt($(object).css("width"));
          let actheight = parseInt($(object).css("height"));

          bg = bg.replace(/(&fm=png)/gi, "");
          bg = bg.replace(/(&auto=compres)s/gi, "");
          bg = bg.replace(
            /(&mask=https:\/\/s3-us-west-2\.amazonaws\.com\/bassmollett-uploads\/squarefeatherbox\.png)/gi,
            ""
          );
          bg = bg.replace(/(&mask=squarefeatherbox\.png)/gi, "");

          if (bg.indexOf("&bw=") != -1) {
            let bw = bg.split("&bw=")[1];
            bw = parseInt(bw.substr(0, 4));
            bg = bg.split("&bw=" + bw).join("&bw=" + width);
          }
          if (bg.indexOf("&bh=") != -1) {
            let bh = bg.split("&bh=")[1];
            bh = parseInt(bh.substr(0, 4));
            bg = bg.split("&bh=" + bh).join("&bh=" + height);
          }

          let opacity = $(object).css("opacity") * 100;

          if (bg.indexOf("?") != -1) {
            bg = bg + "&fm=png&";
          } else {
            bg = bg + "?fm=png&";
          }

          //if image is opaque and No Feather
          if (opacity === 100 && !$(object).hasClass("feathered") && !$(object)[0].hasAttribute("data-feathered")) {
            $(object).css("background-color", "rgba(0,0,0,0)");
            $(object).css({
              "background-image": "url(" + urlPath + bg + ")", // bd + rd
              border: "",
              opacity: "",
              "background-repeat": "no-repeat",
            });
          } else if ($(object).hasClass("feathered")  || $(object)[0].hasAttribute("data-feathered")) {
            //If image is feathered
            let opaqueBg = urlFile
            opaqueBg = opaqueBg.replace(/(&fm=png)/gi, "");
            opaqueBg = opaqueBg.replace(/(&auto=compres)s/gi, "");
            opaqueBg = opaqueBg.replace(
              /(&mask=https:\/\/s3-us-west-2\.amazonaws\.com\/bassmollett-uploads\/squarefeatherbox\.png)/gi,
              ""
            );
            opaqueBg = opaqueBg.replace(
              /(&mask=squarefeatherbox\.png)/gi,
              ""
            );
            if (opaqueBg.indexOf("?") != -1) {
              opaqueBg = opaqueBg + "&fm=png&";
            } else {
              opaqueBg = opaqueBg + "?fm=png&";
            }
            let base = $base64.urlsafe_encode(
              urlPath + opaqueBg + "?w=" + imageWidth + "&h=" + imageHeight
            );
            let url = `url(${transparent}?w=${imageWidth}&h=${imageHeight}&fit=scale&mark64=${base}&markalpha=${opacity}&markw=${imageWidth}&markh=${imageHeight}&markx=0&marky=0)`;

            $(object).css({
              "background-image": url,
              "background-size": "100%",
              "background-repeat": "no-repeat",
              opacity: ""
            });
          } else if (opacity !== 100) {
            //if image is not feathered but is not fully opaque
            bg = urlPath + bg + "&fm=png";
            var base = $base64.urlsafe_encode(bg);
            if ($(object).hasClass("theme")) {
              var url =  `url(${transparent}?w=${imageWidth}&h=${imageHeight}&fit=scale&mark64=${base}&markalpha=${opacity}&markw=${imageWidth}&markh=${imageHeight}&markx=0&marky=0&markfit=scale)`

            } else {
              var url = `url(${transparent}?w=${width}&h=${height}&fit=scale&mark64=${base}&markalpha=${opacity}&markw=${width}&markh=${height}&markx=0&marky=0)`

            }
            $(object).css({
              "background-image": url,
              opacity: "",
              "background-color": "transparent",
              border: "",
              "background-repeat": "no-repeat",
            });
          }
        });
    };
  }

  this.convertImageOpacity = function(object){
    var item = $(object);
    $(object).on("load", function() {
      var width = parseInt(item.css("width"));
      var height = parseInt(item.css("height"));
      var opacity = item.css("opacity") * 100;
      var bg = item.attr("src");
      var newSrc = `${helpers.transparent}?w?w=${width}&h=${height}&fit=scale&mark=${bg}&markalpha=${opacity}&markw=${width}&markh=${height}&markx=0&marky=0)`;
      item.attr("src", newSrc);
    });
  }

  this.convertWatermark = function(object) {
    let width = object.width;
    let height = object.height;
    let opacity = parseFloat(object.opacity) * 100;
    let bg = `${helpers.imgixTheme}${object.image}`;
    let base = $base64.urlsafe_encode(bg);
    let url = `${helpers.transparent}?w=${width}&h=${height}&fit=scale&mark64=${base}&markalpha=${opacity}&markw=${width}&markh=${height}&markx=0&marky=0`

    return url;
  }
})
