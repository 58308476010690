angular.module("app").service("customThemes", function($http, $q) {
  const service = this;

  // ***** THEME ENDPOINTS *****

  this.getCustomThemes = function() {
    const deferred = $q.defer();
    $http
      .get(api + `themes?custom_themes=true`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getCustomThemeById = function(id) {
    const deferred = $q.defer();
    $http.get(api + `themes/${id}?custom=true`)
      .then(response => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createCustomTheme = function(payload) {
    const deferred = $q.defer();
    $http
      .post(api + "createCustomTheme", payload)
      .then(response => {        
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateCustomTheme = function(id, payload) {
    const deferred = $q.defer();
    delete payload.archived
    $http
      .patch(api + `themes/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveCustomTheme = function(id) {
    const deferred = $q.defer();
    $http
      .patch(api + `archiveTheme/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.uploadThemeImage = function(id, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + `uploadThemeImage/${id}`, payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // ***** PRODUCT ENDPOINTS *****

  this.getProductsForCustomThemes = function(collection) {
    const deferred = $q.defer();
    $http
      .get(api + "products?custom_themes=true")
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // ***** LAYOUT ENDPOINTS *****

  this.layoutsForCustomThemes = function(product_id, theme_id) {
    const deferred = $q.defer();
    $http
      .get(api + `layoutsForCustomTheme?product_id=${product_id}&theme_id=${theme_id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.uploadReplacementImage = function(layout_id, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + `uploadReplacementImage/${layout_id}`, payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateThemeLayout = function(id, payload) {
    const deferred = $q.defer();
    $http
      .patch(api + `themeLayouts/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  // ***** WATERMARK ENDPOINTS *****

  this.createWatermark = function(payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        "Content-Type": undefined
      }
    };
    $http
      .post(api + 'createWatermark', payload, config)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.updateWatermark = function(id, payload) {
    const deferred = $q.defer();
    $http
      .patch(api + `watermarks/${id}`, payload)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.getWatermark = function(id) {
    const deferred = $q.defer();
    $http
      .get(api + `watermarks/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };

  this.archiveWatermark = function(id) {
    const deferred = $q.defer();
    $http
      .patch(api + `archiveWatermark/${id}`)
      .then(response => {
        deferred.resolve(response);
      })
      .catch(error => {
        deferred.resolve(error);
      });
    return deferred.promise;
  };
});
