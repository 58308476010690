(function() {

  angular.module('app').directive('staff', staffController)

  function staffController(user, $routeParams) {
    return {

      templateUrl: 'pages/staff/staff.html',
      controllerAs: 'vm',
      controller: function() {
        var vm = this

        angular.extend(vm, {
          reset: reset,
          newPassword: null,
          repeatPassword: null,
          resetting: false,
          resetForm: resetForm
        })

        init()

        function init() {

          getUser()
        }

        function getUser() {
          var token = $routeParams.id
          user.getNewStaff(token).then(function(user){
            vm.email = user.data[0].email
            vm.name = user.data[0].name
          })
        }

        function reset(){
          if(vm.newPassword){
            if(vm.newPassword == vm.repeatPassword){
              vm.resetting = true
              vm.message = ''
              const payload = {
                token: $routeParams.id,
                password: vm.newPassword
              }

              user.resetPassword(payload).then(function(response){
                if(response.status == 200){
                  vm.resetting = false
                  vm.message = 'Password successfully reset.'
                } else if(response.data.validation) {
                  vm.resetting = false
                  vm.message = response.data.validation[0].message
                } else if(response.status == 404) {
                  vm.resetting = false
                  vm.message = "Reset token expired. Please return to the login page and follow the directions for 'Forgot Password'."
                } else {
                  vm.resetting = false
                  vm.message = 'Password failed to reset. Please try again.'
                }
                vm.newPassword = null
                vm.repeatPassword = null
              })
            } else {
              vm.message = "Passwords don't match."
            }
          } else {
            vm.message = 'Enter a valid password'
          }

        }

        function resetForm(){
          vm.message = null
        }

        //******** end *********//
      }
    }
  }

})();
