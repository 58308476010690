angular.module('app').service('drbOptions', function($http, $q) {
  
  this.getDRBOptions = async id => {
    const get = $http.get(`${api}drbOptions?order_id=${id}`)
    try { return await get} catch (err) { throw err }
  };

  this.createDRBOptions = async payload => {
    const post = $http.post(`${api}createDRBOptions`, payload)
    try { return await post} catch (err) { throw err }
  };

  this.updateDRBOptions = async (id, payload) => {
    const patch = $http.patch(`${api}drbOptions/${id}`, payload)
    try { return await patch} catch (err) { throw err }
  };

  this.archiveDRBOptions = async id => {
    const patch = $http.patch(`${api}archiveDRBOptions/${id}`)
    try { return await patch} catch (err) { throw err }
  };

  this.unarchiveDRBOptions = async (id, payload) => {
    const patch = $http.patch(`${api}unarchiveDRBOptions/${id}`)
    try { return await patch} catch (err) { throw err }
  };

});