angular.module('app').service('scroll', function($compile) {

  const scroll = this;
  let scrollWidth,  pagesScrollWidth

    this.setScrollWidth = function() {
  		$('.asset-scroll').on('mouseenter', function($event){
  			scrollWidth = $('.asset-scroll')[0].scrollWidth
				$('.scroll-assist').css('width', scrollWidth)
  		})

  		$('.scroll-track').on('mouseenter', function($event){
				pagesScrollWidth = $('.scroll-track')[0].scrollWidth
			})
    }

		this.scrollAssist = function(){
      $(".scroll-assist-container").scroll(function(){
        $(".asset-scroll")
            .scrollLeft($(".scroll-assist-container").scrollLeft());
      });
      $(".asset-scroll").scroll(function(){
        $(".scroll-assist-container")
            .scrollLeft($(".asset-scroll").scrollLeft());
      });
		};

		this.scrollRight = function() {
      $('.hover-scroll-right').mouseover(function(){
  			let maxscroll = scrollWidth
  			let speed = maxscroll * 4
  			$('.asset-scroll').animate({
  				scrollLeft: maxscroll
  			}, speed, 'linear')
  		})
    }

    this.stopScrollRight = function() {
  		$('.hover-scroll-right').mouseout(function(){
  			$('.asset-scroll').stop()
  		})
    }

    this.scrollLeft = function() {
  		$('.hover-scroll-left').mouseover(function(){
  			let maxscroll = scrollWidth
  			let speed = maxscroll * 4
  			$('.asset-scroll').animate({
  				scrollLeft: 0
  			}, speed, 'linear')
  		})
    }

    this.stopScrollLeft = function() {
  		$('.hover-scroll-left').mouseout(function(){
  			$('.asset-scroll').stop()
  		})
    }

    this.pagesScrollRight = function() {
  		$('.pages-hover-scroll-right').mouseover(function(){
				let maxscroll = pagesScrollWidth + $('#page-shuffler').scrollLeft()
				let speed = maxscroll * 4
  			$('#page-shuffler').animate({
  				scrollLeft: maxscroll
  			}, speed, 'linear');
  		});
    }

    this.stopPagesScrollRight = function() {
  		$('.pages-hover-scroll-right').mouseout(function(){
  			$('#page-shuffler').stop()
  		});
    }

    this.pagesScrollLeft = function() {
			$('.pages-hover-scroll-left').mouseover(function($event){
  			let maxscroll = $('#page-shuffler').scrollLeft()
				let speed = maxscroll * 4
  			$('#page-shuffler').animate({
  				scrollLeft: 0
  			}, speed, 'linear')
  		})
		}

    this.stopPagesScrollLeft = function() {
  		$('.pages-hover-scroll-left').mouseout(function(){
  			$('#page-shuffler').stop()
  		})
    }

})
