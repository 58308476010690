angular.module('app').service('orderLayouts', function($http, $q) {

  const service = this

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createOrderLayoutDetails`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.get = function(id) {
    const deferred = $q.defer();
    $http.get(api + `orderLayoutDetails?order_product_details_id=${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `orderLayoutDetails/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
