// ######  FILE LINKED AND CLEANED  ######

(function() {
  angular.module("app").directive("loader", loaderController);

  function loaderController() {
    return {
      templateUrl: "pages/loader/loader.html",
      controllerAs: "loader",
      controller: function() {
        //******** end *********//
      }
    };
  }
})();
