angular.module('app').service('flowers', function($http, $q) {

  this.get = function(case_id) {
    var deferred = $q.defer();
    $http.get(api + `floralArrangements?case_id=${case_id}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
