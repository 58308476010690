(function() {
	angular.module("app").directive("maintenancebanner", maintenanceBannerController);
	function maintenanceBannerController($compile, messages) {
		return {
			templateUrl: "pages/maintenance-banner/maintenanceBanner.html",
			controllerAs: "mbm",
			controller: function() {
        var vm = this;

        angular.extend(vm, {
          maintenance: false,
          startDate: new Date("2019/01/22"),
          returnDate: new Date("2019/02/22")
        })

        init()

        function init() {
        }
      }
    };
  };
})();
