angular.module('app').service('domHelpers', function($compile, dom, helpers) {

  const domHelpers = this;

  /* The range() fn generates a list of numbers from a starting point (start)
      to an ending point (stop) at a stated interval (step). */

  this.range = (start, stop, step) => Array.from({ length: (stop - start) / step }, (_, i) => start + (i * step));

  /* Generates list of line-height values for Quill. The arithmetic in the
      return statement restricts the output to a rational number with one
      decimal and returns an integer if the decimal value is 0. For some reason
      The 'stop' value needs to be > 4.1 to return the 4 integer. */
  this.lineStyles = this.range(0.5, 4.11, 0.1).map(num => {
    return num % 1 === 0 ? Number(num).toFixed(0) : Number(num).toFixed(1)
  })

  // Generates list of font-sizes for Quill.
  this.fontSizes = this.range(6, 71, 1).map(num => {
    return `${num}px`
  })

  this.deleteElement = function(selected) {
      let multiSelected = $('.multi-select')
      // var selected = vm.selected.element;
      $(multiSelected).remove()
      $(selected).remove()
      $('.tooltip').remove()
      $('.focused .resizer, .focused .rotator, .focused .deletor').css('display', 'none')
    }

  this.addShape = function() {
    return `<div ng-click="selectElement($event)" data-type="shape" style="position:absolute; top:0; left:0;  z-index:10; background: white; width:100px; height:100px;" class="draggable element shape new-shape"></div>`
  }

  this.selectPage = function(page) {
    $.each($('.printedarea'), function(index) {
      if(index == page) {
        $(this).addClass('focused')
      } else {
        $(this).removeClass('focused')
      }
    })
  }

  this.saveDOMReset = async function() {
    $('.outlines').css({
      width: '100%',
      height: '100%'
    })
    await helpers.applyWhenElementExists($('.ql-toolbar'), this.hideQuillElements, 50, 40)
  }

  this.hideQuillElements = function() {
    $('.ql-tooltip').css('display', 'none')
    $('.ql-toolbar').css('display', 'none')
    $('.ql-clipboard').css('display', 'none')
    $('.quill').removeClass('double-clicked')
    $('.oldQuill').removeClass('double-clicked')
    $('.element').removeClass('double-clicked')
    $('div.tooltip').remove()
  }

  this.disableQuillDOM = function(quillEl) {
    $('.ql-toolbar').remove()
    $('.imgOverlay').hide()

    $(quillEl).addClass('oldQuill draggable element')
    $(quillEl).removeClass('quill double-clicked')

    $('.oldQuill').attr('uib-tooltip', 'Double-Click to Edit')
    $('.oldQuill').attr('tooltip-placement', 'top')
    $('.oldQuill').attr('tooltip-trigger', 'outsideClick')

    $('#editor .printedarea').css('overflow', 'hidden')
    $('#editor .outlines').css('overflow', 'hidden')

  }

  this.dragAlign = function( target, alignItem, snap ) {

    /*--------------------------------------------------------------------

    dragAlign will create dotted lines indicating the center points of
    a product when an element is being dragged. It will also snap elements
    into place if the center of the element is dragged close enough
    to the line.

    ---------------------------------------------------------------------*/

    // imports the grid points
    const alignments = alignItem(target)

    // determines the target element's center points
    const targetCenter     = target.offsetLeft + (target.offsetWidth  / 2)
    const targetHorizontal = target.offsetTop  + (target.offsetHeight / 2)
    
    // declares the grid points
    const verticalCenter   = alignments.center
    const horizontalTop    = alignments.middleTop
    const horizontalMiddle = alignments.middle
    const horizontalBottom = alignments.middleBottom

    const drawGridLine = data => {

      // destructs variables
      const { snapLine, orientation, appendClass } = data
      const { position, top, width, height, left, borderLeft, borderBottom, snapColor } = data

      // determines how close an element needs to be to the center of a grid point before the line shows up
      const nearDistance = orientation <= (snapLine + 25) && orientation >= (snapLine - 25)
      const snapDistance = orientation <= (snapLine + 8 ) && orientation >= (snapLine - 8 )

      // function that builds a dotted line at a grid point
      if ( nearDistance ) {
        $('.focused .outlines').append(`<div class="${appendClass}"></div>`)
        $(`.${appendClass}`).css({
          'position'      : position,
          'top'           : top,
          'width'         : width,
          'height'        : height,
          'left'          : left,
          'border-left'   : borderLeft,
          'border-bottom' : borderBottom
         })
        snapDistance && $(`.${appendClass}`).css({ 'border-color': snapColor })
      } else {
        $(`.${appendClass}`).remove()
      }
    }

    // draws a vertical line for a single selected element
    drawGridLine({
      snapLine     : verticalCenter,
      orientation  : targetCenter,
      appendClass  : 'alignCenter',
      position     : 'absolute',
      top          : 0,
      width        : 0,
      height       : '100%',
      left         : verticalCenter,
      borderLeft   : '2px dashed limegreen',
      snapColor    : 'lime'
    })

    // draws a horizontal line for a single selected element
    if (!$(target).hasClass('multi-select')) {

      const lines = [
        { top: '25%', snapLine: horizontalTop,    appendClass: 'alignMiddleTop'    },
        { top: '50%', snapLine: horizontalMiddle, appendClass: 'alignMiddle'       },
        { top: '75%', snapLine: horizontalBottom, appendClass: 'alignMiddleBottom' }
      ]

      lines.map(line => drawGridLine({
        snapLine     : line.snapLine,
        orientation  : targetHorizontal,
        appendClass  : line.appendClass,
        position     : 'absolute',
        top          : line.top,
        width        : '100%',
        height       : 0,
        borderBottom : '2px dashed deepskyblue',
        snapColor    : 'aqua',
      }))

    }

    // function to snap an element to a line
    const snapToLine = ( orientation, alignment, position, dimensions ) => {
      ((orientation <= (position + 8)) && (orientation >= (position - 8))) && snap
        && $(target).css({ [alignment]: position - (dimensions / 2) + 'px' })
    }

    // snaps a single selected element to a vertical line
    snapToLine( targetCenter, 'left', verticalCenter, target.offsetWidth )

    // snaps a single selected element to a horizontal line
    if (!$(target).hasClass('multi-select')) {
      snapToLine( targetHorizontal, 'top', horizontalTop,    target.offsetHeight )
      snapToLine( targetHorizontal, 'top', horizontalMiddle, target.offsetHeight )
      snapToLine( targetHorizontal, 'top', horizontalBottom, target.offsetHeight )
    }

    dom.positionResizer()
    dom.positionRotator()
    dom.positionDelete()

  }

  this.disableTop = function() {
    //this will diable functions on the 'top' or main layer of the product
    //remove the class that allows elemts to be dragged, replace with a class that acts as a placeholder
    $('.product .draggable').addClass('selectable')
    $('.product .selectable').removeClass('draggable element selected resizer-target rotator-target deletor-target')
    $('.product .oldQuill').addClass('addQuill')
    $('.product .addQuill').removeClass('oldQuill')
    //fade out the elements on the page by applying a filter, this will not impact styles added using the action bar
    $('.product .selectable').css({
      'filter': 'grayscale(75%) opacity(.50)',

    })
    //remove icons from the page
    $('.focused .resizer').css({
      display: 'none'
    })
    $('.focused .rotator').css({
      display: 'none'
    })
    $('.focused .deletor').css({
      display: 'none'
    })
  }

  this.disableWatermark = function() {
    //this will disable the watermark layer
    //remove watermark targetors and icons, as well as click functions
    $('.theme img').removeClass('resizer-target deletor-target watermarkDraggable')
    $('.theme img').removeAttr('ng-click')
    $('.watermarkResizor, .watermarkDeletor').css({
      'visbility': 'hidden'
    })
    dom.positionWatermarkLayerResizer()
    dom.positionWatermarkLayerDelete()
    $('.theme .watermark').css({
      'filter': 'opacity(.5) grayscale(75%)',
      'z-index': '',
      'outline': ''
    })
  }

  this.getTopLayer = function() {
    dom.changeOutlines('red')
    //change button highlights
    $('.asset-layer').removeClass('active');
    $('#top-layer').addClass('active');
    //return all elements to draggable class and remove grayscale and opacity
    $('.product .selectable').addClass('draggable element')
    $('.product .draggable').removeClass('selectable')
    $('.product .addQuill').addClass('oldQuill')
    $('.product .oldQuill').removeClass('addQuill')
    $('.printedarea').removeClass('bgdraggable')
    $('.product .draggable').css({
      'filter': ''
    })
    $('.watermark').css({
      'filter': ''
    })
  }

  this.getWatermarkLayer = function() {
    dom.changeOutlines('limegreen')
    //change button highlights
    $('.asset-layer').removeClass('active');
    $('#watermark-layer').addClass('active');

    //move watermarks to the top
    $('.watermark').css({
      'z-index': '100',
      'filter': ''
    })
    //add click handler to watermarks
    $('.theme img').attr('ng-click', 'selectWatermark($event)')
    //ensure the click handler binds to the elements
    $('#first-watermark').replaceWith($('#first-watermark'))
    $('#second-watermark').replaceWith($('#second-watermark'))
  }

  this.setInlineLineHeight = function() {
    $('.ql-editor').each(function(){
      $(this).find('*').each(function(){
        if($(this).hasClass('ql-line-height-0-5em')) {
          $(this).removeClass('ql-line-height-0-5em')
          $(this).css('line-height', '0.5')
        }
        if($(this).hasClass('ql-line-height--0-6em')) {
          $(this).removeClass('ql-line-height-0-6em')
          $(this).css('line-height', '0.6')
        }
        if($(this).hasClass('ql-line-height-0-7em')) {
          $(this).removeClass('ql-line-height-0-7em')
          $(this).css('line-height', '0.7')
        }
        if($(this).hasClass('ql-line-height-0-8em')) {
          $(this).removeClass('ql-line-height-0-8em')
          $(this).css('line-height', '0.8')
        }
        if($(this).hasClass('ql-line-height-0-9em')) {
          $(this).removeClass('ql-line-height-0-9em')
          $(this).css('line-height', '0.9')
        }
        if($(this).hasClass('ql-line-height-1em')) {
          $(this).removeClass('ql-line-height-1em')
          $(this).css('line-height', '1')
        }
        if($(this).hasClass('ql-line-height-1-1em')) {
          $(this).removeClass('ql-line-height-1-1em')
          $(this).css('line-height', '1.1')
        }
        if($(this).hasClass('ql-line-height-1-2em')) {
          $(this).removeClass('ql-line-height-1-2em')
          $(this).css('line-height', '1.2')
        }
        if($(this).hasClass('ql-line-height-1-3em')) {
          $(this).removeClass('ql-line-height-1-3em')
          $(this).css('line-height', '1.3')
        }
        if($(this).hasClass('ql-line-height-1-4em')) {
          $(this).removeClass('ql-line-height-1-4em')
          $(this).css('line-height', '1.4')
        }
        if($(this).hasClass('ql-line-height-1-5em')) {
          $(this).removeClass('ql-line-height-1-5em')
          $(this).css('line-height', '1.5')
        }
        if($(this).hasClass('ql-line-height-1-6em')) {
          $(this).removeClass('ql-line-height-1-6em')
          $(this).css('line-height', '1.6')
        }
        if($(this).hasClass('ql-line-height-1-7em')) {
          $(this).removeClass('ql-line-height-1-7em')
          $(this).css('line-height', '1.7')
        }
        if($(this).hasClass('ql-line-height-1-8em')) {
          $(this).removeClass('ql-line-height-1-8em')
          $(this).css('line-height', '1.8')
        }
        if($(this).hasClass('ql-line-height-1-9em')) {
          $(this).removeClass('ql-line-height-1-9em')
          $(this).css('line-height', '1.9')
        }
        if($(this).hasClass('ql-line-height-2em')) {
          $(this).removeClass('ql-line-height-2em')
          $(this).css('line-height', '2')
        }
        if($(this).hasClass('ql-line-height-2-1em')) {
          $(this).removeClass('ql-line-height-2-1em')
          $(this).css('line-height', '2.1')
        }
        if($(this).hasClass('ql-line-height-2-2em')) {
          $(this).removeClass('ql-line-height-2-2em')
          $(this).css('line-height', '2.2')
        }
        if($(this).hasClass('ql-line-height-2em')) {
          $(this).removeClass('ql-line-height-2em')
          $(this).css('line-height', '2')
        }
        if($(this).hasClass('ql-line-height-2-3em')) {
          $(this).removeClass('ql-line-height-2-3em')
          $(this).css('line-height', '2.3')
        }
        if($(this).hasClass('ql-line-height-2-4em')) {
          $(this).removeClass('ql-line-height-2-4em')
          $(this).css('line-height', '2.4')
        }
        if($(this).hasClass('ql-line-height-2-5em')) {
          $(this).removeClass('ql-line-height-2-5em')
          $(this).css('line-height', '2.5')
        }
        if($(this).hasClass('ql-line-height-2-6em')) {
          $(this).removeClass('ql-line-height-2-6em')
          $(this).css('line-height', '2.6')
        }
        if($(this).hasClass('ql-line-height-2-7em')) {
          $(this).removeClass('ql-line-height-2-7em')
          $(this).css('line-height', '2.7')
        }
        if($(this).hasClass('ql-line-height-2-8em')) {
          $(this).removeClass('ql-line-height-2-8em')
          $(this).css('line-height', '2.8')
        }
        if($(this).hasClass('ql-line-height-2-9em')) {
          $(this).removeClass('ql-line-height-2-9em')
          $(this).css('line-height', '2.9')
        }
        if($(this).hasClass('ql-line-height-3em')) {
          $(this).removeClass('ql-line-height-3em')
          $(this).css('line-height', '3')
        }
        if($(this).hasClass('ql-line-height-3-1em')) {
          $(this).removeClass('ql-line-height-3-1em')
          $(this).css('line-height', '3.1')
        }
        if($(this).hasClass('ql-line-height-3-2em')) {
          $(this).removeClass('ql-line-height-3-2em')
          $(this).css('line-height', '3.2')
        }
        if($(this).hasClass('ql-line-height-3-3em')) {
          $(this).removeClass('ql-line-height-3-3em')
          $(this).css('line-height', '3.3')
        }
        if($(this).hasClass('ql-line-height-3-4em')) {
          $(this).removeClass('ql-line-height-3-4em')
          $(this).css('line-height', '3.4')
        }
        if($(this).hasClass('ql-line-height-3-5em')) {
          $(this).removeClass('ql-line-height-3-5em')
          $(this).css('line-height', '3.5')
        }
        if($(this).hasClass('ql-line-height-3-6em')) {
          $(this).removeClass('ql-line-height-3-6em')
          $(this).css('line-height', '3.6')
        }
        if($(this).hasClass('ql-line-height-3-7em')) {
          $(this).removeClass('ql-line-height-3-7em')
          $(this).css('line-height', '3.7')
        }
        if($(this).hasClass('ql-line-height-3-8em')) {
          $(this).removeClass('ql-line-height-3-8em')
          $(this).css('line-height', '3.8')
        }
        if($(this).hasClass('ql-line-height-3-9em')) {
          $(this).removeClass('ql-line-height-3-9em')
          $(this).css('line-height', '3.9')
        }
        if($(this).hasClass('ql-line-height-4em')) {
          $(this).removeClass('ql-line-height-4em')
          $(this).css('line-height', '4')
        }
      })
    })
  }

})
