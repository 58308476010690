(function() {
	angular.module("app").directive("welcome", welcomeController);

	function welcomeController(user) {
		return {
			templateUrl: "pages/welcome/welcome.html",
			controllerAs: "vm",
			controller: function() {
				var vm = this;

				angular.extend(vm, {trialDays: user.trialDays});

				//******** end *********//
			}
		};
	}
})();
