angular.module('app').service('quillService', function($http, $q) {


  this.bubbleTooltips = function() {
    return new Promise(function(resolve, reject){
      $('.ql-undo').attr('tooltip-placement', 'top')
      $('.ql-undo').attr('uib-tooltip', 'Undo')
      $('.ql-redo').attr('tooltip-placement', 'top')
      $('.ql-redo').attr('uib-tooltip', 'Redo')
      $('.ql-color').attr('tooltip-placement', 'top')
      $('.ql-color').attr('uib-tooltip', 'Font Color')
      $('.ql-background').attr('tooltip-placement', 'top')
      $('.ql-background').attr('uib-tooltip', 'Background Color')
      $('.ql-weight').attr('uib-tooltip', 'Font Weight')
      $('.ql-weight').attr('tooltip-placement', 'top')
      $('.ql-image').attr('uib-tooltip', 'Add Image')
      $('.ql-image').attr('tooltip-placement', 'top')
      $('.ql-line-height').attr('uib-tooltip', 'Line Height')
      $('.ql-line-height').attr('tooltip-placement', 'top')
      $('.ql-regStyle').attr('uib-tooltip', 'Apply To All')
      $('.ql-regStyle').attr('tooltip-placement', 'top')
      $('.ql-clean').parent().attr('tooltip-placement', 'top')
      $('.ql-clean').parent().attr('uib-tooltip', 'Remove Formats')
      resolve()
    })
  };

  this.fontWeightSet = function(fonts, newInst) {

    var font = $('.ql-snow .ql-font .ql-picker-label').attr('data-value')

    var quillWeights = {}
    var font = font.replace(/(")/g, '')
    for (let i in fonts) {
      if(fonts[i].name.replace(/[' ']/g, '') == font.replace(/(")/g, '')) {
        if(fonts[i].weights){
          quillWeights.weights = true
          for (let j in fonts[i].weights) {
            if(fonts[i].weights[j].weight == 500) {
              quillWeights.normal = true
            } else if(fonts[i].weights[j].weight == 800 ) {
              quillWeights.bold = true
            } else if(fonts[i].weights[j].weight == 700) {
              quillWeights.bi = true
            } else if(fonts[i].weights[j].weight == 600) {
              quillWeights.ital = true
            }
          }
        } else {
          quillWeights = {
            weights: false,
            bold: false,
            normal: false,
            bi: false,
            ital: false
          }
        }

      }
    }
    if(newInst) {
      $('.ql-weight').attr('ng-class', "{'disabled': !quillWeights.weights}")
      $('.ql-weight .ql-picker-options').attr('ng-show', 'quillWeights.weights')
      $('.ql-weight .ql-picker-item[data-value="Normal"]').attr('ng-show', 'quillWeights.normal')
      $('.ql-weight .ql-picker-item[data-value="Bold"]').attr('ng-show', 'quillWeights.bold')
      $('.ql-weight .ql-picker-item[data-value="BoldItalic"]').attr('ng-show', 'quillWeights.bi')
      $('.ql-weight .ql-picker-item[data-value="Italic"]').attr('ng-show', 'quillWeights.ital')
    }
    return quillWeights
  };

  this.presetOptions = function() {
    var fontSize = $('.ql-editor p').css('font-size')
    var font = $('.ql-editor p').css('font-family')
    var lineHeight = $('.ql-editor p').css('line-height')
    if(lineHeight == 'normal') lineHeight = '1-2em'
    lineHeight = lineHeight.replace('.', '-')
    $('.ql-size .ql-picker-label').attr('data-value', fontSize)
    $('.ql-font-family .ql-picker-label').attr('data-value', font)
    $('.ql-line-height .ql-picker-label').attr('data-value', lineHeight)

  }

});
