angular.module("app").service("helpers", function() {

  /*
  |--------------------------------------------------------------------------
  | APPLY WHEN ELEMENT EXISTS
  |--------------------------------------------------------------------------
  | When doing DOM manipulation it is important to know whether the node exists
  |   before attempting to manipulate it. This is especially true in this
  |   applicaiton where there is so much DOM manipulation happening. This fn
  |   Takes four arguments: the jquery selector, the fn to run, how frequently
  |   the interval should run when looking for the element
  |   (optional; default = 50ms), and whether there should be a limit to how
  |   often setInterval() is run (optional; default = 200). This fn returns
  |   a promise to help prevent other dependent fns firing before the state
  |   of the element is known.
  |
  */

  this.applyWhenElementExists = (selector, myFunction, intervalTime = 50, attemptLimit = 200) => {
    return new Promise((resolve, reject) => {
      let attempts = 0
      const interval = setInterval(() => {
        if (selector.length > 0) {
           myFunction();
           clearInterval(interval);
           resolve('Element exists')
        }
        if (attempts >= attemptLimit) {
          clearInterval(interval)
          resolve('Element does not exist')
        }
        attempts++
      }, intervalTime);
    })
  }

  this.awsUploads = `https://s3-us-west-2.amazonaws.com/bassmollett-uploads/`

  this.imgixBase = `https://bassmollett.imgix.net/`

  this.imgixCase = `https://bassmollett-case.imgix.net/`

  this.imgixTheme = `https://bassmollett-theme.imgix.net/`

  this.imgixLogo = `https://bassmollett-logos.imgix.net/`

  this.imgixFlower = `https://bassmollett-floralpix.imgix.net/`

  this.squareFeather = `https://bassmollett.imgix.net/squarefeatherbox.png`

  this.circleFeather = `https://bassmollett.imgix.net/circlefeather.png`

  this.transparent = `https://bassmollett.imgix.net/transparent.png`

  this.transparentLarge = `https://bassmollett.imgix.net/transparent-large.png`

  const fonts = `
    @font-face { font-family: 'Antiqua'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ANTQUAI.TTF'); }
    @font-face { font-family: 'Goudy Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudos-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudos-webfont.woff') format('woff'); font-weight: 500; }
    @font-face { font-family: 'Goudy Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudosb-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudosb-webfont.woff') format('woff'); font-weight: 800; }
    @font-face { font-family: 'Goudy Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudosi-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/goudosi-webfont.woff') format('woff'); font-weight: 600; }
    @font-face { font-family: 'AG University'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/AGUniversity.ttf'); }
    @font-face { font-family: 'Amazone'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Amazone.TTF'); }
    @font-face { font-family: 'Century Gothic'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/CenturyGothic.TTF'); font-weight: 500; }
    @font-face { font-family: 'Century Gothic'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/CenturyGothicItalic.TTF'); font-weight: 600; } @font-face { font-family: 'Century Gothic'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/CenturyGothicBoldItalic.TTF'); font-weight: 700; }
    @font-face { font-family: 'Century Gothic'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/CenturyGothicBold.TTF'); font-weight: 800; }
    @font-face { font-family: 'English'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/English157.TTF'); }
    @font-face { font-family: 'FG Jayne'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/FGJayne.ttf'); }
    @font-face { font-family: 'Gaeilge'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Gaeilge.ttf'); }
    @font-face { font-family: 'Goudy Catalog'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/GoudyCatalog.TTF'); }
    @font-face { font-family: 'Liberty'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Liberty.TTF'); }
    @font-face { font-family: 'Mistral'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Mistral.TTF'); }
    @font-face { font-family: 'Monterey'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Monterey.TTF'); }
    @font-face { font-family: 'Murray Hill'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/MurrayHill.TTF'); }
    @font-face { font-family: 'Murray Hill'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/MurrayHillBold.TTF'); font-weight: 'Bold'; }
    @font-face { font-family: 'Nuptial'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Nuptial.TTF'); }
    @font-face { font-family: 'Papyrus'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Papyrus.TTF'); }
    @font-face { font-family: 'Park Avenue'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ParkAvenue.TTF'); }
    @font-face { font-family: 'Piranesi'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Piranesi.TTF'); }
    @font-face { font-family: 'Pristina'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Pristina.TTF'); }
    @font-face { font-family: 'Ribbon'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Ribbon131.TTF'); }
    @font-face { font-family: 'Ruthie'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Ruthie.ttf'); }
    @font-face { font-family: 'Scriptina'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Scriptina.ttf'); }
    @font-face { font-family: 'Shelley Allegro'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ShelleyAllegro.TTF'); }
    @font-face { font-family: 'Shelley Andante'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ShelleyAndante.TTF'); }
    @font-face { font-family: 'Vivaldii'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Vivaldii.TTF'); }
    @font-face { font-family: 'Wedding Text'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/WeddingText.TTF'); }
    @font-face { font-family: 'Tangerine'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Tangerine-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Tangerine'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Tangerine-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Oleo Script'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OleoScript-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Oleo Script'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OleoScript-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Merienda'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Merienda-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Merienda'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Merienda-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Lora'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Lora-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Lora'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Lora-Italic.ttf'); font-weight: 600; }
    @font-face { font-family: 'Lora'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Lora-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Lora'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Lora-BoldItalic.ttf'); font-weight: 700; }
    @font-face { font-family: 'Lobster Two'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/LobsterTwo-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Lobster Two'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/LobsterTwo-Italic.ttf'); font-weight: 600; }
    @font-face { font-family: 'Lobster Two'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/LobsterTwo-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Lobster Two'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/LobsterTwo-BoldItalic.ttf'); font-weight: 700; }
    @font-face { font-family: 'Kalam'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Kalam-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Kalam'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Kalam-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Dancing Script'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/DancingScript-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Dancing Script'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/DancingScript-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-Italic.ttf'); font-weight: 600; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Cormorant'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Cormorant-BoldItalic.ttf'); font-weight: 700; }
    @font-face { font-family: 'Kalam'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Kalam-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Kalam'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Kalam-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Arima Madurai'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ArimaMadurai-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Arima Madurai'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ArimaMadurai-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Times New Roman'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/times-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/times-webfont.woff') format('woff'); font-weight: 500; font-style: normal; }
    @font-face { font-family: 'Times New Roman'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesbd-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesbd-webfont.woff') format('woff'); font-weight: 800; font-style: normal; }
    @font-face { font-family: 'Times New Roman'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesbi-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesbi-webfont.woff') format('woff'); font-weight: 700; font-style: normal; }
    @font-face { font-family: 'Times New Roman'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesi-webfont.woff2') format('woff2'), url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/timesi-webfont.woff') format('woff'); font-weight: 600; font-style: normal; }
    @font-face { font-family: 'Book Antiqua'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/BKANT.TTF'); font-weight: 500; }
    @font-face { font-family: 'Book Antiqua'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ANTQUAI.TTF'); font-weight: 600; }
    @font-face { font-family: 'Book Antiqua'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ANTQUABI.TTF'); font-weight: 700; }
    @font-face { font-family: 'Book Antiqua'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/ANTQUAB.TTF'); font-weight: 800; }
    @font-face { font-family: 'Bookman Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/BookmanOldStyle.woff') format('woff'); font-weight: 500; }
    @font-face { font-family: 'Bookman Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/BookmanOldStyle-Italic.woff') format('woff'); font-weight: 600; }
    @font-face { font-family: 'Bookman Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/BookmanOldStyle-BoldItalic.woff') format('woff'); font-weight: 700; }
    @font-face { font-family: 'Bookman Old Style'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/BookmanOldStyle-Bold.woff') format('woff'); font-weight: 800; }
    @font-face { font-family: 'Corsiva'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/MonotypeCorsiva.woff') format('woff'); font-weight: normal; }
    @font-face { font-family: 'Charm'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Charm-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Charm'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Charm-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Bitter'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Bitter-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Bitter'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Bitter-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Bitter'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Bitter-Italic.ttf'); font-weight: 600; }
    @font-face { font-family: 'Mirza'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Mirza-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Mirza'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Mirza-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Noto Serif TC'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSerifTC-Regular.otf'); font-weight: 500; }
    @font-face { font-family: 'Noto Serif TC'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSerifTC-Bold.otf'); font-weight: 800; }
    @font-face { font-family: 'Noto Serif SC'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSerifSC-Regular.otf'); font-weight: 500; }
    @font-face { font-family: 'Noto Serif SC'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSerifSC-Bold.otf'); font-weight: 800; }
    @font-face { font-family: 'Heebo'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Heebo-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Heebo'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/Heebo-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'David Libre'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/DavidLibre-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'David Libre'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/DavidLibre-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Frank Ruhl Libre'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/FrankRuhlLibre-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Frank Ruhl Libre'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/FrankRuhlLibre-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Abyssinica'; font-feature-settings: "cv32" 1; src: url('https://bassmollett-fonts.s3-us-west-2.amazonaws.com/fonts/editor-fonts/AbyssinicaSIL.woff'); font-weight: 500; }
    @font-face { font-family: 'Noto Sans KR'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSansKR-Regular.otf'); font-weight: 500; }
    @font-face { font-family: 'Noto Sans KR'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/NotoSansKR-Bold.otf'); font-weight: 800; }
    @font-face { font-family: 'Open Sans'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OpenSans-Regular.ttf'); font-weight: 500; }
    @font-face { font-family: 'Open Sans'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OpenSans-Italic.ttf'); font-weight: 600; }
    @font-face { font-family: 'Open Sans'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OpenSans-Bold.ttf'); font-weight: 800; }
    @font-face { font-family: 'Open Sans'; src: url('https://s3-us-west-2.amazonaws.com/bassmollett-fonts/fonts/editor-fonts/OpenSans-BoldItalic.ttf'); font-weight: 700; }
  `

  this.fonts = [
    {
      name: "Amazone"
    },
    {
      name: "Antiqua"
    },
    {
      name: "Arima Madurai",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Bitter",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Book Antiqua",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Italic",
          weight: 600
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Bookman Old Style",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Century Gothic",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Charm",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Corsiva"
    },
    {
      name: "Cormorant",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Dancing Script",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "English"
    },
    {
      name: "FG Jayne"
    },
    {
      name: "Gaeilge"
    },
    {
      name: "Goudy Catalog"
    },
    {
      name: "Goudy Old Style",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Kalam",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Lato",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Liberty"
    },
    {
      name: "Lobster Two",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Lora",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Merienda",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Mistral"
    },
    {
      name: "Monterey"
    },
    {
      name: "Murray Hill",
      weights: [
        {
          name: "Normal",
          weight: "Normal"
        },
        {
          name: "Bold",
          weight: "Bold"
        }
      ]
    },
    {
      name: "Nuptial"
    },
    {
      name: "Oleo Script",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Papyrus"
    },
    {
      name: "Park Avenue"
    },
    {
      name: "Piranesi"
    },
    {
      name: "Pristina"
    },
    {
      name: "Ribbon"
    },
    {
      name: "Ruthie"
    },
    {
      name: "Scriptina"
    },
    {
      name: "Shelley Allegro"
    },
    {
      name: "Shelley Andante"
    },
    {
      name: "Tangerine",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Times New Roman",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
    {
      name: "Vivaldii"
    },
    {
      name: "Wedding Text"
    },
    {
      name: "Abyssinica",
      language: "Amharic - አማርኛ"
    },
    {
      name: "David Libre",
      language: "Hebrew עברית",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Frank Ruhl Libre",
      language: "Hebrew - עברית",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Heebo",
      language: "Hebrew - עברית",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Mirza",
      language: "Arabic - عربى",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Noto Sans KR",
      language: "Korean - 한국어",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Noto Serif TC",
      language: "Traditional Chinese - 中文",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Noto Serif SC",
      language: "Simplified Chinese - 中文",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        }
      ]
    },
    {
      name: "Open Sans",
      language: "Vietnamese - Tiếng Việt",
      weights: [
        {
          name: "Normal",
          weight: 500
        },
        {
          name: "Bold",
          weight: 800
        },
        {
          name: "Bold Italic",
          weight: 700
        },
        {
          name: "Italic",
          weight: 600
        }
      ]
    },
  ];

  this.colorOptions = {
    preferredFormat: 'hex',
    allowEmpty: true,
    replacerClassName: 'color-stage-trigger',
    showInput: true,
    showPalette: true,
    showButtons: false,
    showSelectionPalette: true,
    palette: [
        ["#000","#444","#666","#999","#ccc","#eee","#f3f3f3","#fff"],
        ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
        ["#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#cfe2f3","#d9d2e9","#ead1dc"],
        ["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
        ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
        ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
        ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
        ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
    ]
  };

  this.cvStyle = `<style>
    p { font-family: 'Century Gothic'; } .printedarea { overflow: hidden; } html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 2px; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; text-rendering: geometricPrecision; } /* HTML5 display-role reset for older browsers */ article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; } table { border-collapse: collapse; border-spacing: 0; } a, a:hover, a:focus, button:focus, button:active { color: inherit; text-decoration: none; outline: none; } * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; outline: none; font-family: 'Century Gothic' } </style>
    <style>
    ${fonts}
    </style>`;

  this.printStyle = `<style>
    p { font-family: 'Century Gothic'; } .printedarea { overflow: hidden; } html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; text-rendering: geometricPrecision; } /* HTML5 display-role reset for older browsers */ article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block; } body { line-height: 1; } ol, ul { list-style: none; } blockquote, q { quotes: none; } blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; } table { border-collapse: collapse; border-spacing: 0; } a, a:hover, a:focus, button:focus, button:active { color: inherit; text-decoration: none; outline: none; } * { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; outline: none; font-family: 'Century Gothic' } </style>
    <style>
    ${fonts}
    </style>`;

  this.printHeader = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN" "http://www.w3.org/TR/REC-html40/loose.dtd"><html><head><META http-equiv="Content-Type" content="text/html; charset=iso-8859-1"> <META http-equiv="Content-Style-Type" content="text/css"><link href='https://fonts.googleapis.com/css?family=Lato:400,300,700,900' rel='stylesheet' type='text/css'></head><body>`


  this.printFooter = `</body></html>`

  this.fontStyle = `<style>
    ${fonts}
    </style> `;

  this.casefields = [
    {
  	"title": "In Memory of",
  	"handlebar": "case.name_of_deceased",

    }, {
    	"title": "Pallbearers",
    	"doubleTitle": "Pallbearer Columns",
    	"handlebar": "case.pallbearers"
    }, {
      "title": "Age",
    	"handlebar": "case.age",
    }, {
    	"title": "Age Years",
    	"handlebar": "case.age_years",
    }, {
    	"title": "Age Months",
    	"handlebar": "case.age_months",
    }, {
    	"title": "Age Days",
    	"handlebar": "case.age_days",
    }, {
    	"title": "Date of Birth",
    	"handlebar": "case.date_of_birth | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Date of Death",
    	"handlebar": "case.date_of_death | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Place of Death",
    	"handlebar": "case.place_of_death",
    }, {
    	"title": "Religion",
    	"handlebar": "case.religion",
    }, {
    	"title": "Place of Birth",
    	"handlebar": "case.place_of_birth",
    }, {
    	"title": "Residence Full Address",
    	"handlebar": "case.address_of_deceased",
    }, {
    	"title": "Deceased Mother",
    	"handlebar": "case.mother_of_deceased",
    }, {
    	"title": "Deceaseds Father",
    	"handlebar": "case.father_of_deceased",
    }, {
    	"title": "Spouse",
    	"handlebar": "case.spouse",
    }, {
    	"title": "Marital Status",
    	"handlebar": "case.marital_status",
    }, {
    	"title": "Occupation",
    	"handlebar": "case.occupation",
    }, {
    	"title": "Family Contact",
    	"handlebar": "case.family_contact_name",
    }, {
    	"title": "Family Contact Full Address",
    	"handlebar": "case.family_contact_address",
    }, {
    	"title": "Children",
    	"doubleTitle": "Children Columns",
    	"handlebar": "case.children",
    }, {
    	"title": "Siblings",
    	"doubleTitle": "Sibling Columns",
    	"handlebar": "case.siblings",
    }, {
    	"title": "Obituary",
    	"handlebar": "case.obituary",
    }, {
    	"title": "Organizations",
    	"doubleTitle": "Organization Columns",
    	"handlebar": "case.organizations",
    }, {
    	"title": "Service Location",
    	"handlebar": "case.service_location",
    }, {
    	"title": "Officiating",
    	"handlebar": "case.clergy_name",
    }, {
    	"title": "Service",
    	"handlebar": "case.service_date | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Service Time",
    	"handlebar": "case.service_time",
    }, {
    	"title": "Music Selection",
    	"doubleTitle": "Music Columns",
    	"handlebar": "case.songs",
    }, {
    	"title": "Special Song",
    	"doubleTitle": "Special Song Columns",
    	"handlebar": "case.special_songs",
    }, {
    	"title": "Societies Attending",
    	"doubleTitle": "Societies Attending Columns",
    	"handlebar": "case.societies",
    }, {
    	"title": "Place of Interment",
    	"handlebar": "case.interment_place",
    }, {
    	"title": "Interment Lot",
    	"handlebar": "case.interment_lot",
    }, {
    	"title": "Interment State",
    	"handlebar": "case.interment_state",
    }, {
    	"title": "Interment Section",
    	"handlebar": "case.interment_section",
    }, {
    	"title": "Interment City",
    	"handlebar": "case.interment_city",
    }, {
    	"title": "Interment Date",
    	"handlebar": "case.interment_date | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Interment Block",
    	"handlebar": "case.interment_block",
    }, {
    	"title": "Interment County",
    	"handlebar": "case.interment_county",
    }, {
    	"title": "Interment Time",
    	"handlebar": "case.interment_time",
    }, {
    	"title": "Branch Of Service",
    	"handlebar": "case.branch_of_service",
    }, {
    	"title": "Place Entered Service",
    	"handlebar": "case.place_entered_service",
    }, {
    	"title": "Rank Discharged",
    	"handlebar": "case.rank_discharged",
    }, {
    	"title": "Service Number",
    	"handlebar": "case.service_number",
    }, {
    	"title": "Date Entered Service",
    	"handlebar": "case.date_entered_service | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Date Discharged",
    	"handlebar": "case.date_discharged | date: 'MMMM d, yyyy'",
    }, {
    	"title": "Place Discharged",
    	"handlebar": "case.palce_discharged",
    }, {
    	"title": "NCOIC",
    	"handlebar": "case.ncoic_name",
    }, {
    	"title": "Chaplain",
    	"handlebar": "case.chaplain",
    }, {
    	"title": "OIC",
    	"handlebar": "case.oic_name",
    }, {
    	"title": "Buglar and Or Color Guard",
    	"handlebar": "case.buglar_and_or_color_guard",
    }, {
    	"title": "Providing Honors",
    	"handlebar": "case.person_providing_honors",
    }, {
    	"title": "Honorary Pallbearers",
    	"doubleTitle": "Honorary Pallbearer Columns",
    	"handlebar": "case.honorary_pallbearers"
    }
  ]

});
