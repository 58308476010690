// ######  FILE LINKED AND CLEANED  ######

(function() {
	angular.module("app").directive("siteheader", headerController);
	function headerController(funeralHomes, user, $rootScope, $timeout, funeralHomeLogo, $location, $compile) {
		return {
			templateUrl: "pages/header/header.html",
			controllerAs: "vms",
			controller: function() {
				let vm = this;

				angular.extend(vm, {
					home: {},
					uploadLogo: uploadLogo,
					clickInput: clickInput,
					user: {},
					logout: logout,
					isBeta: false
				});

				getHomeInfo();
				getUser();
				vm.isBeta = isBeta


				
				function getHomeInfo() {
					vm.home = null;
					if (funeralHomes.home) {
						vm.home = funeralHomes.home;
						if (funeralHomeLogo.logos) {
							vm.logo = funeralHomeLogo.logos.small || funeralHomeLogo.logos.medium || funeralHomeLogo.logos.large || null;
						} else {
							getLogos();
						}
					} else {
						funeralHomes.get().then(response => {
							vm.home = response.data;
							getLogos();
						});
					}
				}

				function getLogos() {
					funeralHomeLogo.getLogos().then(response => {
						let logos = response.data

						if (logos && (logos.small || logos.medium || logos.large)) {
							vm.logo = logos.small || logos.medium || logos.large;
						} else {
							vm.logo = null;
						}
					});
				}

				function getUser() {
					if (user.user) {
						vm.user.name = user.user.name;
					} else {
						user.get().then(user => {
							vm.user.name = user.data.name;
						});
					}
				}

				function uploadLogo() {
					$("#logo-recommendations-modal").modal("show");
				}

				function clickInput() {
					$("#logo-recommendations-modal").modal("hide");
					$("#small_logo")[0].click();
				}

				$rootScope.$on('smallLogoUpload', (event, payload) => {
					vm.logo = payload.small
				})

				function logout() {
					user.logout();
					$location.path("/login");
				}

				//       ******** end *********//
			}
		};
	}
})();
