(function() {
  angular.module("app").directive("assetMenu", assetMenuController);
  function assetMenuController(
    cases,
    assets,
    $routeParams,
    $timeout,
    customVerses,
    $compile,
    $rootScope,
    funeralHomeLogo,
    dom,
    scroll,
  ) {
    return {
      templateUrl: "pages/editor/assetMenu/asset-menu.html",
      controllerAs: "avm", // ASSET MODEL
      controller: function($scope) {
        const avm = this;

        angular.extend(avm, {
          compiledVerses: [],
          customCaseFields: null,
          homeLogos: [],
					drawerManager: drawerManager,
          closeLibrary: closeLibrary,
					loadLibrary: loadLibrary,
					showLibrary: [],
					showAssets: false,
          libraries: [
            {name: 'Thank You Verses'},
            {name: 'Service Record Verses'},
            {name: 'Bookmark Verses'}]
        });

        scroll.scrollAssist()

        function drawerManager(drawer) {
          avm.searchTerm = ''
          if(drawer == "Layers"){
            avm.showCaseField = false
            avm.showLayers == true ? avm.showLayers = false : avm.showLayers = true
            avm.assetDrawer1 = false
            avm.assetDrawer2 = false
            avm.showMemPages = false
            avm.showCondolencePages = false
            avm.showFriendsRelativesPages = false
            avm.showPages = false
          }
          if(drawer == "Artwork"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 == true ? avm.assetDrawer1 = false : avm.assetDrawer1 = true
            avm.assetDrawer2 = false
            avm.showMemPages = false
            avm.showCondolencePages = false
            avm.showFriendsRelativesPages = false
            avm.showPages = false
          }
          if(drawer == "Verses"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 = false
            avm.assetDrawer2 == true ? avm.assetDrawer2 = false : avm.assetDrawer2 = true
            avm.showMemPages = false
            avm.showCondolencePages = false
            avm.showFriendsRelativesPages = false
            avm.showPages = false
          }
          if(drawer == "floralPix"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 = false
            avm.showPages == true ? avm.showPages = false : avm.showPages = true
            avm.assetDrawer2 = false
            avm.showMemPages = false
            avm.showCondolencePages = false
            avm.showFriendsRelativesPages = false
          }
          if(drawer == "memorials"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 = false
            avm.showMemPages == true ? avm.showMemPages = false : avm.showMemPages = true
            avm.assetDrawer2 = false
            avm.showCondolencePages = false
            avm.showFriendsRelativesPages = false
            avm.showPages = false
          }
          if(drawer == "condolences"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 = false
            avm.showCondolencePages == true ? avm.showCondolencePages = false : avm.showCondolencePages = true
            avm.assetDrawer2 = false
            avm.showPages = false
            avm.showMemPages = false
            avm.showFriendsRelativesPages = false
          }
          if(drawer == "friendsRelatives"){
            avm.showCaseField = false
            avm.showLayers = false
            avm.assetDrawer1 = false
            avm.showFriendsRelativesPages == true ? avm.showFriendsRelativesPages = false : avm.showFriendsRelativesPages = true
            avm.assetDrawer2 = false
            avm.showPages = false
            avm.showMemPages = false
            avm.showCondolencesPages = false
          }
        }

        function loadLibrary(library, index) {
          $(".asset-scroll").scrollLeft(0)
          $(".scroll-assist-container").scrollLeft(0);
          avm.showAssets = true
          $(".header-mask").show()
          $('.assets-header-title').hide()
          if(library != 'memorials') avm.showMemPages = false
          if(library != 'floralPix') avm.showPages = false

          if(index){
            avm.searchTerm = ''
            avm.assetLibrary = 'assets'
            assets.getAssetsByCategory(library).then(response => {
              avm.assetSubcategories = response.data
            })
          } else {
            avm.assetLibrary = library
            if(library == 'search'){
              assets.searchAssets(avm.searchTerm).then(response => {
                avm.searchResults = response.data
              })
            } else if(library == 'logos'){
              avm.searchTerm = ''
              if(!funeralHomeLogo.logos){
                funeralHomeLogo.getLogos().then(response => {
                  avm.homeLogos = response.data
                  delete avm.homeLogos.funeral_home_id
                })
              } else {
                avm.homeLogos = funeralHomeLogo.logos
                delete avm.homeLogos.funeral_home_id
              }
            } else if (library == 'customVerses') {
              avm.searchTerm = ''
              if(!customVerses.compiledVerses){
                customVerses.getAll().then(response => {
                  avm.compiledVerses = response.data
                })
              } else {
                avm.searchTerm = ''
                avm.compiledVerses = customVerses.compiledVerses
              }
            }
          }

          dom.getPageWidth()
          $('.scroll-tooltip').show()
          $('.hover-scroll-right').show()
          $('.hover-scroll-left').show()
          scroll.setScrollWidth()
          scroll.scrollRight()
          scroll.stopScrollRight()
          scroll.scrollLeft()
          scroll.stopScrollLeft()
        }

        function closeLibrary(event) {
          if (!event) {
            avm.showAssets = false
            avm.showCaseImages = false
            avm.showVerses = false
            avm.showMemPages = false
            $('.header-mask').hide()
            $('.assets-header-title').show()
            $('.hover-scroll-right').hide()
            $('.hover-scroll-left').hide()
            avm.showSearch = false
            avm.assetLibrary = ''
          } else {
            if(event.target.className != 'default-photo-button' && event.target.parentElement.className != 'default-photo-button' ) {
              avm.showPages = false
              avm.showAssets = false
              avm.showCaseImages = false
              avm.showVerses = false
              avm.showMemPages = false
              $('.header-mask').hide()
              $('.assets-header-title').show()
              $('.hover-scroll-right').hide()
              $('.hover-scroll-left').hide()
              avm.showSearch = false
              avm.assetLibrary = ''
            }
          }
        }

        $rootScope.$on('deceasedIMG', function(){
          loadLibrary('personalImages')
        })
      }
    };
  }
})();
