/*
|--------------------------------------------------------------------------
| Handling Raw CSV
|--------------------------------------------------------------------------
| Because CSV and the DPS database formats are not one-to-one matches it is
|  necessary to first parse and prepare the CSV values. The
|  convertNewValues() fn handles CSVs that have adopted the same nomenclature
|  of the v2 version of the DPS backend. The convertOldValues() fn handles
|  the old nomenclature that all customers are using as of 07 Feb 19.
|  Present CSVs also tend to have variation in the type and style of their
|  entries, so convertOldValues() standardizes them. Eventually DPS
|  customers will be encouraged to adopt the new nomenclature and the parser
|  for the old values will be removed.
|
| 12 July 2019 Update:
| This utility has been extended to include additional_family and other new
|   fields. DPS vendors have been provided with documentation for how to
|   properly update CSV data.
*/

angular.module("app").service("convertcsv", function () {
  const falsy = ["n", "N", 0, "0", false, "false"];
  const truthy = ["y", "Y", 1, "1", true, "true", "TRUE"];

  const arrayValues = [
    "children",
    "clergy",
    "grandchildren",
    "siblings",
    "personal_images",
    "organizations",
    "songs",
    "special_songs",
    "pallbearers",
    "honorary_pallbearers",
    "societies",
    "other_medals",
    "us_stations_where_served",
    "firing_parties_for_funeral_service",
    "services",
    "additional_family"
  ];

  const booleanValues = [
    "distinguished_service_medal",
    "purple_heart_medal",
    "silver_star_medal",
    "medal_of_honor",
    "air_force_cross_medal",
    "navy_cross_medal",
    "bronze_star_medal"
  ];

  const stringValuesWithCommas = [
    "name_of_deceased",
    "place_of_birth",
    "place_of_death",
    "address_of_deceased",
    "place_of_marriage",
    "family_contact_address",
    "service_location",
    "interment_place",
    "place_entered_service",
    "place_discharged_from_service",
    "obituary"
  ];

  const stringValues = [
    "editable_case_id",
    "date_of_birth",
    "date_of_death",
    "religion",
    "mother_of_deceased",
    "father_of_deceased",
    "spouse",
    "marital_status",
    "date_of_marriage",
    "family_contact_name",
    "occupation",
    "service_date",
    "service_time",
    "clergy_name",
    "interment_lot",
    "interment_section",
    "interment_block",
    "interment_city",
    "interment_state",
    "interment_date",
    "interment_county",
    "interment_time",
    "branch_of_service",
    "date_entered_service",
    "rank_discharged_from_service",
    "date_discharged_from_service",
    "service_number",
    "chaplain",
    "ncoic_name",
    "oic_name",
    "buglar_and_or_color_guard",
    "person_providing_honors"
  ];

  const convertNewValues = rawCase => {
    let newCase = {};
    const rawCaseArray = Object.entries(rawCase);

    rawCaseArray.forEach(([objKey, objVal]) => {
      /* CSV converts comma separated values into arrays; the following loop
        corrects that behavior for the stringValuesWithCommas values */
      stringValuesWithCommas.forEach(strVal => {
        if (strVal === objKey && objVal) {
          if (typeof objVal === "string") {
            newCase[objKey] = objVal;
          } else {
            newCase[objKey] = objVal.join(",");
          }
        }
      });

      /* For database rows expecting an array, the following loop converts
        CSV values to an array where a single item is rendered as a string */
      arrayValues.forEach(arrVal => {
        if (arrVal === objKey && objVal) {
          typeof objVal === "string"
            ? (newCase[objKey] = [objVal])
            : (newCase[objKey] = objVal);
        }
      });

      stringValues.forEach(strVal => {
        strVal === objKey && objVal && (newCase[objKey] = objVal);
      });

      booleanValues.forEach(boolVal => {
        if (boolVal === objKey && objVal) {
          objVal === "TRUE"
            ? (newCase[objKey] = true)
            : (newCase[objKey] = false);
        }
      });
    });

    // Collates services and stores them on newCase variable
    newCase.services = [];
    const service1 = rawCase.service1_date && ({
      default: rawCase.service1_default && rawCase.service1_default,
      date: rawCase.service1_date && rawCase.service1_date,
      location: rawCase.service1_location && rawCase.service1_location.join(","),
      time: rawCase.service1_time && rawCase.service1_time,
      type: rawCase.service1_type && rawCase.service1_type
    });
    const service2 = rawCase.service2_date && ({
      default: rawCase.service2_default && rawCase.service2_default,
      date: rawCase.service2_date && rawCase.service2_date,
      location: rawCase.service2_location && rawCase.service2_location.join(","),
      time: rawCase.service2_time && rawCase.service2_time,
      type: rawCase.service2_type && rawCase.service2_type
    });
    const service3 = rawCase.service3_date && ({
      default: rawCase.service3_default && rawCase.service3_default,
      date: rawCase.service3_date && rawCase.service3_date,
      location: rawCase.service3_location && rawCase.service3_location.join(","),
      time: rawCase.service3_time && rawCase.service3_time,
      type: rawCase.service3_type && rawCase.service3_type
    });
    const service4 = rawCase.service4_date && ({
      default: rawCase.service4_default && rawCase.service4_default,
      date: rawCase.service4_date && rawCase.service4_date,
      location: rawCase.service4_location && rawCase.service4_location.join(","),
      time: rawCase.service4_time && rawCase.service4_time,
      type: rawCase.service4_type && rawCase.service4_type
    });

    service1 && newCase.services.push(service1);
    service2 && newCase.services.push(service2);
    service3 && newCase.services.push(service3);
    service4 && newCase.services.push(service4);


    // Collates additional_family and stores them on newCase variable
    newCase.additional_family = [];
    const family1 = rawCase.family1_name && ({
      name: rawCase.family1_name && rawCase.family1_name,
      relation: rawCase.family1_relation && rawCase.family1_relation,
      type: rawCase.family1_type && rawCase.family1_type
    });
    const family2 = rawCase.family2_name && ({
      name: rawCase.family2_name && rawCase.family2_name,
      relation: rawCase.family2_relation && rawCase.family2_relation,
      type: rawCase.family2_type && rawCase.family2_type
    });
    const family3 = rawCase.family3_name && ({
      name: rawCase.family3_name && rawCase.family3_name,
      relation: rawCase.family3_relation && rawCase.family3_relation,
      type: rawCase.family3_type && rawCase.family3_type
    });
    const family4 = rawCase.family4_name && ({
      name: rawCase.family4_name && rawCase.family4_name,
      relation: rawCase.family4_relation && rawCase.family4_relation,
      type: rawCase.family4_type && rawCase.family4_type
    });
    const family5 = rawCase.family5_name && ({
      name: rawCase.family5_name && rawCase.family5_name,
      relation: rawCase.family5_relation && rawCase.family5_relation,
      type: rawCase.family5_type && rawCase.family5_type
    });
    family1 && newCase.additional_family.push(family1);
    family2 && newCase.additional_family.push(family2);
    family3 && newCase.additional_family.push(family3);
    family4 && newCase.additional_family.push(family4);
    family5 && newCase.additional_family.push(family5);

    delete newCase.service_date
    delete newCase.service_location
    delete newCase.service_time
    delete newCase.clergy_name
    return newCase;
  };

  const convertOldValues = rawCase => {
    let newCase = {};

    // Strings and Integers
    newCase.editable_case_id = rawCase.CaseNumber || null;
    newCase.date_of_birth = rawCase.DateOfBirth || null;
    newCase.date_of_death = rawCase.DateOfDeath || null;
    newCase.marital_status = rawCase.MaritalStatus || null;
    newCase.religion = rawCase.Religion || null;
    newCase.service_date = rawCase.ServiceDate || null;
    newCase.service_time = rawCase.ServiceTime || null;
    newCase.interment_lot = rawCase.IntermentLot || null;
    newCase.interment_section = rawCase.IntermentSection || null;
    newCase.interment_block = rawCase.IntermentBlock || null;
    newCase.interment_city = rawCase.IntermentCity || null;
    newCase.interment_state = rawCase.IntermentState || null;
    newCase.interment_date = rawCase.IntermentDate || null;
    newCase.interment_county = rawCase.IntermentCounty || null;
    newCase.interment_time = rawCase.IntermentTime || null;
    newCase.branch_of_service = rawCase.BranchOfService || null;
    newCase.date_entered_service = rawCase.DateEnteredService || null;
    newCase.rank_discharged_from_service = rawCase.RankDischarged || null;
    newCase.date_discharged_from_service = rawCase.DateDischarged || null;
    newCase.place_discharged_from_service = rawCase.PlaceDischarged || null;
    newCase.service_number = rawCase.ServiceNumber || null;
    newCase.chaplain = rawCase.Chaplain || null;
    newCase.ncoic_name = rawCase.NCOIC || null;
    newCase.oic_name = rawCase.OIC || null;
    newCase.buglar_and_or_color_guard = rawCase.BuglarAndOrColorGuard || null;

    // Handle for if values split into an array
    newCase.place_of_death = rawCase.PlaceOfDeath
      ? typeof rawCase.PlaceOfDeath == "string"
        ? rawCase.PlaceOfDeath
        : rawCase.PlaceOfDeath.join(", ")
      : null;
    newCase.place_of_birth = rawCase.PlaceOfBirth
      ? typeof rawCase.PlaceOfBirth == "string"
        ? rawCase.PlaceOfBirth
        : rawCase.PlaceOfBirth.join(", ")
      : null;
    newCase.address_of_deceased = rawCase.ResidenceFullAddress
      ? typeof rawCase.ResidenceFullAddress == "string"
        ? rawCase.ResidenceFullAddress
        : rawCase.ResidenceFullAddress.join(", ")
      : null;
    newCase.mother_of_deceased = rawCase.DeceasedsMother
      ? typeof rawCase.DeceasedsMother == "string"
        ? rawCase.DeceasedsMother
        : rawCase.DeceasedsMother.join(", ")
      : null;
    newCase.name_of_deceased = rawCase.DeceasedsName
      ? typeof rawCase.DeceasedsName == "string"
        ? rawCase.DeceasedsName
        : rawCase.DeceasedsName.join(", ")
      : null;
    newCase.father_of_deceased = rawCase.DeceasedsFather
      ? typeof rawCase.DeceasedsFather == "string"
        ? rawCase.DeceasedsFather
        : rawCase.DeceasedsFather.join(", ")
      : null;
    newCase.spouse = rawCase.Spouse
      ? typeof rawCase.Spouse == "string"
        ? rawCase.Spouse
        : rawCase.Spouse.join(", ")
      : null;
    newCase.occupation = rawCase.Occupation
      ? typeof rawCase.Occupation == "string"
        ? rawCase.Occupation
        : rawCase.Occupation.join(", ")
      : null;
    newCase.family_contact_name = rawCase.FamilyContact
      ? typeof rawCase.FamilyContact == "string"
        ? rawCase.FamilyContact
        : rawCase.FamilyContact.join(", ")
      : null;
    newCase.family_contact_address = rawCase.FamilyContactFullAddress
      ? typeof rawCase.FamilyContactFullAddress == "string"
        ? rawCase.FamilyContactFullAddress
        : rawCase.FamilyContactFullAddress.join(", ")
      : null;
    newCase.obituary = rawCase.Obit
      ? typeof rawCase.Obit == "string"
        ? rawCase.Obit
        : rawCase.Obit.join(", ")
      : null;
    newCase.service_location = rawCase.ServiceLocation
      ? typeof rawCase.ServiceLocation == "string"
        ? rawCase.ServiceLocation
        : rawCase.ServiceLocation.join(", ")
      : null;
    newCase.interment_place = rawCase.IntermentPlace
      ? typeof rawCase.IntermentPlace == "string"
        ? rawCase.IntermentPlace
        : rawCase.IntermentPlace.join(", ")
      : null;
    newCase.place_entered_service = rawCase.PlaceEnteredService
      ? typeof rawCase.PlaceEnteredService == "string"
        ? rawCase.PlaceEnteredService
        : rawCase.PlaceEnteredService.join(", ")
      : null;

    let clergyFirstName = rawCase.ClergyFullName
      ? typeof rawCase.ClergyFullName == "string"
        ? rawCase.ClergyFullName
        : rawCase.ClergyFullName.join("")
      : null;

    let clergySecondName = rawCase.ClergyFullName2
      ? typeof rawCase.ClergyFullName2 == "string"
        ? rawCase.ClergyFullName2
        : rawCase.ClergyFullName2.join("")
      : null;

    newCase.clergy_name = clergyFirstName
      ? clergySecondName
        ? clergyFirstName + " " + clergySecondName
        : clergyFirstName
      : null;

    // Booleans for Medals
    rawCase.DistinguishedServiceCross &&
      truthy.indexOf(rawCase.DistinguishedServiceCross) > -1
      ? (newCase.distinguished_service_medal = true)
      : (newCase.distinguished_service_medal = false);
    rawCase.PurpleHeart && truthy.indexOf(rawCase.PurpleHeart) > -1
      ? (newCase.purple_heart_medal = true)
      : (newCase.purple_heart_medal = false);
    rawCase.SilverStar && truthy.indexOf(rawCase.SilverStar) > -1
      ? (newCase.silver_star_medal = true)
      : (newCase.silver_star_medal = false);
    rawCase.MedalOfHonor && truthy.indexOf(rawCase.MedalOfHonor) > -1
      ? (newCase.medal_of_honor = true)
      : (newCase.medal_of_honor = false);
    rawCase.AirForceCross && truthy.indexOf(rawCase.AirForceCross) > -1
      ? (newCase.air_force_cross_medal = true)
      : (newCase.air_force_cross_medal = false);
    rawCase.NavyCross && truthy.indexOf(rawCase.NavyCross) > -1
      ? (newCase.navy_cross_medal = true)
      : (newCase.navy_cross_medal = false);
    rawCase.BronzeStar && truthy.indexOf(rawCase.BronzeStar) > -1
      ? (newCase.bronze_star_medal = true)
      : (newCase.bronze_star_medal = false);

    // Arrays
    newCase.children = [];
    newCase.firing_parties_for_funeral_service = [];
    newCase.us_stations_where_served = [];
    newCase.societies = [];
    newCase.pallbearers = [];
    newCase.honorary_pallbearers = [];
    newCase.special_songs = [];
    newCase.songs = [];
    newCase.organizations = [];
    newCase.siblings = [];

    Object.keys(rawCase).forEach(c => {
      if (c.indexOf("Child") > -1) {
        if (rawCase[c] !== "") {
          newCase.children.push(rawCase[c]);
        }
      } else if (c.indexOf("Firing") > -1) {
        if (rawCase[c] !== "") {
          newCase.firing_parties_for_funeral_service.push(rawCase[c]);
        }
      } else if (c.indexOf("StationWithinUS") > -1) {
        if (rawCase[c] !== "") {
          newCase.us_stations_where_served.push(rawCase[c]);
        }
      } else if (c.indexOf("Society") > -1) {
        if (rawCase[c] !== "") {
          typeof rawCase[c] === "string"
            ? newCase.societies.push(rawCase[c])
            : newCase.societies.push(rawCase[c].join(", "));
        }
      } else if (c.indexOf("Pallbearer") > -1) {
        if (rawCase[c] !== "") {
          newCase.pallbearers.push(rawCase[c]);
        }
      } else if (c.indexOf("HonoraryPallbearer") > -1) {
        if (rawCase[c] !== "") {
          newCase.honorary_pallbearers.push(rawCase[c]);
        }
      } else if (c.indexOf("Organization") > -1) {
        if (rawCase[c] !== "") {
          typeof rawCase[c] === "string"
            ? newCase.organizations.push(rawCase[c])
            : newCase.organizations.push(rawCase[c].join(", "));
        }
      } else if (c.indexOf("Sibling") > -1) {
        if (rawCase[c] !== "") {
          newCase.siblings.push(rawCase[c]);
        }
      } else if (c.indexOf("SpecialSong") > -1) {
        if (rawCase[c] !== "") {
          typeof rawCase[c] === "string"
            ? newCase.special_songs.push(rawCase[c])
            : newCase.special_songs.push(rawCase[c].join(", "));
        }
      } else if (c.indexOf("MusicSelection") > -1) {
        if (rawCase[c] !== "") {
          typeof rawCase[c] === "string"
            ? newCase.songs.push(rawCase[c])
            : newCase.songs.push(rawCase[c].join(", "));
        }
      } else {
        //do nothing
      }
    });

    newCase.services = [{ date: newCase.service_date, location: newCase.service_location, time: newCase.service_time, type: 'service' }]
    newCase.clergy = [newCase.clergy_name]
    delete newCase.service_date
    delete newCase.service_location
    delete newCase.service_time
    delete newCase.clergy_name
    return newCase;
  };

  this.convert = rawCase => {
    return new Promise((resolve, reject) => {
      rawCase.name_of_deceased
        ? resolve(convertNewValues(rawCase))
        : resolve(convertOldValues(rawCase));
    });
  };

  ///// END OF SERViCE \\\\\
});
