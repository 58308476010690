angular.module('app').service('customVerses', function($http, $q) {

  this.getAll = function() {
    var deferred = $q.defer();
    $http.get(api + 'customVerses')
      .then(response => {
        this.compiledVerses = response.data
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.create = function(payload) {
    var deferred = $q.defer();
    $http.post(api + 'createCustomVerse', payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.update = function(id, payload) {
    var deferred = $q.defer();
    $http.patch(api + `customVerses/${id}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.delete = function(id) {
    var deferred = $q.defer();
    $http.delete(api + `customVerses/${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createExclusion = function(payload) {
    var deferred = $q.defer();
    $http.post(api + 'createCustomVerseExclusion', payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
