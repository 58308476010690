angular.module('app').service('orderProducts', function($http, $q) {

  const service = this

  this.create = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createOrderProductDetails`, payload)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.get = function(payload) {
    const deferred = $q.defer();
    $http.get(api + `orderProductDetails?collection_id=${payload.collection_id}&order_id=${payload.order_id}`)
      .then((response) => {
      this.selectedProducts = response.data
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `orderProductDetails/${id}`, payload)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
