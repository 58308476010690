angular.module('app').service('cases', function($http, $q) {

  var service = this
  this.caseCustomFields = null
  this.currentCase = {}

  function convertAllDates(payload) {
    const createdDate = payload.data.created_at ? moment.utc(payload.data.created_at).format('LL') : null
    payload.data.created_at = createdDate

    const serviceDate = payload.data.service_date ? moment.utc(payload.data.service_date).format('LL') : null
    payload.data.service_date = serviceDate

    const birthDate = payload.data.date_of_birth ? moment.utc(payload.data.date_of_birth).format('LL') : null
    payload.data.date_of_birth = birthDate

    const deathDate = payload.data.date_of_death ? moment.utc(payload.data.date_of_death).format('LL') : null
    payload.data.date_of_death = deathDate

    const marriageDate = payload.data.date_of_marriage ? moment.utc(payload.data.date_of_marriage).format('LL') : null
    payload.data.date_of_marriage = marriageDate

    const intermentDate = payload.data.interment_date ? moment.utc(payload.data.interment_date).format('LL') : null
    payload.data.interment_date = intermentDate

    const dateEnteredDate = payload.data.date_entered_service ? moment.utc(payload.data.date_entered_service).format('LL') : null
    payload.data.date_entered_service = dateEnteredDate

    const dateDischargedDate = payload.data.date_discharged_from_service ? moment.utc(payload.data.date_discharged_from_service).format('LL') : null
    payload.data.date_discharged_from_service = dateDischargedDate

    if(payload.data.expiration_date) {
      const expirationDate = payload.data.expiration_date ? moment.utc(payload.data.expiration_date).format('LL') : null
      payload.data.expiration_date = expirationDate
    }

    if(payload.data.services) {
      const servicesDates = payload.data.services.map(item => {
        item.date = item.date ? moment.utc(item.date).format('LL') : null
        return item;
      })
      payload.data.services = servicesDates
    }

    return payload
  }

  function convertDashboardDates(payload){
    payload.data.cases.map(datum => {
      datum.sortable_date_of_death = datum.date_of_death
      const deathDate = datum.date_of_death ? moment.utc(datum.date_of_death).format('ll') : null
      datum.date_of_death = deathDate

      if(datum.services) {
        datum.sortable_service_date = datum.services.reduce(item => item.default)
        const serviceDate = datum.date ? moment.utc(datum.date).format('ll') : null
        datum.service_date = serviceDate
      }
      
      datum.sortable_created_at = datum.created_at
      const createdDate = datum.created_at ? moment.utc(datum.created_at).format('ll') : null
      datum.created_at = createdDate

      return datum
    })

    return payload
  }

  function convertDatesForLabels(payload){
    payload.data.map(datum => {
      const birthDate = moment.utc(datum.date_of_birth).format('LL')
      datum.date_of_birth = birthDate

      const deathDate = moment.utc(datum.date_of_death).format('LL')
      datum.date_of_death = deathDate
    })

    return payload
  }

  this.get = function(id) {
    const deferred = $q.defer();
    $http.get(api + `cases/${id}`)
      .then(response => {
      const sanitizedCase = convertAllDates(response)
      this.currentCase = sanitizedCase.data
      deferred.resolve(convertAllDates(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getAsGuest = function(token) {
    const deferred = $q.defer();
    $http.get(api + `caseAsGuest/${token}`)
      .then(response => {
      const sanitizedCase = convertAllDates(response)
      deferred.resolve(sanitizedCase)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getAll = function(page) {
    const deferred = $q.defer();
    $http.get(api + `funeralHomeCases?page=${page || 0}`)
      .then(response => {
      deferred.resolve(
        convertDashboardDates(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getArchivedCases = function(page) {
    const deferred = $q.defer();
    $http.get(api + `funeralHomeCases?page=${page || 0}&archived=true`)
      .then(response => {
      deferred.resolve(convertDashboardDates(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getCasesForLabels = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'casesForLabels', payload)
      .then(response => {
      deferred.resolve(convertDatesForLabels(response))
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.archiveCase = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `archiveCase/${id}` )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.unarchiveCase = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `unarchiveCase/${id}` )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.search = function(search, field) {
    return $q.when($http.post(api + 'cases/search/' + field, {search: search}))
  }

  this.create = async function(payload) {
    try {
      const response = await $http.post(api + 'createCase', payload )
      this.currentCase = response.data
      return response
    } catch (err) {
      throw err
    }
  }

  this.update = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `cases/${id}`, payload )
      .then(response => {
        const newPayload = convertAllDates(response)
        this.currentCase = newPayload.data
      deferred.resolve(newPayload)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.uploadImage = function(id, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.post(api + `uploadCaseImage/${id}`, payload, config)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.uploadFamilyImage = function(token, payload) {
    const deferred = $q.defer();
    const config = {
      headers: {
        'Content-Type': undefined
      }
    }
    $http.post(api + `uploadCaseImageAsGuest/${token}`, payload, config)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.deleteCaseImage = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `deleteCaseImage/${id}`, {filename: payload})
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getAllCaseCustomFields = function(case_id) {
    const deferred = $q.defer();
    $http.get(api + `casesCustomFields?case_id=${case_id}`)
      .then(response => {
        this.caseCustomFields = response.data
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createCaseCustomField = function(payload) {
    const deferred = $q.defer();
    $http.post(api + 'createCaseCustomField', payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.updateCaseCustomField = function(id, payload) {
    const deferred = $q.defer();
    $http.patch(api + `casesCustomFields/${id}`, payload )
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.deleteCaseCustomField = function(id, payload) {
    const deferred = $q.defer();
    $http.delete(api + `casesCustomFields/${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getCaseMemorialsAsGuest = function(token) {
    const deferred = $q.defer();
    $http.get(api + `caseMemorialsAsGuest/${token}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.getCaseMemorialsForCase = function(case_id) {
    const deferred = $q.defer();
    $http.get(api + `caseMemorials?case_id=${case_id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createCaseMemorial = function(payload) {
    const deferred = $q.defer();
    $http.post(api + `createCaseMemorial`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.createCaseMemorialAsGuest = function(token, payload) {
    const deferred = $q.defer();
    $http.post(api + `createCaseMemorialAsGuest/${token}`, payload)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

  this.archiveCaseMemorial = function(id) {
    const deferred = $q.defer();
    $http.patch(api + `archiveCaseMemorial/${id}`)
      .then(response => {
      deferred.resolve(response)
    }).catch(error => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
