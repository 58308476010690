angular.module('app').directive('hidden', ['$document', function($document){
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			elm.css({
				display: 'none'
			})
		}
	}
}])

angular.module('app').directive('file', function() {
  return {
    restrict: 'AE',
    scope: {
      file: '@'
    },
    link: function(scope, el, attrs){
      el.bind('change', function(event){
        var files = event.target.files;
        var file = files[0];
        scope.file = file;
        scope.$parent.file = file;
        scope.$apply();
      });
    }
  };
});

angular.module('app').directive('errSrc', function() {
  return {
    link: function(scope, element, attrs) {
      element.bind('error', function() {
        if (attrs.src != attrs.errSrc) {
          attrs.$set('src', attrs.errSrc);
        }
      });
    }
  }
});

angular.module('app').directive('stringToNumber', function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
      ngModel.$parsers.push(function(value) {
        return '' + value;
      });
      ngModel.$formatters.push(function(value) {
        return parseFloat(value, 10);
      });
    }
  };
});


angular.module('app').directive('clickOutside', function ($document) {
    return {
       restrict: 'A',
       scope: {
           clickOutside: '&'
       },
       link: function (scope, el, attr) {

           $document.on('click', function (e) {
               if (el !== e.target && !el[0].contains(e.target)) {
                    scope.$apply(function () {
                        scope.$eval(scope.clickOutside);
                    });
                }
           });
       }
    }
});

angular.module('app').directive('elastic', [
    '$timeout',
    function($timeout) {
        return {
            restrict: 'A',
            link: function($scope, element) {
								element[0].style.height = element[0].style.height || '16px';
                $scope.initialHeight = $scope.initialHeight || element[0].style.height;
                var resize = function() {
                    element[0].style.height = $scope.initialHeight;
                    element[0].style.height = "" + element[0].scrollHeight + "px";
                };
                element.on("input change", resize);
                $timeout(resize, 1000);
            }
        };
    }
]);

angular.module('app').directive('bgdraggable', ['$document', function($document) {
	return {
		restrict: 'A',
		link: function(scope, elm, attrs) {
			var startX, startY, initialMouseX, initialMouseY;
			elm.css('cursor','grab')

			elm.bind('mousedown', function($event) {
				startX = elm.css('background-position').split(' ')[0];
				startY = elm.css('background-position').split(' ')[1];
				initialMouseX = $event.clientX;
				initialMouseY = $event.clientY;
				elm.css({
					outline: '1px dashed gray'
				})
				$document.bind('mousemove', mousemove);
				$document.bind('mouseup', mouseup);
				//return false;
			});

			function mousemove($event) {
				var dx = $event.clientX - initialMouseX;
				var dy = $event.clientY - initialMouseY;
				var top = parseInt(startY) + dy
				var left = parseInt(startX) + dx
				var position = left + 'px ' + top + 'px'
				elm.css('background-position', position);
				return false;
			}

			function mouseup() {
				$document.unbind('mousemove', mousemove);
				$document.unbind('mouseup', mouseup);
				elm.css({
					outline: 'none'
				})
			}
		}
	};
}]);
