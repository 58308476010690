angular.module('app').service('products', function($http, $q) {

  const service = this

  this.getByCollection = function(collection) {
    const deferred = $q.defer();
    $http.get(api + `products?collection_id=${collection}`)
      .then((response) => {
      deferred.resolve(response)
    }).catch((error) => {
      deferred.resolve(error)
    })
    return deferred.promise
  }

})
