angular.module("app").service("drbSubscriptions", function($http, $q) {
  
  this.key = 'pk_live_Z4s8Eyp8c26MsEa36AGdbJlS00fx7FJZZn'
  this.sms_incoming = 'plan_G3FtR6ay5ZUxkk'
  this.sms_outgoing = 'plan_G3FtDRUHYwz1Ps'
  this.drb_monthly = 'plan_G3FqAYOEsPMjUL'
  this.drb_annual = 'plan_G3FqHjgItkoFNx'

  this.createSubscription = async payload => {
    const post = $http.post(`${api}createDRBSubscription`, payload);
    try {
      return await post;
    } catch (err) {
      throw err;
    }
  };

  this.syncSubscription = async () => {
    const post = $http.post(`${api}syncDRBSubscription`);
    try {
      return await post;
    } catch (err) {
      throw err;
    }
  };

  this.getActiveSubscription = async () => {
    const get = $http.get(`${api}activeDRBSubscriptionInfo`);
    try {
      return await get;
    } catch (err) {
      return err
    }
  };

  this.cancelSubscription = async (id) => {
    const post = $http.post(`${api}cancelDRBSubscription?id=${id}`);
    try {
      return await post;
    } catch (err) {
      throw err;
    }
  };

  this.getCustomer = async () => {
    const get = $http.get(`${api}drbStripeCustomer`);
    try {
      return await get;
    } catch (err) {
      throw err;
    }
  };

  this.getUsage = async() =>{
    const get = $http.get(`${api}drbGetSMSUsage`);
    try {
      return await get;
    } catch (err) {
      throw err;
    }
  }

  this.updateCustomer = async payload => {
    const patch = $http.patch(`${api}updateDRBStripeCustomer`, payload);
    try {
      return await patch;
    } catch (err) {
      throw err;
    }
  };

  this.getCardSession = async () => {
    const get = $http.get(`${api}setupSession`)
    try {
      return await get;
    } catch (err) {
      throw err;
    }
  }
});
