(function() {
  angular.module("app").directive("assetDisplay", assetDisplayController);
  function assetDisplayController(
    cases,
    assets,
    $routeParams,
    $timeout,
    $compile,
    $rootScope,
    dom,
    helpers
  ) {
    return {
      templateUrl: "pages/editor/assetDisplay/asset-display.html",
      controllerAs: "advm", // ASSET DISPLAY MODEL
      controller: function($scope) {
        const advm = this;
        angular.extend(advm, {
          addLogo: addLogo,
          addPersonalImage: addPersonalImage,
          addPicture: addPicture,
          addFlower: addFlower,
          addMemorialText: addMemorialText,
          addCondolenceText: addCondolenceText,
          addFriendsRelativesText: addFriendsRelativesText,
          makeImgDefault: makeImgDefault,
        });

        function addLogo(file) {
          var img = new Image()
          img.src = helpers.imgixLogo + file

          img.onload = function () {
            let width = this.width/2
            let height = this.height/2
            width > 250 ? (width/2, height/2) : (width, height)
            var image = $compile('<div style="background-image: url(' + helpers.imgixLogo + file + '?or=0); background-size:100% 100%; height:'+ height +'px; width:'+ width +'px; border-radius:2px; z-index:10; position: absolute; cursor: pointer; opacity: 1; top: 0px; left: 0px;" data-type="image" ng-click="selectElement($event)" class="draggable element ng-scope image"></div>')($scope)

            $('#editor .product .focused .outlines').append(image)
            dom.sizeImage(image)
          }
          $scope.quillImgUpload = false
          $scope.setUndoState();
        }

        function addPersonalImage(file, $index) {
          let img = new Image()
          img.src = helpers.imgixCase + file
          if($('.quill').length > 0) {
            let url = {
              url: img.src
            }
            $scope.insertToEditor(url, $index)
          } else {
            img.onload = function () {
              let width = this.width/2
              let height = this.height/2
              width > 250 ? (width/2, height/2) : (width, height)
              let image = $compile(`<div style="background-image: url(${helpers.imgixCase}${file}?or=0); background-size:100% 100%; height: ${height}px; width: ${width}px; border-radius:2px; z-index:10; position: absolute; cursor: pointer; opacity: 1; top: 0px; left: 0px;" data-type="image" ng-click="selectElement($event)" class="caseImage draggable element ng-scope image" data-caseimage="${$index}"></div>`)($scope)
              $('#editor .product .focused .outlines').append(image)

              dom.sizeImage(image)
            }
          }
          $scope.setUndoState();
        }

        function addPicture(file) {
					let img = new Image()
					img.src = helpers.imgixBase + file
					img.onload = function () {
					}
					let image = $compile(`<div style="background-image: url(${helpers.imgixBase}${file}?or=0); background-size:100% 100%; border-radius:2px; z-index:10; position: absolute; cursor: pointer; opacity: 1; top: 0px; left: 0px;" data-type="image" ng-click="selectElement($event)" class="draggable element ng-scope image"></div>`)($scope)
          $('#editor .product .focused .outlines').append(image)

          dom.sizeImage(image)
					$scope.setUndoState();
        }

        function addFlower(file) {
          let img = new Image()
          img.src = helpers.imgixBase + file
          img.onload = function () {
          }
          let image = $compile(`<div style="background-image: url(${helpers.imgixFlower}${file}?or=0); background-size:100% 100%; border-radius:2px; z-index:10; position: absolute; cursor: pointer; opacity: 1; top: 0px; left: 0px;" data-type="image" ng-click="selectElement($event)" class="draggable element ng-scope image"></div>`)($scope)
          $('#editor .product .focused .outlines').append(image)

          dom.sizeImage(image)
          $scope.setUndoState();
        }

        function addMemorialText(memorial) {
          let color
          let font
          $scope.themeFontColor ? (color = $scope.themeFontColor) : (color = 'rgb(0,0,0)')
          $scope.themeFont ? (font = $scope.themeFont) : font = 'Century Gothic'
          let el = $compile(`<p ng-click="selectElement($event)" ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" style="font-family:${font}; font-size: 13px; line-height: 1em; width:180px; position:absolute; color:${color}; top:120px; left:65px; width:350px; height: 80px; border:none; text-align:center; resize:none; background:none; z-index:10;" class="draggable element" contenteditable="true">${memorial.memorial}<br /> - ${memorial.submitter_name}</p>`)($scope)
          $('#editor .product .focused .outlines').append(el)
        }

        function addCondolenceText(guest) {          
          let color
          let font
          $scope.themeFontColor ? (color = $scope.themeFontColor) : (color = 'rgb(0,0,0)')
          $scope.themeFont ? (font = $scope.themeFont) : font = 'Century Gothic'
          let el = $compile(`<p ng-click="selectElement($event)" ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" style="font-family:${font}; font-size: 13px; line-height: 1em; width:180px; position:absolute; color:${color}; top:120px; left:65px; width:350px; height: 80px; border:none; text-align:center; resize:none; background:none; z-index:10;" class="draggable element" contenteditable="true">${guest.message}<br /> - ${guest.name}</p>`)($scope)
          $('#editor .product .focused .outlines').append(el)
        }

        function addFriendsRelativesText(guest) {          
          let color
          let font
          $scope.themeFontColor ? (color = $scope.themeFontColor) : (color = 'rgb(0,0,0)')
          $scope.themeFont ? (font = $scope.themeFont) : font = 'Century Gothic'
          let el = $compile(`<p ng-click="selectElement($event)" ondblclick="document.execCommand(\'selectAll\',false,null)" data-type="text" style="font-family:${font}; font-size: 13px; line-height: 1em; width:180px; position:absolute; color:${color}; top:120px; left:65px; width:350px; height: 80px; border:none; text-align:center; resize:none; background:none; z-index:10;" class="draggable element" contenteditable="true">${guest.name}<br />${$scope.includeEmail ? guest.email : ''}<br/>${guest.address}<br/>${guest.city}&nbsp${guest.state}&nbsp${guest.zip_code}</p>`)($scope)
          $('#editor .product .focused .outlines').append(el)
        }
        
        function makeImgDefault(filename) {
          $scope.makeImgDefault(filename)
        }

      }
    };
  }
})()
