angular.module('app').service('imageService', function($http, $q) {

  this.getImageParams = function(image) {
    const imageParams = {}
    var baseImage = image.split('?')[0]
    imageParams.baseImage = baseImage
    var params = image.split('?')
    params.shift()
    if(params.length == 1) {
      params = params[0]
    } else {
      params = params.join('?')
    }
    params = params.split('&')

    for (var i in params) {
      if(params[i].length){
        var key = params[i].split('=')[0]
        var value = params[i].split('=')[1]
        imageParams[key] = value
      }
    }
    return imageParams
  }

  this.getPath = function(selected) {
    let path
    if(!selected.image) {
      path = $(selected.selector).css('background-image').replace('url(', '').replace(')', '').replace('https://s3-us-west-2.amazonaws.com/bassmollett-uploads/', '').replace(/"/g, '')
    } else {
      path = $(selected.selector).attr('src').replace('https://s3-us-west-2.amazonaws.com/bassmollett-uploads/', '').replace(/"/g, '')
    }
    return path
  }
});
