(function() {
	angular.module("app").directive("signup", signupController);

	function signupController(user, funeralHomes, $timeout, $location) {
		return {
			templateUrl: "pages/sign-up/sign-up.html",
			controllerAs: "vm",
			controller: function() {
				var vm = this;

				angular.extend(vm, {
					form: {},
					signup: signup,
					secondpassword: null,
					signingup: false,
					accesscode: null,
					resetErrors: resetErrors
				});

				function validateForm() {
					return new Promise((resolve, reject) => {
						user.checkEmail(vm.form.user.email).then(response => {
							if (response.status !== 200) {
								vm.emailAlert = true;
								reject(response.data.message);
							} else {
								if (vm.form.user.password.length < 5) {
									reject("Password much be at least 5 characters");
									vm.passAlert = true;
								} else if (vm.form.user.password !== vm.secondpassword) {
									reject("Passwords don't match");
									vm.secondAlert = true;
								} else if (vm.form.home.phone.length !== 10) {
									reject("Phone number must be 10 digits long");
									vm.phoneAlert = true;
								} else if (vm.form.home.zip_code.length < 1) {
									reject("Please enter a zip code");
									vm.zipAlert = true;
								} else {
									resolve();
								}
								resolve();
							}
						});
					});
				}

				function signup() {
					vm.signingup = true;

					validateForm().then(() => {
						user.getIpLocation().then(response => {
							vm.form.home.ip_city = response.data.city;
							vm.form.home.ip_country_code = response.data.country_code;
							vm.form.home.ip_country_name = response.data.country_name;
							vm.form.home.ip_address = response.data.ip;
							vm.form.home.ip_latitude = response.data.latitude;
							vm.form.home.ip_longitude = response.data.longitude;
							vm.form.home.ip_region_code = response.data.region_code;
							vm.form.home.ip_region_name = response.data.region_name;
							vm.form.home.ip_zip_code = response.data.zip_code;

							funeralHomes.create(vm.form.home).then(response => {
								funeralHomes.home = response.data;
								vm.form.user.funeral_home_id = response.data.id;
								user.signup(vm.form.user).then(response => {
									if (response.data.error) {
										alert(response.data.message);
										vm.signingup = false;
									}
									vm.signingup = false;
									$location.path("welcome");
								});
							});
						});
					}).catch(err => {
						vm.signingup = false;
						$timeout(() => {
							vm.formAlert = err;
						}, 100);
					});
				}

				function resetErrors() {
					vm.formAlert = null;
					vm.secondAlert = false;
					vm.emailAlert = false;
					vm.phoneAlert = false;
					vm.zipAlert = false;
					vm.passAlert = false;
				}

				//******** end *********//
			}
		};
	}
})();
